import React, { useCallback, useState, useEffect } from 'react';
import { FiLock, FiUnlock } from 'react-icons/fi';

import { Switch, Tooltip } from 'antd';

import { useDispatch, useSelector } from '../../../../utility/Context';
import { getAuthLocalStorage } from '../../../../utility/localStorage';

import { callEnableProfile } from '../../../../ducks/ApplicationDucks/EnableProfile';

const EnableProfile = () => {
  const userId = getAuthLocalStorage()?.id;

  const [nickname, bookingAvailable, loading] = useSelector(
    ({ user, application }) => [
      user.data.personal.nickname,
      user.data.personal.bookingAvailable,
      application.loading.enableProfile,
    ]
  );

  const [enable, setEnable] = useState(bookingAvailable);

  useEffect(() => {
    setEnable(bookingAvailable);
  }, [bookingAvailable]);

  const dispatch = useDispatch();

  const enableProfile = useCallback(
    (checked) => {
      dispatch(callEnableProfile({ userId, checked, nickname }));
    },
    [dispatch]
  );

  const onChange = (checked) => {
    enableProfile(checked);
  };

  const tooltipTitle = enable
    ? 'Ao fechar a agenda, seu perfil não aparecerá mais no catálogo e não receberá novos agendamentos.'
    : 'Ao abrir a agenda, seu perfil aparecerá no catálogo e poderá receber novos agendamentos.';

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <Switch
          checkedChildren={<FiUnlock />}
          unCheckedChildren={<FiLock />}
          defaultChecked={enable}
          checked={enable}
          onChange={onChange}
          loading={loading}
        />
      </div>
    </Tooltip>
  );
};

export default EnableProfile;
