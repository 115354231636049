/* eslint-disable camelcase */
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Space, Tag, Dropdown, Rate } from 'antd';
import { FiMoreVertical } from 'react-icons/fi';
import { MdOutlineCancel } from 'react-icons/md';
import { AiFillHeart, AiOutlineHeart, AiOutlineStar } from 'react-icons/ai';

import Button from '../../../elements/Button';
import ImagePreview from '../../ImagePreview';
import Avatar from '../../Avatar';
import Status from '../../Status';

import CancelClass from '../CancelClass';
import RateClass from '../RateClass';

import {
  ContainerBtnMobile,
  AvatarContainer,
  ExpandedRowTitle,
} from '../styles';
import { ContainerFavoriteProfessionals } from './styles';

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

const toggleFavorite = async (
  professionalId,
  clientId,
  isFavorite,
  clientProfessional
) => {
  const token = localStorage.getItem('token');
  try {
    await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/clients/${clientId}/professionals/favorites/${professionalId}`,
      {
        method: !isFavorite ? 'POST' : 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Language': 'pt-BR',
        },
      }
    );
    clientProfessional();
  } catch (error) {
    console.error('Erro na chamada POST:', error);
  }
};

export default (isMobile, clientId, clientProfessional, configModal) => [
  {
    title: 'Profissional',
    dataIndex: 'avatar',
    key: 'avatar',
    render: (_, { nickname, avatar, firstName, lastName }) => (
      <a
        href={`https://personally.fit/p/${nickname}`}
        target="_blank"
        rel="noreferrer"
      >
        <AvatarContainer>
          <Avatar avatar={avatar} modalTitle="Avatar do profissional" />
          <p>
            {firstName} {lastName}
          </p>
        </AvatarContainer>
      </a>
    ),
    responsive: ['lg'],
    sorter: (a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Profissional',
    dataIndex: 'name',
    key: 'name',
    render: (_, { nickname, avatar, firstName, lastName }) => (
      <a
        href={`https://personally.fit/p/${nickname}`}
        target="_blank"
        rel="noreferrer"
      >
        <p>
          {firstName} {lastName}
        </p>
      </a>
    ),
    responsive: ['xs', 'sm', 'md'],
    sorter: (a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'CREF',
    key: 'crefNumber',
    dataIndex: 'crefNumber',
    render: (_, { crefNumber }) => <p>{crefNumber}</p>,
    responsive: ['sm', 'md'],
    sorter: (a, b) => {
      if (a.crefNumber < b.crefNumber) {
        return -1;
      }
      if (a.crefNumber > b.crefNumber) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Usuário',
    dataIndex: 'nickname',
    key: 'nickname',
    render: (_, { nickname }) => <p>{nickname}</p>,
    responsive: ['lg'],
    sorter: (a, b) => {
      if (a.nickname < b.nickname) {
        return -1;
      }
      if (a.nickname > b.nickname) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Contato',
    dataIndex: 'phone',
    key: 'phone',
    render: (_, { phone }) => <p>{phone}</p>,
    responsive: ['xs', 'sm'],
  },
  {
    title: 'Aulas realizadas',
    dataIndex: 'totalClasses',
    key: 'totalClasses',
    render: (_, { totalClasses }) => <p>{totalClasses} aula(s)</p>,
    responsive: ['lg'],
    sorter: (a, b) => {
      return a.totalClasses - b.totalClasses;
    },
  },
  {
    title: 'Ações',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, { isFavorite, professionalId }) =>
      isMobile ? (
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <Button
                    className="btn-favorite-professionals"
                    key="favorite"
                    type="text"
                    icon={isFavorite ? <AiFillHeart /> : <AiOutlineHeart />}
                    onClick={() =>
                      toggleFavorite(
                        professionalId,
                        clientId,
                        isFavorite,
                        clientProfessional
                      )
                    }
                  >
                    {isFavorite ? 'Desfavoritar' : 'Favoritar'}
                  </Button>
                ),
                key: '0',
              },
              {
                type: 'divider',
              },
              {
                key: '1',
                label: (
                  <ContainerBtnMobile>
                    <Button
                      danger
                      type="link"
                      onClick={() => {
                        configModal.setProfessionalId(professionalId);
                        configModal.showModal();
                      }}
                    >
                      Denunciar
                    </Button>
                  </ContainerBtnMobile>
                ),
              },
            ],
          }}
          trigger={['click']}
        >
          <Space>
            <FiMoreVertical className="menu-table__mobile" />
          </Space>
        </Dropdown>
      ) : (
        <ContainerFavoriteProfessionals>
          <Button
            className="btn-favorite-professionals"
            key="favorite"
            type="text"
            icon={isFavorite ? <AiFillHeart /> : <AiOutlineHeart />}
            onClick={() =>
              toggleFavorite(
                professionalId,
                clientId,
                isFavorite,
                clientProfessional
              )
            }
          >
            {isFavorite ? 'Desfavoritar' : 'Favoritar'}
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              configModal.setProfessionalId(professionalId);
              configModal.showModal();
            }}
          >
            Denunciar
          </Button>
        </ContainerFavoriteProfessionals>
      ),
  },
];
