import {
  CALL_SIGN_UP_CLIENT,
  SIGN_UP_CLIENT_FAILED,
  SIGN_UP_CLIENT_SUCCESS,
  onCallSignUpClient,
  onSignUpClientFailed,
  onSignUpClientSuccess,
} from './SignUpClient';
import {
  CALL_SIGN_UP_PERSONAL,
  SIGN_UP_PERSONAL_FAILED,
  SIGN_UP_PERSONAL_SUCCESS,
  onCallSignUpPersonal,
  onSignUpPersonalFailed,
  onSignUpPersonalSuccess,
} from './SignUpPersonal';
import {
  CALL_LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  onCallLogin,
  onLoginFailed,
  onLoginSuccess,
} from './Login';
import {
  CALL_RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  onCallResetPassword,
  onResetPasswordFailed,
  onResetPasswordSuccess,
} from './ResetPassword';
import {
  CALL_USER_INFO,
  USER_INFO_FAILED,
  USER_INFO_SUCCESS,
  onCallUserInfo,
  onlUserInfoFailed,
  onUserInfoSuccess,
} from './UserInfo';
import {
  CALL_CUSTOMER_DATA,
  CUSTOMER_DATA_FAILED,
  CUSTOMER_DATA_SUCCESS,
  onCallCustomerData,
  onCallCustomerDataFailed,
  onCallCustomerDataSuccess,
} from './FetchCustomerData';
import {
  CALL_UPDATE_CUSTOMER_DATA,
  UPDATE_CUSTOMER_DATA_FAILED,
  UPDATE_CUSTOMER_DATA_SUCCESS,
  onCallUpdateCustomerData,
  onUpdateCustomerDataFailed,
  onUpdateCustomerDataSuccess,
} from './UpdateCustomerData';
import {
  CALL_MEASURES,
  MEASURES_FAILED,
  MEASURES_SUCCESS,
  onCallMeasures,
  onMeasuresFailed,
  onMeasuresSuccess,
} from './Measures';
import {
  CALL_HEIGHTS,
  HEIGHTS_FAILED,
  HEIGHTS_SUCCESS,
  onCallHeights,
  onHeightsFailed,
  onHeightsSuccess,
} from './Heights';
import {
  CALL_PERSONAL_DATA,
  PERSONAL_DATA_FAILED,
  PERSONAL_DATA_SUCCESS,
  onCallPersonalData,
  onCallPersonalDataFailed,
  onCallPersonalDataSuccess,
} from './FetchPersonalData';
import {
  ADD_PERSONAL_EDUCATION,
  REMOVE_PERSONAL_EDUCATION,
  onAddPersonalEducation,
  onRemovePersonalEducation,
} from './PersonalEducation';
import {
  ADD_PERSONAL_EXPERIENCE,
  REMOVE_PERSONAL_EXPERIENCE,
  onAddPersonalExperience,
  onRemovePersonalExperience,
} from './PersonalExperience';
import {
  CALL_UPDATE_PERSONAL_DATA,
  UPDATE_PERSONAL_DATA_FAILED,
  UPDATE_PERSONAL_DATA_SUCCESS,
  onCallUpdatePersonalData,
  onUpdatePersonalDataFailed,
  onUpdatePersonalDataSuccess,
} from './UpdatePersonalData';
import {
  CALL_WEIGHTS,
  WEIGHTS_FAILED,
  WEIGHTS_SUCCESS,
  onCallWeights,
  onWeightsFailed,
  onWeightsSuccess,
} from './Weights';
import {
  CALL_BODY_FAT,
  BODY_FAT_FAILED,
  BODY_FAT_SUCCESS,
  onCallBodyFat,
  onBodyFatFailed,
  onBodyFatSuccess,
} from './BodyFat';

const ACTION_HANDLERS = {
  [CALL_SIGN_UP_CLIENT]: onCallSignUpClient,
  [SIGN_UP_CLIENT_FAILED]: onSignUpClientFailed,
  [SIGN_UP_CLIENT_SUCCESS]: onSignUpClientSuccess,

  [CALL_SIGN_UP_PERSONAL]: onCallSignUpPersonal,
  [SIGN_UP_PERSONAL_FAILED]: onSignUpPersonalFailed,
  [SIGN_UP_PERSONAL_SUCCESS]: onSignUpPersonalSuccess,

  [CALL_LOGIN]: onCallLogin,
  [LOGIN_FAILED]: onLoginFailed,
  [LOGIN_SUCCESS]: onLoginSuccess,

  [CALL_RESET_PASSWORD]: onCallResetPassword,
  [RESET_PASSWORD_FAILED]: onResetPasswordFailed,
  [RESET_PASSWORD_SUCCESS]: onResetPasswordSuccess,

  [CALL_USER_INFO]: onCallUserInfo,
  [USER_INFO_FAILED]: onlUserInfoFailed,
  [USER_INFO_SUCCESS]: onUserInfoSuccess,

  [CALL_CUSTOMER_DATA]: onCallCustomerData,
  [CUSTOMER_DATA_FAILED]: onCallCustomerDataFailed,
  [CUSTOMER_DATA_SUCCESS]: onCallCustomerDataSuccess,

  [CALL_UPDATE_CUSTOMER_DATA]: onCallUpdateCustomerData,
  [UPDATE_CUSTOMER_DATA_FAILED]: onUpdateCustomerDataFailed,
  [UPDATE_CUSTOMER_DATA_SUCCESS]: onUpdateCustomerDataSuccess,

  [CALL_MEASURES]: onCallMeasures,
  [MEASURES_FAILED]: onMeasuresFailed,
  [MEASURES_SUCCESS]: onMeasuresSuccess,

  [CALL_HEIGHTS]: onCallHeights,
  [HEIGHTS_FAILED]: onHeightsFailed,
  [HEIGHTS_SUCCESS]: onHeightsSuccess,

  [CALL_PERSONAL_DATA]: onCallPersonalData,
  [PERSONAL_DATA_FAILED]: onCallPersonalDataFailed,
  [PERSONAL_DATA_SUCCESS]: onCallPersonalDataSuccess,

  [ADD_PERSONAL_EDUCATION]: onAddPersonalEducation,
  [REMOVE_PERSONAL_EDUCATION]: onRemovePersonalEducation,

  [ADD_PERSONAL_EXPERIENCE]: onAddPersonalExperience,
  [REMOVE_PERSONAL_EXPERIENCE]: onRemovePersonalExperience,

  [CALL_UPDATE_PERSONAL_DATA]: onCallUpdatePersonalData,
  [UPDATE_PERSONAL_DATA_FAILED]: onUpdatePersonalDataFailed,
  [UPDATE_PERSONAL_DATA_SUCCESS]: onUpdatePersonalDataSuccess,

  [CALL_WEIGHTS]: onCallWeights,
  [WEIGHTS_FAILED]: onWeightsFailed,
  [WEIGHTS_SUCCESS]: onWeightsSuccess,

  [CALL_BODY_FAT]: onCallBodyFat,
  [BODY_FAT_FAILED]: onBodyFatFailed,
  [BODY_FAT_SUCCESS]: onBodyFatSuccess,
};

export const INITIAL_STATE = {
  loading: {
    signUp: {
      client: false,
      personal: false,
    },
    login: false,
    resetPassword: false,
    userInfo: false,
    customerData: false,
    updateCustomerData: false,
    measures: false,
    heights: false,
    personalData: false,
    updatePersonalData: false,
    weights: false,
    bodyFat: false,
  },
  errors: {
    signUp: {
      client: null,
      personal: null,
    },
    login: null,
    resetPassword: null,
    userInfo: null,
    customerData: null,
    updateCustomerData: null,
    measures: null,
    heights: null,
    personalData: null,
    updatePersonalData: null,
    weights: null,
    bodyFat: null,
  },
  auth: {
    token: null,
  },
  data: {
    customer: null,
    heights: [],
    personalData: [],
    weights: [],
    bodyFat: [],
  },
  infoPersonal: {
    education: [],
    experience: [],
  },
};

export default [INITIAL_STATE, ACTION_HANDLERS];
