export const CALL_RATE = '@application/CALL_RATE';
export const RATE_FAILED = '@application/RATE_FAILED';
export const RATE_SUCCESS = '@application/RATE_SUCCESS';

export const callRate = (id, body, userId) => ({
  type: CALL_RATE,
  id,
  body,
  userId,
});

export const rateFailed = (error) => ({
  type: RATE_FAILED,
  error,
});

export const rateSuccess = (data) => ({
  type: RATE_SUCCESS,
  data,
});

export const onCallRate = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    rate: true,
  },
  errors: {
    ...state.errors,
    rate: null,
  },
});

export const onRateFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    rate: false,
  },
  errors: {
    ...state.errors,
    rate: error,
  },
});

export const onRateSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    rate: false,
  },
  errors: {
    ...state.errors,
    rate: null,
  },
});
