import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'sign-up__container-terms',
})`
  &.sign-up__container-terms {
    margin: 24px 0 0px;

    & .btn-terms {
      padding: 0px;
      font-size: 14px;
      height: 0px;
    }
  }
`;

export default {
  Container,
};
