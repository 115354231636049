import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_SEARCH } from '../../defaults/Routes';

const Client = () => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate(`/${ROUTE_SEARCH}`);
  };

  useEffect(() => {
    redirect();
  }, []);
};

export default Client;
