export const parseJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        // eslint-disable-next-line
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const jsonParse = JSON.parse(jsonPayload);

  const data = {
    avatar: jsonParse.avatar,
    email: jsonParse.email,
    firstname: jsonParse.first_name,
    id: jsonParse.id,
    lastname: jsonParse.last_name,
    sub: jsonParse.sub,
    userType: jsonParse.user_type,
  };

  return data;
};

export default parseJWT;
