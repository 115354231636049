import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Image } from './styles';

const Logo = ({ onClick, logoLink }) => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate(logoLink);
  };

  return (
    <Image
      src="/image/logo.png"
      alt="Logo Personally"
      onClick={() => redirect()}
    />
  );
};

export default Logo;
