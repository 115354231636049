import React, { useState } from 'react';
import { Modal, Avatar as AvatarAntd } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { Container } from './styles';

const Avatar = ({ avatar, size, modalTitle }) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  return (
    <Container>
      <AvatarAntd
        size={size}
        src={avatar}
        icon={<UserOutlined />}
        onClick={handlePreview}
      />

      <Modal
        open={previewOpen}
        title={modalTitle || 'Imagem'}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={avatar}
        />
      </Modal>
    </Container>
  );
};

export default Avatar;
