import React from 'react';
import { all, fork, takeEvery, call, put, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { message } from 'antd';
import moment from 'moment';

import {
  CALL_SIGN_UP_CLIENT,
  signUpClientFailed,
  signUpClientSuccess,
} from '../ducks/UserDucks/SignUpClient';
import {
  CALL_SIGN_UP_PERSONAL,
  signUpPersonalFailed,
  signUpPersonalSuccess,
} from '../ducks/UserDucks/SignUpPersonal';
import {
  CALL_LOGIN,
  loginFailed,
  loginSuccess,
} from '../ducks/UserDucks/Login';
import {
  CALL_RESET_PASSWORD,
  resetPasswordFailed,
  resetPasswordSuccess,
} from '../ducks/UserDucks/ResetPassword';
import {
  CALL_USER_INFO,
  userInfoFailed,
  userInfoSuccess,
} from '../ducks/UserDucks/UserInfo';
import {
  callCustomerData,
  CALL_CUSTOMER_DATA,
  customerDataFailed,
  customerDataSuccess,
} from '../ducks/UserDucks/FetchCustomerData';
import {
  CALL_UPDATE_CUSTOMER_DATA,
  updateCustomerDataFailed,
  updateCustomerDataSuccess,
} from '../ducks/UserDucks/UpdateCustomerData';
import {
  CALL_MEASURES,
  measuresFailed,
  measuresSuccess,
} from '../ducks/UserDucks/Measures';
import {
  CALL_HEIGHTS,
  callHeights,
  heightsFailed,
  heightsSuccess,
} from '../ducks/UserDucks/Heights';
import {
  CALL_PERSONAL_DATA,
  callPersonalData,
  personalDataFailed,
  personalDataSuccess,
} from '../ducks/UserDucks/FetchPersonalData';
import {
  CALL_UPDATE_PERSONAL_DATA,
  updatePersonalDataFailed,
  updatePersonalDataSuccess,
} from '../ducks/UserDucks/UpdatePersonalData';
import {
  CALL_WEIGHTS,
  callWeights,
  weightsFailed,
  weightsSuccess,
} from '../ducks/UserDucks/Weights';
import {
  CALL_BODY_FAT,
  callBodyFat,
  bodyFatFailed,
  bodyFatSuccess,
} from '../ducks/UserDucks/BodyFat';

import UserAPI from '../integrations/UserAPI';

import { ROUTE_HOME, ROUTE_LOGIN } from '../defaults/Routes';

import convertCustomerData from '../utility/convertCustomerData';
import { convertPersonalData } from '../utility/convertPersonalData';
import { parseJWT } from '../utility/parseJWT';

function* signUpClient({ data }) {
  try {
    yield call(UserAPI.signUpClient, data);
    message.success('Cliente registrado com sucesso.');
    yield put(signUpClientSuccess());

    yield delay(2000);

    yield put(push(ROUTE_LOGIN));
  } catch (error) {
    message.error(error || 'Não foi possível cadastrar usuário.');
    yield put(signUpClientFailed(error));
  }
}

function* signUpClientSagas() {
  yield takeEvery(CALL_SIGN_UP_CLIENT, signUpClient);
}

function* signUpPersonal({ data }) {
  try {
    yield call(UserAPI.signUpPersonal, data);
    message.success('Personal registrado com sucesso.');
    yield put(signUpPersonalSuccess());

    yield delay(2000);

    yield put(push(ROUTE_LOGIN));
  } catch (error) {
    message.error(error || 'Não foi possível cadastrar usuário.');
    yield put(signUpPersonalFailed(error));
  }
}

function* signUpPersonalSagas() {
  yield takeEvery(CALL_SIGN_UP_PERSONAL, signUpPersonal);
}

function* login({ data }) {
  try {
    const token = yield call(UserAPI.login, data);

    const parseJWTToken = parseJWT(token);
    localStorage.setItem('auth', JSON.stringify(parseJWTToken));
    localStorage.setItem('token', token);

    yield put(loginSuccess(token));
    yield put(push(ROUTE_HOME));
  } catch (error) {
    message.error(error || 'Email ou senha inválida');
    yield put(loginFailed(error));
  }
}

function* loginSagas() {
  yield takeEvery(CALL_LOGIN, login);
}

function* resetPassword({ data }) {
  try {
    yield call(UserAPI.resetPassword, data);

    yield put(resetPasswordSuccess());
    message.success(
      'As informações de recuperação de senha foram enviadas no seu e-mail'
    );
  } catch (error) {
    yield put(resetPasswordFailed(error));
    message.error(error || 'Email inválido');
  }
}

function* resetPasswordSagas() {
  yield takeEvery(CALL_RESET_PASSWORD, resetPassword);
}

function* userInfo({ token }) {
  try {
    yield put(userInfoSuccess({ token }));
  } catch (error) {
    yield put(userInfoFailed(error));
  }
}

function* userInfoSagas() {
  yield takeEvery(CALL_USER_INFO, userInfo);
}

function* fetcCustomerData() {
  try {
    const data = yield call(UserAPI.getCustomerData);

    const customerData = convertCustomerData(data);
    yield put(customerDataSuccess(customerData));
  } catch (error) {
    message.error(error || 'Não foi possivel obter dados do usuário');
    yield put(customerDataFailed(error));
  }
}

function* fetcCustomerDataSagas() {
  yield takeEvery(CALL_CUSTOMER_DATA, fetcCustomerData);
}

function* updateCustomerData({ data, userId }) {
  try {
    yield call(UserAPI.updateCustomerData, {
      data,
      userId,
    });

    yield put(updateCustomerDataSuccess());
    message.success('Dados atualizados com sucesso');
  } catch (error) {
    yield put(updateCustomerDataFailed(error));
    message.error(error || 'Não foi possivel atualizar os dados do usuário');
  }
}

function* updateCustomerDataSagas() {
  yield takeEvery(CALL_UPDATE_CUSTOMER_DATA, updateCustomerData);
}

function* updateMeasures({ userId, weight, height, bodyFat }) {
  try {
    yield call(UserAPI.updateWeight, {
      weight: {
        weight_in_grams: weight,
      },
      userId,
    });

    yield call(UserAPI.updateHeight, {
      height: {
        height_in_centimeters: height,
      },
      userId,
    });

    yield call(UserAPI.addBodyFat, {
      bodyFat: {
        body_fat_percentage: bodyFat,
      },
      userId,
    });

    yield put(measuresSuccess());
    yield put(callCustomerData());
    yield put(callHeights(userId));
    yield put(callWeights(userId));
    yield put(callBodyFat(userId));
    message.success('Dados atualizados com sucesso');
  } catch (error) {
    yield put(measuresFailed(error));
    message.error(error || 'Não foi possivel atualizar os dados do usuário');
  }
}

function* updateMeasuresSagas() {
  yield takeEvery(CALL_MEASURES, updateMeasures);
}

function* getHeights({ userId }) {
  try {
    const data = yield call(UserAPI.getHeights, {
      userId,
    });

    const heights = data.map((height) => ({
      createdAt: moment(height.created_at).format('DD/MM/YYYY'),
      value: height.value,
    }));

    yield put(heightsSuccess(heights));
  } catch (error) {
    yield put(heightsFailed(error));
    message.error(error || 'Não foi possivel obter os dados do usuário');
  }
}

function* getHeightsSagas() {
  yield takeEvery(CALL_HEIGHTS, getHeights);
}

function* fetchPersonalData({ paramsUrl }) {
  try {
    let data;

    if (paramsUrl) {
      data = yield call(UserAPI.fetchProfessionalNickname, { paramsUrl });
    } else {
      data = yield call(UserAPI.fetchPersonalData);
    }

    const personalData = convertPersonalData(data);
    yield put(personalDataSuccess(personalData));
  } catch (error) {
    message.error(error || 'Não foi possivel obter dados do usuário');
    yield put(personalDataFailed(error));
  }
}

function* fetchPersonalDataSagas() {
  yield takeEvery(CALL_PERSONAL_DATA, fetchPersonalData);
}

function* updatePersonalData({ data, userId }) {
  try {
    yield call(UserAPI.updatePersonalData, {
      data,
      userId,
    });

    yield put(updatePersonalDataSuccess());
    yield put(callPersonalData());
    message.success('Dados atualizados com sucesso');
  } catch ({ errors, message: messageError }) {
    yield put(updatePersonalDataFailed(errors));
    message.error(
      errors?.length
        ? errors.map((error, index) => (
            <>
              <span key={index}>{error}</span>
              <br />
            </>
          ))
        : messageError
    );
  }
}

function* updatePersonalDataSagas() {
  yield takeEvery(CALL_UPDATE_PERSONAL_DATA, updatePersonalData);
}

function* getWeights({ userId }) {
  try {
    const data = yield call(UserAPI.getWeights, {
      userId,
    });

    const weights = data.map((weight) => ({
      createdAt: moment(weight.created_at).format('DD/MM/YYYY'),
      value: weight.value,
    }));

    yield put(weightsSuccess(weights));
  } catch (error) {
    yield put(weightsFailed(error));
    message.error(error || 'Não foi possivel obter os dados do usuário');
  }
}

function* getWeightsSagas() {
  yield takeEvery(CALL_WEIGHTS, getWeights);
}

function* getBodyFat({ userId }) {
  try {
    const data = yield call(UserAPI.getBodyFat, {
      userId,
    });

    const bodyFat = data.map((weight) => ({
      createdAt: moment(weight.created_at).format('DD/MM/YYYY'),
      value: weight.value,
    }));

    yield put(bodyFatSuccess(bodyFat));
  } catch (error) {
    yield put(bodyFatFailed(error));
    message.error(error || 'Não foi possivel obter os dados do usuário');
  }
}

function* getBodyFatSagas() {
  yield takeEvery(CALL_BODY_FAT, getBodyFat);
}

export default function* () {
  yield all([
    fork(signUpClientSagas),
    fork(signUpPersonalSagas),
    fork(loginSagas),
    fork(resetPasswordSagas),
    fork(userInfoSagas),
    fork(fetcCustomerDataSagas),
    fork(updateCustomerDataSagas),
    fork(updateMeasuresSagas),
    fork(getHeightsSagas),
    fork(fetchPersonalDataSagas),
    fork(updatePersonalDataSagas),
    fork(getWeightsSagas),
    fork(getBodyFatSagas),
  ]);
}
