import React, { useCallback } from 'react';
import {
  Form,
  Modal as ModalAntd,
  notification,
  DatePicker as DatePickerAntd,
} from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Input from '../../../../../elements/Input';
import Button from '../../../../../elements/Button';

import { getRuleField } from '../../../../../utility/RuleField';
import { useDispatch } from '../../../../../utility/Context';

import { addPersonalExperience } from '../../../../../ducks/UserDucks/PersonalExperience';

import {
  INPUT_EXPERIENCES_ACTIVITIES,
  INPUT_EXPERIENCES_COMPANY,
  INPUT_EXPERIENCES_POSITION,
} from '../../../../../defaults/RegisterUserFields';

import { ContainerButton } from './styles';

const monthFormat = 'YYYY/MM';

const Modal = ({ open, handleCancel }) => {
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const dispatch = useDispatch();

  const addExperience = useCallback(
    (body) => {
      dispatch(addPersonalExperience(body));
    },
    [dispatch]
  );

  const handleReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    const { startDate, endDate, ...rest } = values;

    const currStartDate = dayjs(startDate, monthFormat).format('MM/YYYY');

    const currEndDate = endDate
      ? dayjs(endDate, monthFormat).format('MM/YYYY')
      : null;

    const body = [
      {
        ...rest,
        startDate: currStartDate,
        endDate: currEndDate,
      },
    ];

    addExperience(body);
    message('success', 'Sucesso!', 'Formação acadêmica adicionada com sucesso');
    handleReset();
    handleCancel();
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel salvar os dados');
  };

  return (
    <>
      {contextHolder}
      <ModalAntd
        title="Adicionar experiência"
        footer={null}
        open={open}
        onCancel={() => {
          handleCancel();
          handleReset();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Input
            inputConfig={INPUT_EXPERIENCES_COMPANY}
            rules={[getRuleField(INPUT_EXPERIENCES_COMPANY)]}
          />

          <Input
            inputConfig={INPUT_EXPERIENCES_ACTIVITIES}
            rules={[getRuleField(INPUT_EXPERIENCES_ACTIVITIES)]}
          />

          <Input
            inputConfig={INPUT_EXPERIENCES_POSITION}
            rules={[getRuleField(INPUT_EXPERIENCES_POSITION)]}
          />

          <Form.Item
            name="startDate"
            label="Data de início"
            isRequired
            rules={[
              {
                required: true,
                message: `O campo ${'Data de início'.toLocaleLowerCase()} é obrigatório`,
              },
            ]}
          >
            <DatePickerAntd
              picker="month"
              placeholder="Digite a data de ínicio"
              format="MM/YYYY"
            />
          </Form.Item>

          <Form.Item name="endDate" label="Data de término (ou prevista)">
            <DatePickerAntd
              picker="month"
              placeholder="Digite a data de termino"
              format="MM/YYYY"
            />
          </Form.Item>

          <ContainerButton>
            <Button
              key="back"
              onClick={() => {
                handleCancel();
                handleReset();
              }}
            >
              Voltar
            </Button>
            <Button key="submit" type="primary" htmlType="submit">
              Confirmar
            </Button>
          </ContainerButton>
        </Form>
      </ModalAntd>
    </>
  );
};

Modal.defaultProps = {
  editEducation: null,
};

Modal.propTypes = {
  editEducation: PropTypes.shape({
    institution: PropTypes.string,
    degree: PropTypes.string,
    fieldOfStudy: PropTypes.string,
  }),
};

export default Modal;
