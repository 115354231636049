import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  SHADOW_COLOR,
  MEDIUM_RED_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'container-card-personal',
})`
  &.container-card-personal {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1000px;
    align-items: flex-start;
    margin-bottom: 24px;
    justify-content: center;

    & .classes_card {
      width: 300px;
      margin: 24px 0px;

      & li {
        display: flex;
        justify-content: center;
      }

      & .btn-favorite-class {
        display: flex;
        color: ${PRIMARY_COLOR};
        align-items: center;

        & svg {
          color: ${PRIMARY_COLOR};
        }
      }
    }

    & .title-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 6px;

      & svg {
        color: #656d76;
        font-size: 1rem;

        &.valid-cref {
          font-size: 1rem;
          color: ${PRIMARY_COLOR};
        }

        &.invalid-cref {
          color: ${SHADOW_COLOR};
        }
      }
    }
  }
`;

export const ContainerDescription = styled.div.attrs({
  className: 'container-description',
})`
  &.container-description {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & .rate-container {
      display: flex;
      gap: 3px;

      & ul {
        display: flex;
      }
    }

    & .text-description {
      color: #252525;
    }

    & .tag-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: space-between;
    }

    & .rate-description {
      font-size: 12px;
    }
  }
`;

export const AvatarStyled = styled.div.attrs({
  className: 'avatar-container',
})`
  &.avatar-container {
    position: relative;

    & img {
      width: 100%;
    }

    & h3 {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 10px;
      color: #000;
      font-weight: 700;
    }
  }
`;

export default {
  Container,
  ContainerDescription,
  AvatarStyled,
};
