export const CALL_CANCEL_CLASS = '@application/CALL_CANCEL_CLASS';
export const CANCEL_CLASS_FAILED = '@application/CANCEL_CLASS_FAILED';
export const CANCEL_CLASS_SUCCESS = '@application/CANCEL_CLASS_SUCCESS';

export const callCancelClass = (id, reason, userId, userType) => ({
  type: CALL_CANCEL_CLASS,
  id,
  reason,
  userId,
  userType,
});

export const cancelClassFailed = (error) => ({
  type: CANCEL_CLASS_FAILED,
  error,
});

export const cancelClassSuccess = (data) => ({
  type: CANCEL_CLASS_SUCCESS,
  data,
});

export const onCancelClass = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    cancelClass: true,
  },
  errors: {
    ...state.errors,
    cancelClass: null,
  },
});

export const onCancelClassFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    cancelClass: false,
  },
  errors: {
    ...state.errors,
    cancelClass: error,
  },
});

export const onCancelClassSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    cancelClass: false,
  },
  errors: {
    ...state.errors,
    cancelClass: null,
  },
});
