import React from 'react';
import { ConfigProvider } from 'antd';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Login from './pages/Login';
import LandingPageClient from './pages/LandingPageClient';
import SignUpPersonal from './pages/SignUp/Personal';
import SignUpClient from './pages/SignUp/Client';
import ResetPassword from './components/ResetPassword';

import PrivateRoute from './components/PrivateRoute';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Catalog from './components/Catalog';
import AccountSettings from './components/Client/AccountSettings';
import AppointmentsClient from './components/Client/Appointments';
import MyProfessionalsClient from './components/Client/MyProfessionals';

import PersonalAccountSettings from './components/Personal/AccountSettings';
import PersonalClients from './components/Personal/Clients';
import Appointments from './components/Personal/Appointments';
import ProfessionalPublic from './components/ProfessionalPublic';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';

import { PRIMARY_COLOR } from './defaults/Colors';
import {
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD,
  ROUTE_SETTINGS,
  ROUTE_DASHBOARD_MEASURES,
  ROUTE_PERSONAL_SETTINGS,
  ROUTE_PERSONAL_MY_CLIENTS,
  ROUTE_SEARCH,
  ROUTE_PERSONAL_APPOINTMENTS,
  ROUTE_APPOINTMENTS,
  ROUTE_MY_PROFESSIONALS,
} from './defaults/Routes';
import { GlobalStyled } from './globalStyled';
import Dashboards from './components/Client/Dashboards';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
          colorLink: PRIMARY_COLOR,
        },
      }}
    >
      <div className="App" data-testid="app">
        <BrowserRouter>
          <Header />

          <Routes>
            <Route path="/" element={<LandingPageClient />} />
            <Route path="/personal" element={<SignUpPersonal />} />
            <Route path="/sign-up-client" element={<SignUpClient />} />
            <Route path="/p/:nickname" element={<ProfessionalPublic />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path={ROUTE_LOGIN} element={<Login />} />
            <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />} />

            <Route path={ROUTE_SEARCH} element={<Catalog />} />

            <Route path="/workspace/*" element={<PrivateRoute />}>
              <Route index element={<Home />} />

              {/* client screens */}
              <Route path={ROUTE_SETTINGS} element={<AccountSettings />} />
              <Route path={ROUTE_DASHBOARD_MEASURES} element={<Dashboards />} />
              <Route
                path={ROUTE_APPOINTMENTS}
                element={<AppointmentsClient />}
              />
              <Route
                path={ROUTE_MY_PROFESSIONALS}
                element={<MyProfessionalsClient />}
              />

              {/* personal screens */}
              <Route
                path={ROUTE_PERSONAL_SETTINGS}
                element={<PersonalAccountSettings />}
              />
              <Route
                path={ROUTE_PERSONAL_MY_CLIENTS}
                element={<PersonalClients />}
              />
              <Route
                path={ROUTE_PERSONAL_APPOINTMENTS}
                element={<Appointments />}
              />
            </Route>
          </Routes>

          <Footer />
          <GlobalStyled />
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
