import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from '../../utility/Context';
import { getAuthLocalStorage } from '../../utility/localStorage';

import { callPersonalData } from '../../ducks/UserDucks/FetchPersonalData';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import Empty from '../Empty';

import SideBar from './SideBar';
import Main from './Main';
import Mobile from './Mobile';

import { Container, ContainerMainMobile } from './styles';

const Personal = ({ paramsUrl }) => {
  const [userData, loading, personalDataError] = useSelector(({ user }) => [
    user.data.personal,
    user.loading.personalData,
    user.errors.personalData,
  ]);

  const { isMobile } = useWindowDimensions();

  const userType = getAuthLocalStorage()?.userType;

  const dispatch = useDispatch();

  const customerData = useCallback(() => {
    dispatch(callPersonalData(paramsUrl));
  }, [dispatch]);

  useEffect(() => {
    customerData();
  }, []);

  if (personalDataError) {
    return <Empty text="Página não encontrada" img="/image/not-found.svg" />;
  }

  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        {isMobile ? (
          <div>
            <Mobile userData={userData} paramsUrl={paramsUrl} />

            <ContainerMainMobile>
              <Main userData={userData} paramsUrl={paramsUrl} />
            </ContainerMainMobile>
          </div>
        ) : (
          <Container>
            <SideBar userData={userData} paramsUrl={paramsUrl} />
            <div className="container-main__personal">
              <Main userData={userData} paramsUrl={paramsUrl} />
            </div>
          </Container>
        )}
      </Spin>
    </>
  );
};

export default Personal;
