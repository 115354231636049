import { PROFILE_TYPE_PERSONAL } from '../../defaults/ProfileType';

export const SAVE_DATA_PROFILE = '@register/SAVE_DATA_PROFILE';

export const CALL_PERSONAL_TRAINER_REGISTRY =
  '@user/CALL_PERSONAL_TRAINER_REGISTRY';
export const PERSONAL_TRAINER_REGISTRY_SUCCESS =
  '@user/PERSONAL_TRAINER_REGISTRY_SUCCESS';
export const PERSONAL_TRAINER_REGISTRY_FAILED =
  '@user/PERSONAL_TRAINER_REGISTRY_FAILED';

export const saveDataProfile = ({ data, profileType }) => ({
  type: SAVE_DATA_PROFILE,
  data,
  profileType,
});

export const callPersonalTrainerRegistry = ({ data }) => ({
  type: CALL_PERSONAL_TRAINER_REGISTRY,
  data,
});

export const personalTrainerRegistrySuccess = () => ({
  type: PERSONAL_TRAINER_REGISTRY_SUCCESS,
});

export const personalTrainerRegistryFailed = (error) => ({
  type: PERSONAL_TRAINER_REGISTRY_FAILED,
  error,
});

export const onSaveDataProfile = (state, { data, profileType }) => ({
  ...state,
  form: {
    ...state.form,
    register: {
      ...state.form.register,
      personal:
        profileType === PROFILE_TYPE_PERSONAL
          ? {
              ...state.form.register.personal,
              ...data,
            }
          : null,
      student:
        profileType !== PROFILE_TYPE_PERSONAL
          ? {
              ...state.form.register.student,
              ...data,
            }
          : null,
    },
  },
});

export const onCallPersonalTrainerRegistry = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    personalTrainerRegistry: true,
  },
  error: {
    ...state.error,
    personalTrainerRegistry: null,
  },
});

export const onPersonalTrainerRegistrySuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    personalTrainerRegistry: false,
  },
});

export const onPersonalTrainerRegistryFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    personalTrainerRegistry: false,
  },
  error: {
    ...state.error,
    personalTrainerRegistry: error,
  },
});
