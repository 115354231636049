import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Form } from 'antd';
import moment from 'moment';

import Button from '../../../elements/Button';
import Select from '../../../elements/Select';

import { getRuleField } from '../../../utility/RuleField';
import { useDispatch, useSelector } from '../../../utility/Context';
import { getAuthLocalStorage } from '../../../utility/localStorage';

import useNotification from '../../../hooks/useNotification';

import { callAvailability } from '../../../ducks/ApplicationDucks/Availability';
import { callEditClass } from '../../../ducks/ApplicationDucks/EditClass';

import { ContainerForm, ContainerButtonFooter } from './styles';

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

const ModalEdit = ({ open, handleCancel, professional, appointmentId }) => {
  const { showNotification, contextHolder } = useNotification();

  const [form] = Form.useForm();

  const userId = getAuthLocalStorage()?.id;

  const [currClassModality, setCurrClassModality] = useState([]);
  const [currSkill, setCurrSkill] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);

  const dispatch = useDispatch();

  const fetchAvailability = useCallback(() => {
    if (professional) {
      dispatch(callAvailability(professional?.nickname));
    }
  }, [dispatch, professional]);

  useEffect(() => {
    fetchAvailability();
  }, [professional]);

  const [availability, loading] = useSelector(({ user, application }) => [
    application.availability,
    application.loading.availability,
  ]);

  const getUnavailableTimes = useCallback(() => {
    const unavailableTimes = [];

    Object.keys(availability).forEach((date) => {
      availability[date].forEach((item) => {
        if (item.available) {
          unavailableTimes.push({
            label: moment(item.time).format('DD/MM/YYYY - HH:mm'),
            value: item.time,
          });
        }
      });
    });

    return setAvailabilities(unavailableTimes);
  }, [availability]);

  useEffect(() => {
    getUnavailableTimes();
  }, [availability]);

  const getSkills = useCallback(() => {
    const option = professional?.skills?.map((skill) => ({
      value: skill,
      label: skill,
    }));

    setCurrSkill(option);
  }, []);

  useEffect(() => {
    getSkills();
  }, [professional?.skills]);

  const getModality = useCallback(() => {
    const options = professional?.classModalities?.map((modality) => ({
      value: modality,
      label: MODALITY[modality],
    }));

    setCurrClassModality(options);
  }, []);

  useEffect(() => {
    getModality();
  }, [professional?.classModalities]);

  const onFinishFailed = () => {
    showNotification('warning', 'Atenção!', 'Não foi possivel cancelar a aula');
  };

  const handleReset = () => {
    form.resetFields();
  };

  const editClass = useCallback(
    (values) => {
      dispatch(callEditClass(appointmentId, values, userId));
    },
    [dispatch]
  );

  const onFinish = (values) => {
    const { classModality: modality, type, startDate } = values;

    if (!startDate || !modality || !type) {
      showNotification(
        'warning',
        'Atenção!',
        'É necessário preencher os campos obrigatórios'
      );
      return;
    }

    editClass(values);
    handleReset();
    handleCancel();
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Editar agendamento"
        open={open}
        footer={null}
        onCancel={() => {
          handleReset();
          handleCancel();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ContainerForm>
            <Select
              selectConfig={{
                options: availabilities,
                label: 'Horário da aula',
                placeholder: 'Selecione o horário da aula',
                required: true,
                name: 'startDate',
              }}
            />

            <Select
              selectConfig={{
                options: currSkill,
                label: 'Categoria da aula',
                placeholder: 'Selecione a categoria',
                required: true,
                name: 'type',
              }}
              rules={[
                getRuleField({
                  options: currSkill,
                  label: 'Categoria da aula',
                  placeholder: 'Selecione a categoria',
                  required: true,
                  name: 'type',
                }),
              ]}
            />

            <Select
              selectConfig={{
                options: currClassModality,
                label: 'Modalidade',
                placeholder: 'Selecione a modalidade',
                required: true,
                name: 'classModality',
              }}
              rules={[
                getRuleField({
                  options: currClassModality,
                  label: 'Modalidade',
                  placeholder: 'Selecione a modalidade',
                  required: true,
                  name: 'classModality',
                }),
              ]}
            />
          </ContainerForm>

          <ContainerButtonFooter>
            <Button
              key="back"
              // disabled={loading}
              onClick={() => {
                handleReset();
                handleCancel();
              }}
            >
              Voltar
            </Button>
            <Button
              key="submit"
              // loading={loading}
              type="primary"
              htmlType="submit"
            >
              Editar
            </Button>
          </ContainerButtonFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEdit;
