const daysWeek = [
  { dayOfWeek: 'sunday', label: 'Domingo' },
  { dayOfWeek: 'monday', label: 'Segunda-feira' },
  { dayOfWeek: 'tuesday', label: 'Terça-feira' },
  { dayOfWeek: 'wednesday', label: 'Quarta-feira' },
  { dayOfWeek: 'thursday', label: 'Quinta-feira' },
  { dayOfWeek: 'friday', label: 'Sexta-feira' },
  { dayOfWeek: 'saturday', label: 'Sábado' },
];

export default daysWeek;
