import React from 'react';
import dayjs from 'dayjs';
import { Space, Tag, Dropdown } from 'antd';
import { FiMoreVertical } from 'react-icons/fi';

import Button from '../../../elements/Button';
import ImagePreview from '../../ImagePreview';
import Avatar from '../../Avatar';
import Status from '../../Status';

import FinishClass from './FinishClass';
import ConfirmClass from './ConfirmClass';

import { ContainerBtnMobile, AvatarContainer } from './styles';

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

export default (isMobile, configModal) => [
  {
    title: 'Cliente',
    dataIndex: 'avatar',
    key: 'avatar',
    responsive: ['sm'],
    sorter: (a, b) => {
      if (a.client.firstName < b.client.firstName) {
        return -1;
      }
      if (a.client.firstName > b.client.firstName) {
        return 1;
      }
      return 0;
    },
    render: (_, { client }) => (
      <AvatarContainer>
        <Avatar avatar={client?.avatar} modalTitle="Avatar do cliente" />
        <p>
          {client?.firstName} {client?.lastName}
        </p>
      </AvatarContainer>
    ),
  },
  {
    title: 'Cliente',
    dataIndex: 'name',
    key: 'name',
    responsive: ['xs'],
    sorter: (a, b) => {
      if (a.client.firstName < b.client.firstName) {
        return -1;
      }
      if (a.client.firstName > b.client.firstName) {
        return 1;
      }
      return 0;
    },
    render: (_, { client }) => (
      <AvatarContainer>
        <p>
          {client?.firstName} {client?.lastName}
        </p>
      </AvatarContainer>
    ),
  },
  {
    title: 'Contato',
    key: 'cellphone',
    dataIndex: 'cellphone',
    responsive: ['xs', 'sm'],
    render: (_, { client }) => <p>{client?.phone}</p>,
  },
  {
    title: 'Tipo da aula',
    key: 'modality',
    dataIndex: 'modality',
    responsive: ['lg'],
    render: (_, { type }) => (type ? <Tag color="geekblue">{type}</Tag> : null),
    sorter: (a, b) => {
      if (a.type < b.type) {
        return -1;
      }
      if (a.type > b.type) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Modalidade',
    key: 'modality',
    dataIndex: 'modality',
    responsive: ['lg'],
    sorter: (a, b) => {
      if (a.classModality < b.classModality) {
        return -1;
      }
      if (a.classModality > b.classModality) {
        return 1;
      }
      return 0;
    },
    render: (_, { classModality }) => (
      <Tag color="geekblue">{MODALITY[classModality].toUpperCase()}</Tag>
    ),
  },
  {
    title: 'Valor',
    dataIndex: 'price',
    key: 'price',
    responsive: ['lg'],
    sorter: (a, b) => {
      return a.price - b.price;
    },
    render: (_, { price }) => (
      <p>{`${price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`}</p>
    ),
  },
  {
    title: 'Data da aula',
    dataIndex: 'date',
    key: 'date',
    responsive: ['sm'],
    render: (_, { startDate }) => (
      <p>{dayjs(startDate, 'YYYY/MM/DD').format('DD/MM/YYYY')}</p>
    ),
    sorter: (a, b) => {
      if (a.startDate < b.startDate) {
        return -1;
      }
      if (a.startDate > b.startDate) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Horário',
    dataIndex: 'date',
    key: 'date',
    responsive: ['sm'],
    render: (_, { startDate, endDate }) => (
      <p>
        {dayjs.tz(startDate, 'America/Sao_Paulo').format('HH:mm')} -{' '}
        {dayjs.tz(endDate, 'America/Sao_Paulo').format('HH:mm')}
      </p>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    responsive: ['md'],
    render: (_, { status }) => <Status status={status} />,
    sorter: (a, b) => {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Ações',
    key: 'action',
    responsive: ['xs', 'sm'],
    render: (_, { key, professional, status }) =>
      isMobile ? (
        <Dropdown
          menu={{
            items: [
              status === 'confirmed' && {
                key: '0',
                label: (
                  <ContainerBtnMobile>
                    <FinishClass id={key} />
                  </ContainerBtnMobile>
                ),
              },
              status === 'confirmed' && {
                key: '1',
                label: (
                  <ContainerBtnMobile>
                    <Button
                      type="link"
                      danger
                      onClick={configModal.showModalCancel}
                    >
                      Cancelar aula
                    </Button>
                  </ContainerBtnMobile>
                ),
              },
              status === 'pending_confirmation' && {
                key: '2',
                label: (
                  <>
                    <ConfirmClass id={key} configModal={configModal} />
                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        configModal.setModalData({ id: key });
                        configModal.showModalCancel();
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        configModal.setModalData({ id: key });
                        configModal.showModalReject();
                      }}
                    >
                      Rejeitar
                    </Button>
                  </>
                ),
              },
            ],
          }}
          trigger={['click']}
        >
          <Space>
            <FiMoreVertical className="menu-table__mobile" />
          </Space>
        </Dropdown>
      ) : (
        <>
          {status === 'confirmed' && <FinishClass id={key} />}

          {status === 'confirmed' && (
            <Button type="link" danger onClick={configModal.showModalCancel}>
              Cancelar aula
            </Button>
          )}

          {status === 'pending_confirmation' && (
            <>
              <ConfirmClass id={key} configModal={configModal} />
              <Button
                type="link"
                danger
                onClick={() => {
                  configModal.setModalData({ id: key });
                  configModal.showModalCancel();
                }}
              >
                Cancelar
              </Button>
              <Button
                type="link"
                danger
                onClick={() => {
                  configModal.setModalData({ id: key });
                  configModal.showModalReject();
                }}
              >
                Rejeitar
              </Button>
            </>
          )}
        </>
      ),
  },
];
