import React from 'react';
import PropTypes from 'prop-types';

import parseCurrencyFormatter from '../../utility/parseCurrencyFormatter';
import removeCommasAndDots from '../../utility/removeCommasAndDots';

import { FormItem, InputNumberAntd } from './styles';

const InputNumber = ({ inputNumberConfig, hasFeedback, rules }) => {
  const {
    min,
    max,
    formatter,
    parse,
    defaultValue,
    disabled,
    placeholder,
    ...rest
  } = inputNumberConfig;

  return (
    <FormItem {...rest}>
      <InputNumberAntd
        min={min}
        max={max}
        formatter={formatter}
        parse={parse}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FormItem>
  );

  // const formatCurrency = (value) => {
  //   if (!value) {
  //     return '';
  //   }

  //   const convertValueToString = value.toString();
  //   const valueCurrency = parseCurrencyFormatter(convertValueToString);

  //   return valueCurrency;
  // };

  // return (
  //   <FormItem {...rest} hasFeedback={hasFeedback} rules={rules}>
  //     <InputNumberAntd
  //       onChange={formatCurrency}
  //       formatter={(value) => parseCurrencyFormatter(value)}
  //       parser={(value) => removeCommasAndDots(value)}
  //       onKeyPress={(event) => {
  //         if (!/[0-9]/.test(event.key)) {
  //           event.preventDefault();
  //         }
  //       }}
  //       defaultValue={0}
  //       disabled={disabled}
  //     />
  //   </FormItem>
  // );
};

InputNumber.defaultProps = {
  hasFeedback: false,
};

InputNumber.propTypes = {
  inputNumberConfig: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.shape({
      marginBottom: PropTypes.string,
    }),
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default InputNumber;
