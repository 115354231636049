export const CALL_CATEGORY = '@application/CALL_CATEGORY';
export const CATEGORY_FAILED = '@application/CATEGORY_FAILED';
export const CATEGORY_SUCCESS = '@application/CATEGORY_SUCCESS';

export const callCategory = () => ({
  type: CALL_CATEGORY,
});

export const categoryFailed = (error) => ({
  type: CATEGORY_FAILED,
  error,
});

export const categorySuccess = (data) => ({
  type: CATEGORY_SUCCESS,
  data,
});

export const onCallCategory = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    category: true,
  },
  errors: {
    ...state.errors,
    category: null,
  },
  category: [],
});

export const onCategoryFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    category: false,
  },
  errors: {
    ...state.errors,
    category: error,
  },
  category: [],
});

export const onCategorySuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    category: false,
  },
  errors: {
    ...state.errors,
    category: null,
  },
  category: data,
});
