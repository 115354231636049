import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'antd-mask-input';

import { FormItem } from './styles';

const InputMasked = ({ inputMaskedConfig, hasFeedback, rules, onChange }) => {
  const { placeholder, type, mask, disabled, ...rest } = inputMaskedConfig;

  return (
    <FormItem {...rest} hasFeedback={hasFeedback} rules={rules}>
      <MaskedInput
        placeholder={placeholder}
        mask={mask}
        onChange={onChange}
        disabled={disabled}
      />
    </FormItem>
  );
};

InputMasked.defaultProps = {
  hasFeedback: false,
};

InputMasked.propTypes = {
  inputMaskedConfig: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.shape({
      marginBottom: PropTypes.string,
    }),
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default InputMasked;
