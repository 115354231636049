export const getHeaders = () => {
  const token = localStorage.getItem('token');

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${token}`,
    'Content-Language': 'pt-BR',
  };

  return headers;
};

export default { getHeaders };
