export const CALL_WEIGHTS = '@user/CALL_WEIGHTS';
export const WEIGHTS_FAILED = '@user/WEIGHTS_FAILED;';
export const WEIGHTS_SUCCESS = '@user/WEIGHTS_SUCCESS';

export const callWeights = (userId) => ({
  type: CALL_WEIGHTS,
  userId,
});

export const weightsFailed = (error) => ({
  type: WEIGHTS_FAILED,
  error,
});

export const weightsSuccess = (data) => ({
  type: WEIGHTS_SUCCESS,
  data,
});

export const onCallWeights = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    weights: true,
  },
  errors: {
    ...state.errors,
    weights: null,
  },
});

export const onWeightsFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    weights: false,
  },
  errors: {
    ...state.errors,
    weights: error,
  },
});

export const onWeightsSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    weights: false,
  },
  errors: {
    ...state.errors,
    weights: null,
  },
  data: {
    ...state.data,
    weights: data,
  },
});
