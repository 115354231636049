export const CALL_FINISH_CLASS = '@application/CALL_FINISH_CLASS';
export const FINISH_CLASS_FAILED = '@application/FINISH_CLASS_FAILED';
export const FINISH_CLASS_SUCCESS = '@application/FINISH_CLASS_SUCCESS';

export const callFinishClass = (appointmentId) => ({
  type: CALL_FINISH_CLASS,
  appointmentId,
});

export const finishClassFailed = (error) => ({
  type: FINISH_CLASS_FAILED,
  error,
});

export const finishClassSuccess = () => ({
  type: FINISH_CLASS_SUCCESS,
});

export const onCallFinishClass = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    finishClass: true,
  },
  errors: {
    ...state.errors,
    finishClass: null,
  },
});

export const onCallFinishClassFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    finishClass: false,
  },
  errors: {
    ...state.errors,
    finishClass: error,
  },
});

export const onCallFinishClassSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    finishClass: false,
  },
  errors: {
    ...state.errors,
    finishClass: null,
  },
});
