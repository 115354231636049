import React from 'react';
import { Space, Tag, Button, Tooltip } from 'antd';
import { AiOutlinePushpin } from 'react-icons/ai';
import { AvatarContainer } from '../../Client/MyProfessionals/styles';
import Avatar from '../../Avatar';

// import CancelClass from './CancelClass';
// import RateClass from './RateClass';

export default [
  {
    title: 'Cliente',
    dataIndex: 'avatar',
    key: 'avatar',
    responsive: ['sm', 'md', 'lg'],
    sorter: (a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    },
    render: (_, { nickname, avatar, firstName, lastName }) => (
      <AvatarContainer>
        <Avatar avatar={avatar} modalTitle="Avatar do cliente" />
        <p>
          {firstName} {lastName}
        </p>
      </AvatarContainer>
    ),
  },
  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
    responsive: ['xs'],
    sorter: (a, b) => {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    },
    render: (_, { firstName, lastName }) => (
      <AvatarContainer>
        <p>
          {firstName} {lastName}
        </p>
      </AvatarContainer>
    ),
  },
  {
    title: 'Objetivos',
    key: 'customerObjectives',
    dataIndex: 'customerObjectives',
    responsive: ['sm', 'md'],
    render: (_, { objectives }) => (
      <>
        {objectives?.map((objective) => (
          <Tag color="geekblue" key={objective}>
            {objective.toUpperCase()}
          </Tag>
        ))}
      </>
    ),
  },
  {
    title: 'Peso atual',
    dataIndex: 'lastWeight',
    key: 'lastWeight',
    responsive: ['md'],
    render: (_, { lastWeight }) => (
      <p>{lastWeight?.length === 0 ? '-' : `${lastWeight / 1000} kgs`}</p>
    ),
    sorter: (a, b) => {
      return a.lastWeight - b.lastWeight;
    },
  },
  {
    title: 'Taxa de gordura corporal atual',
    dataIndex: 'lastBodyFat',
    key: 'lastBodyFat',
    responsive: ['md'],
    render: (_, { lastBodyFat }) => (
      <p>{lastBodyFat?.length === 0 ? '-' : `${lastBodyFat}%`}</p>
    ),
    sorter: (a, b) => {
      return a.lastBodyFat - b.lastBodyFat;
    },
  },
  {
    title: 'Altura atual',
    dataIndex: 'lastHeight',
    key: 'lastHeight',
    responsive: ['md'],
    render: (_, { lastHeight }) => (
      <p>{lastHeight?.length === 0 ? '-' : `${lastHeight} cm`}</p>
    ),
    sorter: (a, b) => {
      return a.lastHeight - b.lastHeight;
    },
  },
  {
    title: 'Aulas realizadas',
    dataIndex: 'totalClasses',
    key: 'totalClasses',
    render: (_, { totalClasses }) => <p>{totalClasses} aula(s)</p>,
    responsive: ['xs', 'sm'],
    sorter: (a, b) => {
      return a.totalClasses - b.totalClasses;
    },
  },
];
