import React, { useCallback, useEffect } from 'react';
import { Divider, Modal, Form, notification, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import Input from '../../../../elements/Input';
import InputNumber from '../../../../elements/InputNumber';
import Button from '../../../../elements/Button';

import {
  INPUT_WEIGHT,
  INPUT_HEIGHT,
  INPUT_BODY_FAT,
} from '../../../../defaults/RegisterUserFields';

import { getRuleField } from '../../../../utility/RuleField';
import removeCommasAndDots from '../../../../utility/removeCommasAndDots';
import { parseKgToGrams } from '../../../../utility/parseKgToGrams';
import validateOnlyNumbers from '../../../../utility/validateOnlyNumbers';

import { useDispatch, useSelector } from '../../../../utility/Context';

import { callMeasures } from '../../../../ducks/UserDucks/Measures';

import { ContainerFields, ContainerButtonFooter } from '../styles';

const ModalMeasures = ({ userId, userData, isModalOpen, setIsModalOpen }) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel salvar os dados');
  };

  const dispatch = useDispatch();

  const updateMeasures = useCallback(
    (weight, height, bodyFat) => {
      dispatch(
        callMeasures({
          userId,
          weight,
          height,
          bodyFat,
        })
      );
    },
    [dispatch]
  );

  const onFinish = ({ weight, height, bodyFat }) => {
    const currWeight = removeCommasAndDots(parseKgToGrams(weight));
    const convertIntWeight = parseInt(currWeight, 10);

    if (convertIntWeight < 30000) {
      message(
        'warning',
        'Atenção!',
        'O peso precisa ter um valor mínimo de 30 kgs.'
      );
      return;
    }

    const convertIntHeight = parseInt(height, 10);

    if (convertIntHeight < 100) {
      message(
        'warning',
        'Atenção!',
        'A altura precisa ter um valor mínimo de 100 centímetros.'
      );
      return;
    }

    updateMeasures(convertIntWeight, convertIntHeight, bodyFat);
  };

  const [loading] = useSelector(({ user }) => [user.loading.measures]);

  return (
    <>
      {contextHolder}
      <Modal
        title="Minhas medidas"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            weight: userData.weight,
            height: userData.height,
            bodyFat: userData.bodyFat,
          }}
        >
          <ContainerFields>
            <Input
              inputConfig={INPUT_WEIGHT}
              rules={[
                getRuleField(INPUT_WEIGHT),
                { validator: validateOnlyNumbers },
              ]}
            />

            <Input
              inputConfig={INPUT_HEIGHT}
              rules={[
                getRuleField(INPUT_HEIGHT),
                { validator: validateOnlyNumbers },
              ]}
            />

            <InputNumber
              inputNumberConfig={{
                ...INPUT_BODY_FAT,
                min: 0,
                max: 100,
                formatter: (value) => `${value}%`,
                parser: (value) => value?.replace('%', ''),
                defaultValue: 0,
              }}
            />

            <ContainerButtonFooter>
              <Button key="back" disabled={loading} onClick={handleCancel}>
                Voltar
              </Button>
              <Button
                key="submit"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Confirmar
              </Button>
            </ContainerButtonFooter>
          </ContainerFields>
        </Form>
      </Modal>
    </>
  );
};

export default ModalMeasures;
