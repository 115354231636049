import React, { useState, useCallback, useEffect } from 'react';
import { Divider, notification, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { BsQuestionCircle } from 'react-icons/bs';

import UploadImage from '../../../UploadImage';

import Input from '../../../../elements/Input';
import InputMasked from '../../../../elements/InputMasked';
import DatePicker from '../../../../elements/DatePicker';
import Textarea from '../../../../elements/Textarea';
import Select from '../../../../elements/Select';
import Button from '../../../../elements/Button';

import {
  INPUT_FIRST_NAME,
  INPUT_LAST_NAME,
  INPUT_CPF,
  DATE_PICKER_BIRTH_DATE,
  TEXTAREA_RESTRICTION,
  INPUT_EMAIL,
  SELECT_MULTIPLE_CUSTOMER_OBJECTIVES,
  INPUT_NUMBER_PHONE,
  INPUT_ZIP_CODE,
} from '../../../../defaults/RegisterUserFields';
import { getRuleField } from '../../../../utility/RuleField';
import { useDispatch, useSelector } from '../../../../utility/Context';
import removeCPFCharacter from '../../../../utility/removeCPFCharacter';
import { convertCustomerUpdateBackend } from '../../../../utility/convertCustomerDataBackend';
import validateCPF from '../../../../utility/validateCPF';

import { callUpdateCustomerData } from '../../../../ducks/UserDucks/UpdateCustomerData';
import { callCategory } from '../../../../ducks/ApplicationDucks/Category';

import useNotification from '../../../../hooks/useNotification';

import { Form, Title, TooltipContainerCellphone } from '../styles';
import {USER_TYPE_CLIENT} from "../../../../defaults/userType";

const Profile = ({ userId }) => {
  const { showNotification, contextHolder } = useNotification();

  const [
    userData,
    userDataLoading,
    updateCustomerDataLoading,
    categories,
    categoriesLoading,
  ] = useSelector(({ user, application }) => [
    user.data.customer,
    user.loading.customerData,
    user.loading.updateCustomerData,
    application.category,
    application.loading.category,
  ]);

  const dispatch = useDispatch();

  const category = useCallback(() => {
    dispatch(callCategory());
  }, [dispatch]);

  useEffect(() => {
    category();
  }, []);

  const updateCustomerData = useCallback(
    (data) => {
      dispatch(
        callUpdateCustomerData({
          data,
          userId,
        })
      );
    },
    [dispatch]
  );

  const onFinish = (values) => {
    const {
      firstName,
      lastName,
      birthDate,
      cpf,
      phone,
      zipCode,
      restriction,
      customerObjectives,
    } = values;

    const removeCommasAndDotsCPF = removeCPFCharacter(cpf);
    const isValidCPF = validateCPF(removeCommasAndDotsCPF);

    if (!isValidCPF) {
      showNotification(
        'warning',
        'CPF inválido',
        'Ops! Parece que o CPF inserido não está correto. Por favor, confira e insira um CPF válido.'
      );

      return;
    }

    if (customerObjectives?.length > 2) {
      showNotification(
        'warning',
        'O limite de objetivos foi atingido',
        'Selecione no máximo dois objetivos'
      );
      return;
    }

    const inputDate = new Date(birthDate);

    const rfc3339FormattedDate = moment(inputDate).format(
      'YYYY-MM-DDTHH:mm:ss[Z]'
    );

    const body = convertCustomerUpdateBackend({
      firstName,
      lastName,
      birthDate: rfc3339FormattedDate,
      phone,
      zipCode,
      restriction,
      customerObjectives,
    });

    updateCustomerData(body);
  };

  const onFinishFailed = () => {
    showNotification(
      'warning',
      'Preencha os dados corretamente',
      'Por favor, preencha o formulário corretamente para continuar.'
    );
  };

  const today = new Date();
  const date = new Date();
  const minimumAge = date.setFullYear(today.getFullYear() - 18);
  const maximumAge = date.setFullYear(today.getFullYear() - 120);

  const disabledDate = (current) =>
    (current && current > minimumAge) || current < maximumAge;

  if (!userData) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={userDataLoading}
      >
        <h3>Carregando...</h3>
      </Spin>
    );
  }

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          cpf: userData.cpf,
          birthDate: dayjs(userData.birthDate.split('T')[0], 'YYYY/MM/DD'),
          restriction: userData.restriction,
          customerObjectives: userData.customerObjectives,
          phone: userData.phone,
          zipCode: userData.zipCode,
        }}
      >
        <Title>Informações gerais</Title>
        <Divider />

        <div>
          <UploadImage userId={userId} userType={USER_TYPE_CLIENT} defaultAvatar={userData.avatar} />
        </div>

        <Input
          inputConfig={{
            ...INPUT_FIRST_NAME,
            maxLength: 255,
          }}
          rules={[getRuleField(INPUT_FIRST_NAME)]}
        />

        <Input
          inputConfig={{ ...INPUT_LAST_NAME, maxLength: 255 }}
          rules={[getRuleField(INPUT_LAST_NAME)]}
        />

        <DatePicker
          datePickerConfig={DATE_PICKER_BIRTH_DATE}
          rules={[getRuleField(DATE_PICKER_BIRTH_DATE)]}
          disabledDate={disabledDate}
        />

        <InputMasked
          inputMaskedConfig={{ ...INPUT_CPF, disabled: true }}
          rules={[getRuleField(INPUT_CPF)]}
        />

        <Input
          inputConfig={{
            ...INPUT_EMAIL,
            tooltip: {
              title:
                'Para alterar este dado, entre em contato com o suporte no e-mail suporte@personally.fit.',
            },
            disabled: true,
          }}
          rules={[getRuleField(INPUT_EMAIL)]}
        />

        <TooltipContainerCellphone>
          <InputMasked
            inputMaskedConfig={INPUT_NUMBER_PHONE}
            rules={[getRuleField(INPUT_NUMBER_PHONE)]}
          />

          <Tooltip title="Este número será disponibilizado como meio de contato para o profissional.">
            <BsQuestionCircle />
          </Tooltip>
        </TooltipContainerCellphone>

        <TooltipContainerCellphone>
          <InputMasked
            inputMaskedConfig={INPUT_ZIP_CODE}
            rules={[getRuleField(INPUT_ZIP_CODE)]}
          />

          <Tooltip title="Este dado será coletado para fins de análise estatística e não será divulgado. ">
            <BsQuestionCircle />
          </Tooltip>
        </TooltipContainerCellphone>

        <Textarea
          textareaConfig={TEXTAREA_RESTRICTION}
          rules={[getRuleField(TEXTAREA_RESTRICTION)]}
        />

        <Select
          selectConfig={{
            ...SELECT_MULTIPLE_CUSTOMER_OBJECTIVES,
            options: categories,
            disabled: categoriesLoading,
            label: 'Objetivos (no máximo dois)',
          }}
        />

        <Button
          type="primary"
          className="btn-save-data"
          htmlType="submit"
          loading={updateCustomerDataLoading}
        >
          Salvar dados
        </Button>
      </Form>
    </>
  );
};

export default Profile;
