import React from 'react';
import { Dropdown } from 'antd';
import { FiMenu } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import Button from '../../../elements/Button';
import Logo from '../../Logo';

import {
  ContainerDesktop,
  ContainerMobile,
  NavMenuContainer,
  SessionContainer,
} from './styles';

const HeaderUnauthenticated = ({ items, logoLink }) => {
  const { isMobile } = useWindowDimensions();

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const itemsMenuHeader = [
    {
      key: '1',
      label: 'Sou profissional',
      onClick: () => redirect('/personal'),
      type: 'default',
    },
    {
      key: '2',
      label: 'Sou aluno(a)',
      onClick: () => redirect('/sign-up-client'),
      type: 'default',
    },
    {
      key: '3',
      label: 'Entrar',
      onClick: () => redirect('/login'),
      type: 'primary',
    },
  ];

  return (
    <>
      {isMobile ? (
        <ContainerMobile>
          <Logo logoLink={logoLink} />
          <Dropdown
            menu={{
              items: itemsMenuHeader,
              selectable: true,
            }}
          >
            <FiMenu />
          </Dropdown>
        </ContainerMobile>
      ) : (
        <ContainerDesktop>
          <Logo logoLink={logoLink} />

          <SessionContainer>
            {itemsMenuHeader.map(
              ({ key, label, onClick, type, shape, icon, className }) => (
                <Button
                  key={key}
                  type={type}
                  onClick={onClick}
                  className={className}
                  shape={shape}
                  icon={icon}
                >
                  {label}
                </Button>
              )
            )}
          </SessionContainer>
        </ContainerDesktop>
      )}
    </>
  );
};

export default HeaderUnauthenticated;
