import styled, { createGlobalStyle } from 'styled-components';

// import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
  MEDIUM_RED_COLOR,
  SHADOW_COLOR,
} from '../../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'side-bar__container',
})`
  &.side-bar__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 300px;

    & .avatar {
      width: 250px;
      height: 250px;
      font-size: 230px;
    }

    & img {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      border: 6px solid ${PRIMARY_COLOR};
    }

    & .more-info {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-bottom: 8px;

      & svg {
        color: ${PRIMARY_COLOR};
        font-size: 24px;
      }
    }

    & .info-fullname {
      display: flex;
      gap: 24px;
      align-items: center;

      & svg {
        color: #656d76;
        font-size: 2rem;

        &.valid-cref {
          color: ${PRIMARY_COLOR};
        }

        &.invalid-cref {
          color: ${SHADOW_COLOR};
        }
      }
    }

    & .fullname {
      color: ${PRIMARY_COLOR};
      font-size: 24px;
      line-height: 1.25;
    }

    & .nickname {
      font-size: 20px;
      font-style: normal;
      font-weight: #656d76;
      line-height: 24px;
      margin-bottom: 24px;
    }

    & .about-class,
    .tag__container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    & .tag-container__type {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    & .tag__container h3 {
      margin-bottom: 12px;
    }

    & .enable-profile {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      gap: 16px;
    }
  }
`;

export default { Container };
