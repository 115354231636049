export const CALL_PERSONAL_DATA = '@user/CALL_PERSONAL_DATA';
export const PERSONAL_DATA_FAILED = '@user/PERSONAL_DATA_FAILED';
export const PERSONAL_DATA_SUCCESS = '@user/PERSONAL_DATA_SUCCESS';

export const callPersonalData = (paramsUrl) => ({
  type: CALL_PERSONAL_DATA,
  paramsUrl,
});

export const personalDataFailed = (error) => ({
  type: PERSONAL_DATA_FAILED,
  error,
});

export const personalDataSuccess = (data) => ({
  type: PERSONAL_DATA_SUCCESS,
  data,
});

export const onCallPersonalData = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    personalData: true,
  },
  errors: {
    ...state.errors,
    personalData: null,
  },
});

export const onCallPersonalDataFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    personalData: false,
  },
  errors: {
    ...state.errors,
    personalData: true,
  },
});

export const onCallPersonalDataSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    personalData: false,
  },
  errors: {
    ...state.errors,
    personalData: null,
  },
  data: {
    ...state.data,
    personal: data,
  },
});
