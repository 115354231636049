import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { FiMenu } from 'react-icons/fi';

import { getAuthLocalStorage } from '../../utility/localStorage';
import { useDispatch, useSelector } from '../../utility/Context';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import {
  USER_TYPE_PROFESSIONAL,
  USER_TYPE_CLIENT,
  USER_TYPE_UNAUTHENTICATED,
} from '../../defaults/userType';
import {
  ROUTE_HOME,
  PRIVATE_ROUTE_SETTINGS,
  PRIVATE_ROUTE_PERSONAL_SETTINGS,
  PRIVATE_ROUTE_SEARCH,
  PRIVATE_ROUTE_PERSONAL_APPOINTMENTS,
  PRIVATE_ROUTE_MY_CLIENTS,
  PRIVATE_ROUTE_APPOINTMENTS,
  PRIVATE_ROUTE_DASHBOARD_MEASURES,
  ROUTE_SEARCH,
  PRIVATE_ROUTE_MY_PROFESSIONALS,
} from '../../defaults/Routes';

import { callNotification } from '../../ducks/ApplicationDucks/Notification';

import Button from '../../elements/Button';

import HeaderUnauthenticated from './HeaderUnauthenticated';
import Notification from './Notification';
import Logo from '../Logo';

import { FooterStyled } from './styles';

const Header = () => {
  const authUserType = getAuthLocalStorage()?.userType;

  let userType = USER_TYPE_UNAUTHENTICATED;
  let logoLink = '/';
  if (authUserType === USER_TYPE_PROFESSIONAL) {
    userType = USER_TYPE_PROFESSIONAL;
  }

  if (authUserType === USER_TYPE_CLIENT) {
    userType = USER_TYPE_CLIENT;
  }

  if (userType === USER_TYPE_UNAUTHENTICATED) {
    return <HeaderUnauthenticated logoLink={logoLink} />;
  }

  const [itemsNavBar, setItemsNavBar] = useState([]);
  const [itemsMenuDropdown, setItemsMenuDropdown] = useState([]);

  const { isMobile } = useWindowDimensions();

  const userId = getAuthLocalStorage()?.id;

  const dispatch = useDispatch();

  const notification = useCallback(() => {
    dispatch(callNotification(userId));
  }, [dispatch]);

  useEffect(() => {
    notification();
  }, []);

  const [notifications] = useSelector(({ application }) => [
    application.notification,
  ]);

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const itemsNavBarDefault = [
    {
      key: 1,
      label: 'Inicio',
      onClick: () => redirect(ROUTE_HOME),
      enable: [USER_TYPE_PROFESSIONAL],
    },
    {
      key: 2,
      label: 'Meus agendamentos',
      onClick: () => redirect(PRIVATE_ROUTE_PERSONAL_APPOINTMENTS),
      enable: [USER_TYPE_PROFESSIONAL],
    },
    {
      key: 3,
      label: 'Meus clientes',
      onClick: () => redirect(PRIVATE_ROUTE_MY_CLIENTS),
      enable: [USER_TYPE_PROFESSIONAL],
    },
    {
      key: 4,
      label: 'Meus agendamentos',
      onClick: () => redirect(PRIVATE_ROUTE_APPOINTMENTS),
      enable: [USER_TYPE_CLIENT],
    },
    {
      key: 5,
      label: 'Minhas medidas',
      onClick: () => redirect(PRIVATE_ROUTE_DASHBOARD_MEASURES),
      enable: [USER_TYPE_CLIENT],
    },
    {
      key: 6,
      label: 'Meus profissionais',
      onClick: () => redirect(PRIVATE_ROUTE_MY_PROFESSIONALS),
      enable: [USER_TYPE_CLIENT],
    },
    {
      key: 7,
      label: 'Buscar',
      onClick: () => redirect(ROUTE_SEARCH),
      enable: [USER_TYPE_PROFESSIONAL, USER_TYPE_CLIENT],
    },
  ];

  const itemsMenuDropdownDefault = [
    {
      key: 8,
      label: 'Meu perfil',
      onClick: () => redirect(PRIVATE_ROUTE_SETTINGS),
      enable: [USER_TYPE_CLIENT],
    },
    {
      key: 9,
      label: 'Meu perfil',
      onClick: () => redirect(PRIVATE_ROUTE_PERSONAL_SETTINGS),
      enable: [USER_TYPE_PROFESSIONAL],
    },
    {
      key: 10,
      label: 'Sair',
      onClick: () => {
        localStorage.clear();
        redirect('/login');
      },
      enable: [USER_TYPE_PROFESSIONAL, USER_TYPE_CLIENT],
    },
  ];

  const filterItemsNavBar = useCallback(() => {
    const itemsNav = itemsNavBarDefault.filter((item) =>
      item.enable.find((enable) => enable === userType)
    );

    const itemsMenu = itemsMenuDropdownDefault.filter((item) =>
      item.enable.find((enable) => enable === userType)
    );

    setItemsNavBar(itemsNav);
    setItemsMenuDropdown(itemsMenu);
  }, [userType]);

  useEffect(() => {
    filterItemsNavBar();
  }, [userType]);

  logoLink = '/';

  return (
    <FooterStyled>
      {isMobile ? (
        <div className="header__dropdown">
          <Logo logoLink={logoLink} />
          <Dropdown
            menu={{
              items: [...itemsNavBar, ...itemsMenuDropdown],
              selectable: true,
            }}
          >
            <FiMenu className="dropdown__icon" />
          </Dropdown>
        </div>
      ) : (
        <>
          <div className="header__nav-bar">
            <Logo logoLink={logoLink} />

            {itemsNavBar?.map(({ onClick, label }, index) => (
              <Button key={index} type="link" onClick={onClick}>
                {label}
              </Button>
            ))}
          </div>
          <div className="header-left">
            <Notification notifications={notifications} userId={userId} />

            <Dropdown
              trigger={['click']}
              menu={{
                items: itemsMenuDropdown,
              }}
            >
              <FiMenu className="dropdown__icon" />
            </Dropdown>
          </div>
        </>
      )}
    </FooterStyled>
  );
};

export default Header;
