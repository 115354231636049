export const CALL_SIGN_UP_CLIENT = '@user/CALL_SIGN_UP_CLIENT';
export const SIGN_UP_CLIENT_FAILED = '@user/SIGN_UP_CLIENT_FAILED';
export const SIGN_UP_CLIENT_SUCCESS = '@user/SIGN_UP_CLIENT_SUCCESS';

export const callSignUpClient = (data) => ({
  type: CALL_SIGN_UP_CLIENT,
  data,
});

export const signUpClientFailed = (error) => ({
  type: SIGN_UP_CLIENT_FAILED,
  error,
});

export const signUpClientSuccess = () => ({
  type: SIGN_UP_CLIENT_SUCCESS,
});

export const onCallSignUpClient = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    signUp: { ...state.loading.signUp, client: true },
  },
  errors: {
    ...state.errors,
    signUp: { ...state.errors.signUp, client: null },
  },
});

export const onSignUpClientFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    signUp: { ...state.loading.signUp, client: false },
  },
  errors: {
    ...state.errors,
    signUp: { ...state.errors.signUp, client: error },
  },
});

export const onSignUpClientSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    signUp: { ...state.loading.signUp, client: false },
  },
  errors: {
    ...state.errors,
    signUp: { ...state.errors.signUp, client: null },
  },
});
