import {
  SAVE_DATA_PROFILE,
  CALL_PERSONAL_TRAINER_REGISTRY,
  PERSONAL_TRAINER_REGISTRY_SUCCESS,
  PERSONAL_TRAINER_REGISTRY_FAILED,
  onSaveDataProfile,
  onCallPersonalTrainerRegistry,
  onPersonalTrainerRegistrySuccess,
  onPersonalTrainerRegistryFailed,
} from './PersonalTrainerRegistry';
import {
  ADD_PROFILE_TYPE_REGISTER,
  onAddProfileTypeRegister,
} from './ProfileTypeRegisterUser';
import {
  CALL_CATEGORY,
  CATEGORY_FAILED,
  CATEGORY_SUCCESS,
  onCallCategory,
  onCategoryFailed,
  onCategorySuccess,
} from './Category';
import {
  CALL_PROFESSIONAL_COUNT,
  PROFESSIONAL_COUNT_FAILED,
  PROFESSIONAL_COUNT_SUCCESS,
  onCallProfessionalCount,
  onProfessionalCountFailed,
  onProfessionalCountSuccess,
} from './ProfessionalCount';
import {
  CALL_NOTIFICATION,
  NOTIFICATION_FAILED,
  NOTIFICATION_SUCCESS,
  CALL_READ_NOTIFICATION,
  READ_NOTIFICATION_FAILED,
  READ_NOTIFICATION_SUCCESS,
  onCallNotification,
  onNotificationFailed,
  onNotificationSuccess,
  onCallReadNotification,
  onReadNotificationFailed,
  onReadNotificationSuccess,
} from './Notification';
import {
  CALL_SEND_EMAIL,
  SEND_EMAIL_FAILED,
  SEND_EMAIL_SUCCESS,
  onCallSendEmail,
  onSendEmailFailed,
  onSendEmailSuccess,
} from './SendEmail';
import {
  CALL_CREATE_POST,
  CREATE_POST_FAILED,
  CREATE_POST_SUCCESS,
  onCallCreatePost,
  onCreatePostFailed,
  onCreatePostSuccess,
} from './CreatePost';
import {
  CALL_DELETE_POST,
  DELETE_POST_FAILED,
  DELETE_POST_SUCCESS,
  onCallDeletePost,
  onDeletePostFailed,
  onDeletePostSuccess,
} from './DeletePost';
import {
  FETCH_CATALOG,
  CATALOG_FAILED,
  CATALOG_SUCCESS,
  onFetchCatalog,
  onCatalogFailed,
  onCatalogSuccess,
} from './FetchCatalog';
import {
  CALL_RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  onCallResetPassword,
  onResetPasswordFailed,
  onResetPasswordSuccess,
} from './ResetPassword';
import {
  CALL_PERSONAL_CLIENTS,
  PERSONAL_CLIENTS_FAILED,
  PERSONAL_CLIENTS_SUCCESS,
  onCallPersonalClients,
  onPersonalClientsFailed,
  onPersonalClientsSuccess,
} from './PersonalClients';
import {
  FETCH_CLIENT_APPOINTMENT,
  CLIENT_APPOINTMENT_FAILED,
  CLIENT_APPOINTMENT_SUCCESS,
  onFetchClientAppointment,
  onClientAppointmentFailed,
  onClientAppointmentSuccess,
} from './FetchClientAppointments';
import {
  CALL_CLIENTS_PROFESSIONALS,
  CLIENTS_PROFESSIONALS_FAILED,
  CLIENTS_PROFESSIONALS_SUCCESS,
  onCallClientsProfessionals,
  onClientsProfessionalsFailed,
  onClientsProfessionalsSuccess,
} from './ClientsProfessionals';
import {
  CALL_CLIENTS_PROFESSIONALS_FAVORITES,
  CALL_CLIENTS_PROFESSIONALS_FAVORITES_FAILED,
  CALL_CLIENTS_PROFESSIONALS_FAVORITES_SUCCESS,
  onCallClientsProfessionalsFavorites,
  onClientsProfessionalsFavoritesFailed,
  onClientsProfessionalsFavoritesSuccess,
} from './ClientsProfessionalsFavorites';
import {
  CALL_RATE,
  RATE_FAILED,
  RATE_SUCCESS,
  onCallRate,
  onRateFailed,
  onRateSuccess,
} from './Rate';
import {
  CALL_CANCEL_CLASS,
  CANCEL_CLASS_FAILED,
  CANCEL_CLASS_SUCCESS,
  onCancelClass,
  onCancelClassFailed,
  onCancelClassSuccess,
} from './CancelClass';
import {
  CALL_AVAILABILITY,
  AVAILABILITY_FAILED,
  AVAILABILITY_SUCCESS,
  onCallAvailability,
  onAvailabilityFailed,
  onAvailabilitySuccess,
} from './Availability';
import {
  CALL_BOOK_CLASS,
  BOOK_CLASS_FAILED,
  BOOK_CLASS_SUCCESS,
  onCallBookClass,
  onBookClassFailed,
  onBookClassSuccess,
} from './BookClass';
import {
  FETCH_PROFESSIONALS_APPOINTMENT,
  PROFESSIONALS_APPOINTMENT_FAILED,
  PROFESSIONALS_APPOINTMENT_SUCCESS,
  onFetchProfessionalsAppointment,
  onProfessionalsAppointmentFailed,
  onProfessionalsAppointmentSuccess,
  professionalsAppointmentFailed,
  professionalsAppointmentSuccess,
} from './FetchProfessionalsAppointments';
import {
  CALL_ENABLE_PROFILE,
  ENABLE_PROFILE_FAILED,
  ENABLE_PROFILE_SUCCESS,
  onCallEnableProfile,
  onEnableProfileFailed,
  onEnableProfileSuccess,
} from './EnableProfile';
import {
  CALL_CONFIRM_CLASS,
  CONFIRM_CLASS_FAILED,
  CONFIRM_CLASS_SUCCESS,
  onCallConfirmClass,
  onConfirmClassFailed,
  onConfirmClassSuccess,
} from './ConfirmClass';
import {
  CALL_REJECT_CLASS,
  REJECT_CLASS_FAILED,
  REJECT_CLASS_SUCCESS,
  onCallRejectClass,
  onRejectClassFailed,
  onRejectClassSuccess,
} from './Reject';
import {
  CALL_FINISH_CLASS,
  FINISH_CLASS_FAILED,
  FINISH_CLASS_SUCCESS,
  onCallFinishClass,
  onCallFinishClassFailed,
  onCallFinishClassSuccess,
} from './FinishClass';
import {
  CALL_EDIT_CLASS,
  EDIT_CLASS_FAILED,
  EDIT_CLASS_SUCCESS,
  onCallEditClass,
  onEditClassFailed,
  onEditClassSuccess,
} from './EditClass';

const ACTION_HANDLERS = {
  [CALL_PERSONAL_TRAINER_REGISTRY]: onCallPersonalTrainerRegistry,
  [PERSONAL_TRAINER_REGISTRY_SUCCESS]: onPersonalTrainerRegistrySuccess,
  [PERSONAL_TRAINER_REGISTRY_FAILED]: onPersonalTrainerRegistryFailed,

  [ADD_PROFILE_TYPE_REGISTER]: onAddProfileTypeRegister,

  [SAVE_DATA_PROFILE]: onSaveDataProfile,

  [CALL_CATEGORY]: onCallCategory,
  [CATEGORY_FAILED]: onCategoryFailed,
  [CATEGORY_SUCCESS]: onCategorySuccess,

  [CALL_PROFESSIONAL_COUNT]: onCallProfessionalCount,
  [PROFESSIONAL_COUNT_FAILED]: onProfessionalCountFailed,
  [PROFESSIONAL_COUNT_SUCCESS]: onProfessionalCountSuccess,

  [CALL_NOTIFICATION]: onCallNotification,
  [NOTIFICATION_FAILED]: onNotificationFailed,
  [NOTIFICATION_SUCCESS]: onNotificationSuccess,

  [CALL_READ_NOTIFICATION]: onCallReadNotification,
  [READ_NOTIFICATION_FAILED]: onReadNotificationFailed,
  [READ_NOTIFICATION_SUCCESS]: onReadNotificationSuccess,

  [CALL_SEND_EMAIL]: onCallSendEmail,
  [SEND_EMAIL_FAILED]: onSendEmailFailed,
  [SEND_EMAIL_SUCCESS]: onSendEmailSuccess,

  [CALL_CREATE_POST]: onCallCreatePost,
  [CREATE_POST_FAILED]: onCreatePostFailed,
  [CREATE_POST_SUCCESS]: onCreatePostSuccess,

  [CALL_DELETE_POST]: onCallDeletePost,
  [DELETE_POST_FAILED]: onDeletePostFailed,
  [DELETE_POST_SUCCESS]: onDeletePostSuccess,

  [FETCH_CATALOG]: onFetchCatalog,
  [CATALOG_FAILED]: onCatalogFailed,
  [CATALOG_SUCCESS]: onCatalogSuccess,

  [CALL_RESET_PASSWORD]: onCallResetPassword,
  [RESET_PASSWORD_FAILED]: onResetPasswordFailed,
  [RESET_PASSWORD_SUCCESS]: onResetPasswordSuccess,

  [CALL_PERSONAL_CLIENTS]: onCallPersonalClients,
  [PERSONAL_CLIENTS_FAILED]: onPersonalClientsFailed,
  [PERSONAL_CLIENTS_SUCCESS]: onPersonalClientsSuccess,

  [CALL_CLIENTS_PROFESSIONALS]: onCallClientsProfessionals,
  [CLIENTS_PROFESSIONALS_FAILED]: onClientsProfessionalsFailed,
  [CLIENTS_PROFESSIONALS_SUCCESS]: onClientsProfessionalsSuccess,

  [CALL_CLIENTS_PROFESSIONALS_FAVORITES]: onCallClientsProfessionalsFavorites,
  [CALL_CLIENTS_PROFESSIONALS_FAVORITES_FAILED]:
    onClientsProfessionalsFavoritesFailed,
  [CALL_CLIENTS_PROFESSIONALS_FAVORITES_SUCCESS]:
    onClientsProfessionalsFavoritesSuccess,

  [FETCH_CLIENT_APPOINTMENT]: onFetchClientAppointment,
  [CLIENT_APPOINTMENT_FAILED]: onClientAppointmentFailed,
  [CLIENT_APPOINTMENT_SUCCESS]: onClientAppointmentSuccess,

  [FETCH_PROFESSIONALS_APPOINTMENT]: onFetchProfessionalsAppointment,
  [PROFESSIONALS_APPOINTMENT_FAILED]: onProfessionalsAppointmentFailed,
  [PROFESSIONALS_APPOINTMENT_SUCCESS]: onProfessionalsAppointmentSuccess,

  [CALL_RATE]: onCallRate,
  [RATE_FAILED]: onRateFailed,
  [RATE_SUCCESS]: onRateSuccess,

  [CALL_CANCEL_CLASS]: onCancelClass,
  [CANCEL_CLASS_FAILED]: onCancelClassFailed,
  [CANCEL_CLASS_SUCCESS]: onCancelClassSuccess,

  [CALL_AVAILABILITY]: onCallAvailability,
  [AVAILABILITY_FAILED]: onAvailabilityFailed,
  [AVAILABILITY_SUCCESS]: onAvailabilitySuccess,

  [CALL_BOOK_CLASS]: onCallBookClass,
  [BOOK_CLASS_FAILED]: onBookClassFailed,
  [BOOK_CLASS_SUCCESS]: onBookClassSuccess,

  [CALL_ENABLE_PROFILE]: onCallEnableProfile,
  [ENABLE_PROFILE_FAILED]: onEnableProfileFailed,
  [ENABLE_PROFILE_SUCCESS]: onEnableProfileSuccess,

  [CALL_CONFIRM_CLASS]: onCallConfirmClass,
  [CONFIRM_CLASS_FAILED]: onConfirmClassFailed,
  [CONFIRM_CLASS_SUCCESS]: onConfirmClassSuccess,

  [CALL_REJECT_CLASS]: onCallRejectClass,
  [REJECT_CLASS_FAILED]: onRejectClassFailed,
  [REJECT_CLASS_SUCCESS]: onRejectClassSuccess,

  [CALL_FINISH_CLASS]: onCallFinishClass,
  [FINISH_CLASS_FAILED]: onCallFinishClassFailed,
  [FINISH_CLASS_SUCCESS]: onCallFinishClassSuccess,

  [CALL_EDIT_CLASS]: onCallEditClass,
  [EDIT_CLASS_FAILED]: onEditClassFailed,
  [EDIT_CLASS_SUCCESS]: onEditClassSuccess,
};

export const INITIAL_STATE = {
  loading: {
    personalTrainerRegistry: false,
    authenticateUser: false,
    category: false,
    professionalCount: false,
    notification: false,
    readNotification: false,
    sendEmail: false,
    createPost: false,
    deletePost: false,
    resetPassword: false,
    personalClients: false,
    clientsProfessionals: false,
    clientsProfessionalsFavorites: false,
    clientAppointment: false,
    professionalAppointment: false,
    rate: false,
    cancelClass: false,
    availability: false,
    bookCalss: false,
    enableProfile: false,
    confirmClass: false,
    rejectClass: false,
    editClass: false,
  },
  error: {
    personalTrainerRegistry: null,
    authenticateUser: null,
    category: null,
    professionalCount: null,
    notification: null,
    readNotification: null,
    sendEmail: null,
    createPost: null,
    deletePost: null,
    resetPassword: null,
    personalClients: null,
    clientsProfessionals: false,
    clientsProfessionalsFavorites: false,
    clientAppointment: null,
    professionalAppointment: null,
    rate: null,
    cancelClass: null,
    availability: null,
    bookCalss: null,
    enableProfile: null,
    confirmClass: null,
    rejectClass: null,
    editClass: null,
  },
  form: {
    register: {
      student: null,
      personal: null,
    },
  },
  profileType: null,
  category: [],
  professionalCount: null,
  notification: [],
  personalClients: [],
  clientsProfessionals: [],
  clientsProfessionalsFavorites: [],
  catalog: {
    data: [],
    filters: null,
  },
  appointments: {
    all: [],
    favorite: [],
  },
  availability: {},
  enableProfile: false,
};

export default [INITIAL_STATE, ACTION_HANDLERS];
