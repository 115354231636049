export const ADD_PERSONAL_EDUCATION = '@user/ADD_PERSONAL_EDUCATION';
export const REMOVE_PERSONAL_EDUCATION = '@user/REMOVE_PERSONAL_EDUCATION';

export const addPersonalEducation = (data) => ({
  type: ADD_PERSONAL_EDUCATION,
  data,
});

export const removePersonalEducation = (index) => ({
  type: REMOVE_PERSONAL_EDUCATION,
  index,
});

export const onAddPersonalEducation = (state, { data }) => ({
  ...state,
  infoPersonal: {
    ...state.infoPersonal,
    education: [...state.infoPersonal.education, ...data],
  },
});

export const onRemovePersonalEducation = (state, { index }) => ({
  ...state,
  infoPersonal: {
    ...state.infoPersonal,
    education: state.infoPersonal.education.filter((item, i) => i !== index),
  },
});
