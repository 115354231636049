export const CALL_CLIENTS_PROFESSIONALS = '@application/CALL_CLIENTS_PROFESSIONALS';
export const CLIENTS_PROFESSIONALS_FAILED = '@application/CLIENTS_PROFESSIONALS_FAILED';
export const CLIENTS_PROFESSIONALS_SUCCESS = '@application/CLIENTS_PROFESSIONALS_SUCCESS';

export const fetchClientsProfessionals = (userId) => ({
  type: CALL_CLIENTS_PROFESSIONALS,
  userId,
});

export const clientsProfessionalsFailed = (error) => ({
  type: CLIENTS_PROFESSIONALS_FAILED,
  error,
});

export const clientsProfessionalsSuccess = (data) => ({
  type: CLIENTS_PROFESSIONALS_SUCCESS,
  data,
});

export const onCallClientsProfessionals = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    clientsProfessionals: true,
  },
  errors: {
    ...state.errors,
    clientsProfessionals: null,
  },
  clientsProfessionals: [],
});

export const onClientsProfessionalsFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    clientsProfessionals: false,
  },
  errors: {
    ...state.errors,
    clientsProfessionals: error,
  },
  clientsProfessionals: [],
});

export const onClientsProfessionalsSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    clientsProfessionals: false,
  },
  errors: {
    ...state.errors,
    clientsProfessionals: null,
  },
  clientsProfessionals: data,
});
