import { parseGramsToKg } from './parseKgToGrams';

const convertClientAppointment = (data) => {
  if (!data) {
    return null;
  }

  return {
    key: data.id,
    price: data.price,
    classModality: data.class_modality,
    rate: data.rate,
    rateDescription: data.rate_description,
    status: data.status,
    startDate: data.start_date,
    endDate: data.end_date,
    type: data.type,
    client: {
      avatar: data.client.avatar,
      email: data.client.email,
      id: data.client.id,
      birthDate: data.client.birth_date,
      firstLogin: data.client.first_login,
      firstName: data.client.first_name,
      lastName: data.client.last_name,
      cpf: data.client.registration_number,
      phone: data.client.phone_number,
      restriction: data.client.restriction,
      cep: data.client.zip_code,
      weight: data.last_weight,
      bodyFat: data.last_body_fat,
      height: data.last_height,
      customerObjectives: data.objectives,
    },
    professional: {
      id: data.professional.id,
      avatar: data.professional.avatar,
      aboutClass: data.professional.about_my_class,
      firstName: data.professional.first_name,
      lastName: data.professional.last_name,
      nickname: data.professional.nickname,
      email: data.professional.email,
      averageRate: data.professional.average_rate,
      birthDate: data.professional.birth_date,
      bookingAvailable: data.professional.booking_available,
      classModalities: data.professional.class_modality,
      crefNumber: data.professional.cref_number,
      instagram: data.professional.instagram_user,
      isCrefValid: data.professional.is_cref_valid,
      mailingList: data.professional.mailing_list,
      phoneNumber: data.professional.phone_number,
      priceiInPerson: data.professional.price_in_person,
      priceOnline: data.professional.price_online,
      rateCount: data.professional.rate_count,
      targetMarket: data.professional.target_market,
      workLocations: data.professional.work_locations,
      paymentMethods: data.professional.payment_methods,
      skills: data.professional.skills,
    },
  };
};

export default convertClientAppointment;
