export const CALL_MEASURES = '@user/CALL_MEASURES';
export const MEASURES_FAILED = '@user/MEASURES_FAILED';
export const MEASURES_SUCCESS = '@user/MEASURES_SUCCESS';

export const callMeasures = ({ userId, weight, height, bodyFat }) => ({
  type: CALL_MEASURES,
  userId,
  weight,
  height,
  bodyFat,
});

export const measuresFailed = (error) => ({
  type: MEASURES_FAILED,
  error,
});

export const measuresSuccess = () => ({
  type: MEASURES_SUCCESS,
});

export const onCallMeasures = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    measures: true,
  },
  errors: {
    ...state.errors,
    measures: null,
  },
});

export const onMeasuresFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    measures: false,
  },
  errors: {
    ...state.errors,
    measures: error,
  },
});

export const onMeasuresSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    measures: false,
  },
  errors: {
    ...state.errors,
    measures: null,
  },
});
