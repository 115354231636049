import styled, { createGlobalStyle } from 'styled-components';
import { PRIMARY_COLOR } from '../../../defaults/Colors';

// import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
// import {
//   PRIMARY_COLOR,
//   WHITE_COLOR,
//   BACKGROUND_BODY,
// } from '../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'main__container',
})`
  &.main__container {
    width: 100%;
  }
`;

export const PostContainer = styled.div.attrs({
  className: 'post__container',
})`
  &.post__container {
    & .container-btn-create-post {
      display: flex;
      justify-content: end;
      margin-top: 24px;
    }
  }
`;

export const ContainerFooterModal = styled.div.attrs({
  className: 'container-button-footer',
})`
  &.container-button-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    margin-top: 32px;
  }
`;

export const ContainerPost = styled.div.attrs({
  className: 'container-post',
})`
  &.container-post {
    margin-top: 32px;

    & li {
      background: rgb(244 244 244);
      border-radius: 3px;
      padding: 30px 20px;

      & h4.ant-list-item-meta-title {
        color: ${PRIMARY_COLOR} !important;
      }
    }

    & .icon-post {
      align-items: center;
      color: ${PRIMARY_COLOR};
      display: flex;
      justify-content: center;
      background-color: #fff;
    }
  }
`;

export default {
  Container,
  PostContainer,
  ContainerFooterModal,
  ContainerPost,
};
