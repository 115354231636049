export const CALL_RESET_PASSWORD = '@application/CALL_RESET_PASSWORD';
export const RESET_PASSWORD_FAILED = '@application/RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_SUCCESS = '@application/RESET_PASSWORD_SUCCESS';

export const callResetPassword = ({
  oldPassword,
  newPassword,
  confirmNewPassword,
  userType,
  userId,
}) => ({
  type: CALL_RESET_PASSWORD,
  oldPassword,
  newPassword,
  confirmNewPassword,
  userType,
  userId,
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD_FAILED,
  error,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const onCallResetPassword = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    resetPassword: true,
  },
  errors: {
    ...state.errors,
    resetPassword: null,
  },
});

export const onResetPasswordFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    resetPassword: false,
  },
  errors: {
    ...state.errors,
    resetPassword: error,
  },
});

export const onResetPasswordSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    resetPassword: false,
  },
  errors: {
    ...state.errors,
    resetPassword: null,
  },
});
