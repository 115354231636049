import React from 'react';
import brazilianStates from './BrazilianStates';
import brazilianNeighborhoods from './BrazilianNeighborhoods';
import targetMarket from './TargetMarket';
import skillsPersonal from './SkillsPersonal';
import paymentMethod from './PaymentMethod';
import daysWeek from './DaysWeek';
import classModality from './ClassModality';

const dateFormat = 'DD/MM/YYYY';

export const INPUT_FIRST_NAME = {
  label: 'Nome',
  placeholder: 'Digite seu nome',
  name: 'firstName',
  isRequired: true,
};

export const INPUT_LAST_NAME = {
  label: 'Sobrenome',
  placeholder: 'Digite seu sobrenome',
  name: 'lastName',
  isRequired: true,
};

export const INPUT_EMAIL = {
  label: 'E-mail',
  placeholder: 'Digite seu e-mail',
  name: 'email',
  isRequired: true,
};

export const INPUT_BUSINESS_EMAIL = {
  label: 'E-mail de contato',
  placeholder: 'Digite seu e-mail de contato',
  name: 'contactEmail',
};

export const INPUT_CPF = {
  label: 'CPF',
  placeholder: 'Digite seu cpf',
  name: 'cpf',
  isRequired: true,
  mask: '000.000.000-00',
};

export const DATE_PICKER_BIRTH_DATE = {
  label: 'Data de nascimento',
  placeholder: 'Digite sua data de nascimento',
  name: 'birthDate',
  isRequired: true,
  mask: '00/00/0000',
  format: dateFormat,
};

export const INPUT_WEIGHT = {
  label: 'Peso (em kgs)',
  placeholder: 'Digite o peso',
  name: 'weight',
  isRequired: true,
  tooltip: {
    title:
      'Para adequar suas aulas, seu peso será compartilhado com seu profissional.',
  },
};

export const INPUT_HEIGHT = {
  name: 'height',
  isRequired: true,
  label: 'Altura (em centímetros)',
  placeholder: 'Digite a altura',
  tooltip: {
    title:
      'Para adequar suas aulas, sua altura será compartilhada com seu profissional.',
  },
};

export const TEXTAREA_RESTRICTION = {
  name: 'restriction',
  label: 'Possui alguma restrição para atividades físicas? Quais?',
  placeholder: 'Digite as restrições',
  tooltip: {
    title:
      'Para adequar suas aulas, as suas restrições serão compartilhadas com seu profissional.',
  },
};

export const INPUT_PASSWORD = {
  label: 'Senha',
  placeholder: 'Digite sua senha',
  name: 'password',
  type: 'password',
  isRequired: true,
};

export const INPUT_CONFIRM_PASSWORD = {
  label: 'Confirmar senha',
  placeholder: 'Digite sua confirmação de senha',
  name: 'passwordConfirmation',
  type: 'password',
  isRequired: true,
};

export const SELECT_MULTIPLE_CUSTOMER_OBJECTIVES = {
  label: 'Objetivos',
  name: 'customerObjectives',
  isRequired: false,
  mode: 'multiple',
  options: skillsPersonal,
  placeholder: 'Selecione os seus objetivos',
  allowClear: true,
};

export const INPUT_BODY_FAT = {
  name: 'bodyFat',
  label: 'Taxa de gordura corporal (em porcentagem)',
  tooltip: {
    title:
      'Para adequar suas aulas, sua taxa de gordura corporal será compartilhada com seu profissional.',
  },
};

export const INPUT_CREF = {
  tooltip: {
    title:
      'O número do CREF será utilizado para validação do perfil e será disponibilizado para todos os usuários.',
  },
  label: 'CREF',
  placeholder: 'Digite seu CREF',
  name: 'cref',
  isRequired: true,
  mask: '000000-a/aa',
};

export const INPUT_ZIP_CODE = {
  label: 'CEP',
  placeholder: 'Digite o CEP',
  name: 'zipCode',
  mask: '00000-000',
};

export const TEXTAREA_ABOUT_CLASS = {
  label: 'Sobre as aulas',
  name: 'aboutClass',
  placeholder: 'Escreva sobre as aulas ...',
};

export const INPUT_NICKNAME = {
  label: 'Nome de usuário',
  placeholder: 'Digite seu apelido',
  name: 'nickname',
  isRequired: true,
};

export const INPUT_NUMBER_PHONE = {
  label: 'Celular',
  placeholder: 'Digite seu número de celular',
  name: 'phone',
  isRequired: true,
  mask: '(00) 0 0000-0000',
};

export const INPUT_INSTAGRAM = {
  label: 'Instagram',
  placeholder: 'Digite seu instagram',
  name: 'instagram',
};

export const SELECT_SKILLS = {
  label: 'Tipos de aula',
  name: 'skills',
  isRequired: true,
  mode: 'multiple',
  options: skillsPersonal,
  placeholder: 'Selecione os tipos de aula',
  allowClear: true,
};

export const INPUT_STUDY_INSTITUTION = {
  name: 'institution',
  isRequired: true,
  label: 'Instituição de ensino',
  placeholder: 'Digite o nome da instituição de ensino ',
};

export const INPUT_STUDY_DEGREE = {
  name: 'degree',
  isRequired: true,
  label: 'Grau',
  placeholder: 'Digite o tipo grau',
};

export const INPUT_FIELD_OF_STUDY = {
  name: 'fieldOfStudy',
  isRequired: true,
  label: 'Área de estudo',
  placeholder: 'Digite a área de estudo',
};

export const DATE_PICKER_STUDY_START_DATE = {
  name: 'startDate',
  isRequired: true,
  label: 'Data de início',
  placeholder: 'Digite a data de ínicio',
};

export const DATE_PICKER_STUDY_END_DATE = {
  name: 'endDate',
  label: 'Data de término (ou prevista)',
  format: dateFormat,
  placeholder: 'Digite a data de termino',
};

export const INPUT_EXPERIENCES_ACTIVITIES = {
  name: 'activities',
  isRequired: true,
  label: 'Atividades',
  placeholder: 'Digite as atividades',
};

export const INPUT_EXPERIENCES_COMPANY = {
  name: 'company',
  isRequired: true,
  label: 'Nome da empresa',
  placeholder: 'Digite o nome da empresa',
};

export const INPUT_EXPERIENCES_POSITION = {
  name: 'position',
  isRequired: true,
  label: 'Posição',
  placeholder: 'Digite a posição',
};

export const DATE_PICKER_EXPERIENCES_START_DATE = {
  name: 'startDate',
  isRequired: true,
  allowClear: true,
  label: 'Data de início',
  format: dateFormat,
  placeholder: 'Digite a data de ínicio',
};

export const DATE_PICKER_EXPERIENCES_END_DATE = {
  name: 'endDate',
  allowClear: true,
  label: 'Data de término (ou prevista)',
  format: dateFormat,
  placeholder: 'Digite a data de termino',
};

export const SELECT_CLASS_MODALITY = {
  label: 'Modalidade',
  placeholder: 'Selecione a modalidade',
  name: 'targetMarket',
  mode: 'multiple',
  options: classModality,
  allowClear: true,
};

export const SELECT_WEEKDAY = {
  label: 'Dia da semana',
  placeholder: 'Selecione pelo dia da semana',
  name: 'weekday',
  mode: 'multiple',
  options: daysWeek,
  allowClear: true,
};

export const TEXTAREA_REASON = {
  label: 'Motivo',
  name: 'reason',
  placeholder: 'Escreva o motivo do cancelamento ...',
  isRequired: true,
  maxLength: 500,
  minLength: 10,
  showCount: true,
  autoSize: { minRows: 2, maxRows: 4 },
};

//

export const INPUT_ADDRESS = {
  label: 'Endereço',
  placeholder: 'Digite o endereço',
  name: 'address',
  isRequired: true,
};

export const INPUT_CITY = {
  label: 'Cidade',
  placeholder: 'Digite a cidade',
  name: 'city',
  isRequired: true,
};

export const SELECT_ADDRESS_STATE = {
  label: 'Estado',
  placeholder: 'Selecione o estado',
  name: 'addressState',
  isRequired: true,
  options: brazilianStates,
};

export const INPUT_NEIGHBORNHOOD = {
  label: 'Bairro',
  placeholder: 'Digite o bairro',
  name: 'neighborhood',
  isRequired: true,
};

export const INPUT_ADDRESS_COMPLEMENT = {
  label: 'Complemento',
  placeholder: 'Digite o complemento',
  name: 'complement',
};

export const INPUT_CLASS_PERSON_PRICE = {
  label: 'Digite o valor das aulas por hora (presencial)',
  name: 'classPersonPrice',
};

export const INPUT_CLASS_ONLINE_PRICE = {
  label: 'Digite o valor das aulas por hora (online)',
  name: 'classOnlinePrice',
};

export const SELECT_WORK_LOCATIONS = {
  label: 'Localidade de trabalho',
  name: 'workLocations',
  mode: 'multiple',
  options: brazilianNeighborhoods,
  placeholder: 'Selecione a localidade de trabalho',
  allowClear: true,
};

export const TEXTAREA_ABOUT_USER = {
  label: 'Sobre mim',
  name: 'aboutClass',
  placeholder: 'Escreva sobre você ...',
};

export const SWITCH_CLASS_TYPE_ONLINE = {
  label: 'Gostaria de dar aula online?',
  defaultChecked: false,
  name: 'classOnline',
};

export const SWITCH_CLASS_TYPE_PERSON = {
  label: 'Gostaria de dar aula presencial?',
  defaultChecked: false,
  name: 'classPerson',
};

export const SELECT_TARGET_MARKET = {
  label: 'Público alvo',
  placeholder: 'Selecione o público alvo',
  name: 'targetMarket',
  mode: 'multiple',
  options: targetMarket,
  allowClear: true,
};

export const SELECT_PAYMENT_METHOD = {
  label: 'Método de pagamento',
  placeholder: 'Selecione o método de pagamento',
  name: 'paymentMethods',
  mode: 'multiple',
  options: paymentMethod,
  allowClear: true,
  tip: 'Escolha como deseja receber o pagamento.',
};

export const RANGER_PICKER_WORK_SCHEDULES = {
  name: 'workSchedules',
  type: 'rangerPicker',
  allowClear: true,
  placeholder: ['Início', 'Fim'],
  label: 'Dias de trabalho',
};

export default {
  INPUT_FIRST_NAME,
  INPUT_LAST_NAME,
  INPUT_EMAIL,
  INPUT_CPF,
  DATE_PICKER_BIRTH_DATE,
  INPUT_CREF,
  INPUT_NICKNAME,
  INPUT_PASSWORD,
  INPUT_CONFIRM_PASSWORD,
  INPUT_ZIP_CODE,
  INPUT_ADDRESS,
  INPUT_CITY,
  SELECT_ADDRESS_STATE,
  INPUT_NEIGHBORNHOOD,
  INPUT_ADDRESS_COMPLEMENT,
  INPUT_CLASS_PERSON_PRICE,
  INPUT_CLASS_ONLINE_PRICE,
  SELECT_SKILLS,
  SELECT_WORK_LOCATIONS,
  TEXTAREA_ABOUT_USER,
  SWITCH_CLASS_TYPE_ONLINE,
  SWITCH_CLASS_TYPE_PERSON,
  SELECT_TARGET_MARKET,
  SELECT_PAYMENT_METHOD,
  RANGER_PICKER_WORK_SCHEDULES,
  INPUT_WEIGHT,
  INPUT_HEIGHT,
  INPUT_BODY_FAT,
  TEXTAREA_RESTRICTION,
  INPUT_STUDY_INSTITUTION,
  INPUT_STUDY_DEGREE,
  INPUT_FIELD_OF_STUDY,
  DATE_PICKER_STUDY_START_DATE,
  DATE_PICKER_STUDY_END_DATE,
  INPUT_EXPERIENCES_ACTIVITIES,
  INPUT_EXPERIENCES_COMPANY,
  INPUT_EXPERIENCES_POSITION,
  DATE_PICKER_EXPERIENCES_START_DATE,
  DATE_PICKER_EXPERIENCES_END_DATE,
  TEXTAREA_ABOUT_CLASS,
  INPUT_NUMBER_PHONE,
  INPUT_INSTAGRAM,
  SELECT_WEEKDAY,
};
