import React from 'react';

import Button from '../../../elements/Button';
import { useSelector } from '../../../utility/Context';

const CancelClass = ({ id, configModal }) => {
  const [loading] = useSelector(({ application }) => [
    application.loading.cancelClass,
  ]);

  return (
    <Button
      type="link"
      id={id}
      danger
      loading={loading}
      onClick={() => {
        configModal.setModalData({ id });
        configModal.showModal();
      }}
    >
      Cancelar
    </Button>
  );
};

export default CancelClass;
