export const CALL_HEIGHTS = '@user/CALL_HEIGHTS';
export const HEIGHTS_FAILED = '@user/HEIGHTS_FAILED;';
export const HEIGHTS_SUCCESS = '@user/HEIGHTS_SUCCESS';

export const callHeights = (userId) => ({
  type: CALL_HEIGHTS,
  userId,
});

export const heightsFailed = (error) => ({
  type: HEIGHTS_FAILED,
  error,
});

export const heightsSuccess = (data) => ({
  type: HEIGHTS_SUCCESS,
  data,
});

export const onCallHeights = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    heights: true,
  },
  errors: {
    ...state.errors,
    heights: null,
  },
});

export const onHeightsFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    heights: false,
  },
  errors: {
    ...state.errors,
    heights: error,
  },
});

export const onHeightsSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    heights: false,
  },
  errors: {
    ...state.errors,
    heights: null,
  },
  data: {
    ...state.data,
    heights: data,
  },
});
