import styled, { createGlobalStyle } from 'styled-components';
import { Form as FormAntd } from 'antd';

import { MOBILE_WIDTH } from '../../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../../defaults/Colors';

export const Title = styled.h3.attrs({
  className: 'sign-up__title',
})`
  &.sign-up__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const ContainerButtonFooter = styled.div.attrs({
  className: 'container-button-footer',
})`
  &.container-button-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export const ContainerHeader = styled.div.attrs({
  className: 'container-header',
})`
  &.container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
  }
`;

export const ContainerFields = styled.div.attrs({
  className: 'container-fields',
})`
  &.container-fields {
    & .alert {
      margin-bottom: 24px;
    }
  }
`;

export default {
  Title,
  ContainerButtonFooter,
  ContainerHeader,
  ContainerFields,
};
