export const CALL_CONFIRM_CLASS = '@application/CALL_CONFIRM_CLASS';
export const CONFIRM_CLASS_FAILED = '@application/CONFIRM_CLASS_FAILED';
export const CONFIRM_CLASS_SUCCESS = '@application/CONFIRM_CLASS_SUCCESS';

export const callConfirmClass = (appointmentId, userId) => ({
  type: CALL_CONFIRM_CLASS,
  appointmentId,
  userId,
});

export const confirmClassFailed = (error) => ({
  type: CONFIRM_CLASS_FAILED,
  error,
});

export const confirmClassSuccess = (data) => ({
  type: CONFIRM_CLASS_SUCCESS,
  data,
});

export const onCallConfirmClass = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    confirmClass: true,
  },
  errors: {
    ...state.errors,
    confirmClass: null,
  },
});

export const onConfirmClassFailed = (state, { error }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    confirmClass: false,
  },
  errors: {
    ...state.errors,
    confirmClass: error,
  },
});

export const onConfirmClassSuccess = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    confirmClass: false,
  },
  errors: {
    ...state.errors,
    confirmClass: null,
  },
});
