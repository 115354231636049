import React, { useCallback, useEffect } from 'react';
import { Spin, Empty } from 'antd';
import { useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from '../../utility/Context';

import { fetchCatalog } from '../../ducks/ApplicationDucks/FetchCatalog';

import CardPersonal from '../CardPersonal';

import Filters from './Filters';

import { Container, ContainerEmpty } from './styles';

const Catalog = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const callCatalog = useCallback(() => {
    const skills = queryParams.get('skills');
    const body = {
      skills: [skills],
    };

    dispatch(fetchCatalog(body));
  }, [dispatch]);

  useEffect(() => {
    callCatalog();
  }, []);

  const [catolog, loadingCatalog] = useSelector(({ application }) => [
    application.catalog.data,
    application.loading.catalog,
  ]);

  return (
    <Container>
      <Spin
        spinning={loadingCatalog}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        <Filters />

        {catolog?.length ? (
          <CardPersonal data={catolog} />
        ) : (
          <ContainerEmpty>
            <Empty
              image="/image/Empty-pana.svg"
              description={<span>Não encontramos nenhum profissional com os filtros selecionados :(.</span>}
            />
          </ContainerEmpty>
        )}
      </Spin>
    </Container>
  );
};

export default Catalog;
