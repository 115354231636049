import React, { useState } from 'react';
import { EditFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { USER_TYPE_CLIENT } from '../../defaults/userType';
import { PRIMARY_COLOR } from '../../defaults/Colors';
import { AvatarEdit, AvatarPreview, AvatarUploadWrapper } from './styles';

const UploadImage = ({ userId, defaultAvatar, userType }) => {
  const [avatar, setAvatar] = useState(null);
  const defaultProfileImage =
    defaultAvatar?.length > 0 ? defaultAvatar : '/image/avatar-placeholder.jpg';

  const submitAvatar = async (event) => {
    setAvatar(event.target.files[0]);
    const file = event.target.files[0];
    const token = localStorage.getItem('token');

    const avatarForm = new FormData();
    avatarForm.append('avatar', file);

    try {
      let endpoint = `/api/v1/professionals/${userId}/avatar`;

      if (userType === USER_TYPE_CLIENT) {
        endpoint = `/api/v1/clients/${userId}/avatar`;
      }

      await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Language': 'pt-BR',
        },
        body: avatarForm,
      });
    } catch (error) {
      console.error('Erro na chamada PATCH:', error);
    }
  };

  return (
    <>
      <AvatarUploadWrapper>
        <AvatarEdit className="avatar-edit">
          <input
            type="file"
            id="file-input"
            onChange={(e) => submitAvatar(e)}
            style={{ display: 'none' }}
            accept=".png, .jpg, .jpeg"
          />
          {/* eslint-disable-next-line */}
          <button
            onClick={() => document.getElementById('file-input')?.click()}
            type="button"
          >
            <EditFilled twoToneColor={PRIMARY_COLOR} />
          </button>
        </AvatarEdit>
        <AvatarPreview
          src={avatar ? URL.createObjectURL(avatar) : defaultProfileImage}
        />
      </AvatarUploadWrapper>
    </>
  );
};
export default UploadImage;
