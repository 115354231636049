import React, { useState } from 'react';
import { Tooltip } from 'antd';

import Button from '../../../elements/Button';

import Modal from './Modal';

import { RateTitle } from '../styles';

const RateClass = ({ id, professional }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title={<RateTitle>Fulano quer saber:</RateTitle>}>
        <Button type="link" onClick={showModal}>
          Avaliar
        </Button>
      </Tooltip>

      <Modal
        open={isModalOpen}
        handleCancel={handleCancel}
        professional={professional}
        id={id}
      />
    </>
  );
};

export default RateClass;
