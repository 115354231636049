import React, { useCallback } from 'react';
import { Form, Modal as ModalAntd, notification, Rate } from 'antd';

import Textarea from '../../../elements/Textarea';
import Button from '../../../elements/Button';

import { useDispatch, useSelector } from '../../../utility/Context';
import { getAuthLocalStorage } from '../../../utility/localStorage';

import { callRate } from '../../../ducks/ApplicationDucks/Rate';

import { ContainerButton, ContainerModal, ContentForm } from './styles';

const Modal = ({ open, handleCancel, professional, id }) => {
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const userId = getAuthLocalStorage()?.id;

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const dispatch = useDispatch();

  const rate = useCallback(
    (body) => {
      dispatch(callRate(id, body, userId));
    },
    [dispatch]
  );

  const handleReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    rate(values);
    handleReset();
    handleCancel();
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel enviar avaliação');
  };

  const [loading] = useSelector(({ application }) => [
    application.loading.rate,
  ]);

  return (
    <ContainerModal>
      {contextHolder}
      <ModalAntd
        title={`${
          professional?.firstName || 'O(a) profissional'
        } quer saber. O que achou da aula?`}
        footer={null}
        open={open}
        onCancel={() => {
          handleCancel();
          handleReset();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ContentForm>
            <Form.Item name="rate" label="O que achou da aula?" required>
              <Rate count={5} />
            </Form.Item>

            <Form.Item>
              <Textarea
                textareaConfig={{
                  name: 'description',
                  label: 'Deixar comentário',
                  placeholder: 'Nos conte o motivo da sua nota (opcional)',
                  maxLength: 500,
                  minLength: 10,
                  showCount: true,
                  autoSize: { minRows: 2, maxRows: 4 },
                }}
              />
            </Form.Item>
          </ContentForm>

          <ContainerButton>
            <Button
              key="back"
              onClick={() => {
                handleCancel();
                handleReset();
              }}
              disabled={loading}
            >
              Voltar
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Avaliar
            </Button>
          </ContainerButton>
        </Form>
      </ModalAntd>
    </ContainerModal>
  );
};

export default Modal;
