import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker as DatePickerAntd } from 'antd';

import { FormItem } from './styles';

const DatePicker = ({
  datePickerConfig,
  hasFeedback,
  rules,
  defaultValue,
  disabledDate,
}) => {
  const { placeholder, format, name, picker, ...rest } = datePickerConfig;

  return (
    <FormItem name={name} {...rest} hasFeedback={hasFeedback} rules={rules}>
      <DatePickerAntd
        placeholder={placeholder}
        format={format}
        disabledDate={disabledDate}
        defaultValue={defaultValue}
        picker={picker}
      />
    </FormItem>
  );
};

DatePicker.defaultProps = {
  hasFeedback: false,
};

DatePicker.propTypes = {
  datePickerConfig: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      marginBottom: PropTypes.string,
    }),
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default DatePicker;
