import React from 'react';
import { Divider, Avatar, Tooltip } from 'antd';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { FaInstagram } from 'react-icons/fa';
import { FaRegAddressCard, FaRegCalendar } from 'react-icons/fa6';
import { BiUser } from 'react-icons/bi';

import formatCurrency from '../../../utility/formatCurrency';

import Tags from '../SideBar/Tags';
import MoreInfo from '../SideBar/MoreInfo';
import EnableProfile from '../SideBar/EnableProfile';

import { Container } from './styles';

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

const Mobile = ({ userData, paramsUrl }) => {
  if (!userData) {
    return null;
  }

  const tags = [
    { title: 'Tipos de aula:', data: userData.skills, color: 'lime' },
    {
      title: 'Atende:',
      data: userData.targetMarket,
      color: 'geekblue',
    },
    {
      title: 'Modalidades:',
      data: userData.classModality?.map((item) => MODALITY[item]),
      color: 'orange',
    },
    {
      title: 'Localidade de trabalho:',
      data: userData.workLocations,
      color: 'purple',
    },
    {
      title: 'Formas de pagamento:',
      data: userData.paymentMethods,
      color: 'cyan',
    },
  ];

  const moreInfo = [
    { icon: <FaRegAddressCard />, label: userData.cref },
    { icon: <FaRegCalendar />, label: `${userData.age} anos`, onlyMe: true },
    {
      icon: <MdOutlineMarkEmailRead />,
      label: userData.contactEmail,
      hidden: Boolean(paramsUrl),
    },
    { icon: <FaInstagram />, label: userData.instagram },
  ];

  let currMoreInfo = moreInfo;

  if (paramsUrl) {
    currMoreInfo = moreInfo.filter((item) => !item.hidden);
  }

  const viewClients = moreInfo.filter((item) => !item.onlyMe);

  return (
    <Container>
      <div className="user-cards-mobile">
        <Avatar
          src={userData.avatar}
          icon={<BiUser />}
          alt="Avatar do profissional"
          shape="circle"
          size="large"
        />

        <div>
          <div className="fullname__mobile">
            <h3>
              {userData.firstName} {userData.lastName}
            </h3>
            {userData.isCrefValid ? (
              <Tooltip title="CREF verificado.">
                <AiFillCheckCircle className="valid-cref" />
              </Tooltip>
            ) : (
              <Tooltip title="CREF não verificado.">
                <AiFillCloseCircle className="invalid-cref" />
              </Tooltip>
            )}
          </div>

          <p className="nickname__mobile">{userData.nickname}</p>
        </div>
      </div>

      <Divider />

      <div className="info-user__mobile">
        {paramsUrl ? null : (
          <div className="enable-profile__mobile">
            <p>Gostaria de abrir a sua agenda?</p>
            <EnableProfile />
          </div>
        )}

        {paramsUrl
          ? viewClients.map(({ label, icon, onlyMe }, index) => (
              <MoreInfo key={index} label={label} icon={icon} />
            ))
          : currMoreInfo.map(({ label, icon, onlyMe }, index) => (
              <MoreInfo key={index} label={label} icon={icon} />
            ))}
      </div>

      <Divider />

      <div className="about-class__mobile">
        <h3>Sobre as aulas:</h3>
        <p>{userData.aboutClass}</p>

        {userData.classPersonPrice !== 0 ? (
          <>
            <h3>Preço da aula (presencial):</h3>
            <p>{formatCurrency(userData.classPersonPrice)}</p>
          </>
        ) : null}

        {userData.classOnlinePrice !== 0 ? (
          <>
            <h3>Preço da aula (online): </h3>
            <p>{formatCurrency(userData.classOnlinePrice)}</p>
          </>
        ) : null}

        <div className="tag__container__mobile">
          {tags?.map(({ title, data, color }, index) =>
            data?.length ? (
              <Tags key={index} title={title} data={data} color={color} />
            ) : null
          )}
        </div>
      </div>
    </Container>
  );
};

export default Mobile;
