import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'antd';

import { getAuthLocalStorage } from '../../../utility/localStorage';

import Availability from './Availability';
import Post from './Post';
import Qualifications from './Qualifications';
import Rates from './Rates';

import { Container } from './styles';

const Main = ({ userData, paramsUrl }) => {
  if (!userData) {
    return null;
  }

  const userId = getAuthLocalStorage()?.id;

  const items = [
    {
      key: '1',
      label: 'Agenda',
      children: <Availability userData={userData} />,
    },
    {
      key: '2',
      label: 'Postagens',
      children: <Post userId={userId} paramsUrl={paramsUrl} />,
    },
    {
      key: '3',
      label: 'Qualificações',
      children: <Qualifications userData={userData} />,
    },
    {
      key: '4',
      label: 'Avaliações',
      children: <Rates userData={userData} />,
    },
  ];

  return (
    <Container>
      <Tabs defaultActiveKey="1" items={items} />
    </Container>
  );
};

export default Main;
