import React from 'react';
import { Empty } from 'antd';

import { Container } from './styles';

const Catalog = ({ img, text }) => {
  return (
    <Container>
      <Empty
        image={img || '/image/Empty-pana.svg'}
        description={<span>{text || 'Dados não encontrados'}</span>}
      />
    </Container>
  );
};

export default Catalog;
