export const CALL_CREATE_POST = '@application/CALL_CREATE_POST';
export const CREATE_POST_FAILED = '@application/CREATE_POST_FAILED';
export const CREATE_POST_SUCCESS = '@application/CREATE_POST_SUCCESS';

export const callCreatePost = ({ userId, title, content }) => ({
  type: CALL_CREATE_POST,
  userId,
  title,
  content,
});

export const createPostFailed = (error) => ({
  type: CREATE_POST_FAILED,
  error,
});

export const createPostSuccess = (data) => ({
  type: CREATE_POST_SUCCESS,
  data,
});

export const onCallCreatePost = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    createPost: true,
  },
  errors: {
    ...state.errors,
    createPost: null,
  },
});

export const onCreatePostFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    createPost: false,
  },
  errors: {
    ...state.errors,
    createPost: error,
  },
});

export const onCreatePostSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    createPost: false,
  },
  errors: {
    ...state.errors,
    createPost: null,
  },
  data: {
    ...state.data,
    personal: data,
  },
});
