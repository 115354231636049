import React, { useCallback } from 'react';
import { Avatar, Card, notification, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Input from '../../../../elements/Input';
import InputNumber from '../../../../elements/InputNumber';
import Button from '../../../../elements/Button';

import {
  INPUT_WEIGHT,
  INPUT_HEIGHT,
  INPUT_BODY_FAT,
} from '../../../../defaults/RegisterUserFields';

import { callMeasures } from '../../../../ducks/UserDucks/Measures';

import { getRuleField } from '../../../../utility/RuleField';
import { parseKgToGrams } from '../../../../utility/parseKgToGrams';
import removeCommasAndDots from '../../../../utility/removeCommasAndDots';
import { useSelector, useDispatch } from '../../../../utility/Context';
import validateOnlyNumbers from '../../../../utility/validateOnlyNumbers';

import { Form, Title } from '../styles';

const Measures = ({ userId }) => {
  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const dispatch = useDispatch();

  const updateMeasures = useCallback(
    (weight, height, bodyFat) => {
      dispatch(
        callMeasures({
          userId,
          weight,
          height,
          bodyFat,
        })
      );
    },
    [dispatch]
  );

  const onFinish = ({ weight, height, bodyFat }) => {
    const currWeight = removeCommasAndDots(parseKgToGrams(weight));
    const convertIntWeight = parseInt(currWeight, 10);

    if (convertIntWeight < 30000) {
      message(
        'warning',
        'Atenção!',
        'O peso precisa ter um valor mínimo de 30 kgs.'
      );
      return;
    }

    const convertIntHeight = parseInt(height, 10);

    if (convertIntHeight < 100) {
      message(
        'warning',
        'Atenção!',
        'A altura precisa ter um valor mínimo de 100 centímetros.'
      );
      return;
    }

    updateMeasures(convertIntWeight, convertIntHeight, bodyFat);
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel salvar os dados');
  };

  const [userData, userDataLoading, measuresLoading] = useSelector(
    ({ user }) => [
      user.data.customer,
      user.loading.customerData,
      user.loading.measures,
    ]
  );

  if (!userData) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={userDataLoading}
      >
        <h3>Carregando...</h3>
      </Spin>
    );
  }

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          weight: userData.weight,
          height: userData.height,
          bodyFat: userData.bodyFat,
        }}
      >
        <Title>Minhas medidas</Title>

        <Divider />

        <Input
          inputConfig={INPUT_WEIGHT}
          rules={[
            getRuleField(INPUT_WEIGHT),
            { validator: validateOnlyNumbers },
          ]}
        />

        <Input
          inputConfig={INPUT_HEIGHT}
          rules={[
            getRuleField(INPUT_HEIGHT),
            { validator: validateOnlyNumbers },
          ]}
        />

        <InputNumber
          inputNumberConfig={{
            ...INPUT_BODY_FAT,
            min: 0,
            max: 100,
            formatter: (value) => `${value}%`,
            parser: (value) => value?.replace('%', ''),
            defaultValue: 0,
          }}
        />

        <Button
          type="primary"
          className="btn-save-data"
          htmlType="submit"
          loading={measuresLoading}
        >
          Salvar dados
        </Button>
      </Form>
    </>
  );
};

export default Measures;
