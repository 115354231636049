import styled from 'styled-components';
import { Form, InputNumber } from 'antd';

import { PRIMARY_COLOR } from '../../defaults/Colors';

export const FormItem = styled(Form.Item).attrs({
  className: 'form__item',
})`
  &.form__item {
    width: 100%;
  }
`;

export const InputNumberAntd = styled(InputNumber).attrs({
  className: 'input-number',
})`
  &.input-number {
    width: 100%;
  }
`;

export default { FormItem, InputNumberAntd };
