export const CALL_ENABLE_PROFILE = '@application/CALL_ENABLE_PROFILE';
export const ENABLE_PROFILE_FAILED = '@application/ENABLE_PROFILE_FAILED';
export const ENABLE_PROFILE_SUCCESS = '@application/ENABLE_PROFILE_SUCCESS';

export const callEnableProfile = ({ userId, checked, nickname }) => ({
  type: CALL_ENABLE_PROFILE,
  userId,
  checked,
  nickname,
});

export const enableProfileFailed = (error) => ({
  type: ENABLE_PROFILE_FAILED,
  error,
});

export const enableProfileSuccess = () => ({
  type: ENABLE_PROFILE_SUCCESS,
});

export const onCallEnableProfile = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    enableProfile: true,
  },
  errors: {
    ...state.errors,
    enableProfile: null,
  },
});

export const onEnableProfileFailed = (state, { error }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    enableProfile: false,
  },
  errors: {
    ...state.errors,
    enableProfile: error,
  },
});

export const onEnableProfileSuccess = (state, { enableProfile }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    enableProfile: false,
  },
  errors: {
    ...state.errors,
    enableProfile: null,
  },
  enableProfile,
});
