export const FETCH_PROFESSIONALS_APPOINTMENT =
  '@application/FETCH_PROFESSIONALS_APPOINTMENT';
export const PROFESSIONALS_APPOINTMENT_FAILED =
  '@application/PROFESSIONALS_APPOINTMENT_FAILED';
export const PROFESSIONALS_APPOINTMENT_SUCCESS =
  '@application/PROFESSIONALS_APPOINTMENT_SUCCESS';

export const fetchProfessionalsAppointment = (userId) => ({
  type: FETCH_PROFESSIONALS_APPOINTMENT,
  userId,
});

export const professionalsAppointmentFailed = (error) => ({
  type: PROFESSIONALS_APPOINTMENT_FAILED,
  error,
});

export const professionalsAppointmentSuccess = (data) => ({
  type: PROFESSIONALS_APPOINTMENT_SUCCESS,
  data,
});

export const onFetchProfessionalsAppointment = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    professionalAppointment: true,
  },
  errors: {
    ...state.errors,
    professionalAppointment: null,
  },
  appointments: {
    ...state.appointments,
    all: [],
  },
});

export const onProfessionalsAppointmentFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    professionalAppointment: false,
  },
  errors: {
    ...state.errors,
    professionalAppointment: error,
  },
});

export const onProfessionalsAppointmentSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    professionalAppointment: false,
  },
  errors: {
    ...state.errors,
    professionalAppointment: null,
  },
  appointments: {
    ...state.appointments,
    all: data,
  },
});
