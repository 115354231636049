import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';

export const Image = styled.img.attrs({
  className: 'image-logo',
})`
  &.image-logo {
    height: 5rem;
    width: 5rem;
    cursor: pointer;
  }
`;

export default {
  Image,
};
