import { getHeaders } from '../utility/getHeaders';

class UserAPI {
  static signUpClient = (data) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/clients`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static signUpPersonal = (data) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/professionals`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static login = (data) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/auths/signin`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            const authorizationHeader = res.headers.get('Authorization');
            resolve(authorizationHeader);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static resetPassword = (data) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/auths/forgot`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static getCustomerData = () =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/clients/me/profile`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static updateCustomerData = ({ data, userId }) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static addCustomerObjectives = ({ data, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/objectives`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static updateWeight = ({ weight, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/weights`,
        {
          method: 'POST',
          body: JSON.stringify(weight),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static updateHeight = ({ height, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/heights`,
        {
          method: 'POST',
          body: JSON.stringify(height),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static addBodyFat = ({ bodyFat, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/bodyfat`,
        {
          method: 'POST',
          body: JSON.stringify(bodyFat),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static getHeights = ({ userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/heights`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static fetchPersonalData = () =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/me/profile`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static updatePersonalData = ({ data, userId }) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then((error) => reject(error));
          }
        })
        .catch(reject);
    });

  static getWeights = ({ userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/weights`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static getBodyFat = ({ userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/bodyfat`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static fetchProfessionalNickname = ({ paramsUrl }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/public/${paramsUrl}`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
}

export default UserAPI;
