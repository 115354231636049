export const CALL_UPDATE_CUSTOMER_DATA = '@user/CALL_UPDATE_CUSTOMER_DATA';
export const UPDATE_CUSTOMER_DATA_FAILED = '@user/UPDATE_CUSTOMER_DATA_FAILED';
export const UPDATE_CUSTOMER_DATA_SUCCESS =
  '@user/UPDATE_CUSTOMER_DATA_SUCCESS';

export const callUpdateCustomerData = ({ data, userId }) => ({
  type: CALL_UPDATE_CUSTOMER_DATA,
  data,
  userId,
});

export const updateCustomerDataFailed = (error) => ({
  type: UPDATE_CUSTOMER_DATA_FAILED,
  error,
});

export const updateCustomerDataSuccess = () => ({
  type: UPDATE_CUSTOMER_DATA_SUCCESS,
});

export const onCallUpdateCustomerData = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    updateCustomerData: true,
  },
  errors: {
    ...state.errors,
    updateCustomerData: null,
  },
});

export const onUpdateCustomerDataFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    updateCustomerData: false,
  },
  errors: {
    ...state.errors,
    updateCustomerData: error,
  },
});

export const onUpdateCustomerDataSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    updateCustomerData: false,
  },
  errors: {
    ...state.errors,
    updateCustomerData: null,
  },
});
