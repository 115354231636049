import React from 'react';
import { Rate, Card, Tag, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

import { Container, ContainerDescription, AvatarStyled } from './styles';

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

const { Meta } = Card;

const CardPersonal = ({ data }) => {
  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(`/p/${url}`);
  };

  const drawTags = ({ tags, title = null, color = 'purple' }) => {
    if (!tags?.length) {
      return null;
    }

    return (
      <div className="tag-container">
        {title ? <p>{title}</p> : null}
        {tags.map((tag, index) => (
          <Tag key={index} color={color}>
            R$ {tag} /h
          </Tag>
        ))}
      </div>
    );
  };

  const drawRate = (rateCount, averageRate) => {
    if (!rateCount) {
      return <span>Nenhuma avaliação</span>;
    }

    return (
      <div className="rate-container">
        <Rate disabled defaultValue={1} count={1} />
        <span>{averageRate}</span>
        <span>({rateCount} avaliações)</span>
      </div>
    );
  };

  return (
    <Container>
      {data.map((personal, index) => (
        <Card
          key={index}
          hoverable
          style={{
            width: 250,
            height: 500,
          }}
          cover={<img alt="Foto do personal " src={personal.avatar} />}
          onClick={() => redirect(personal.nickname)}
        >
          <Meta
            title={
              <div className="title-card">
                {personal.firstName} {personal.lastName}{' '}
                {personal.isCrefValid ? (
                  <Tooltip title="CREF verificado.">
                    <AiFillCheckCircle className="valid-cref" />
                  </Tooltip>
                ) : (
                  <Tooltip title="CREF não verificado.">
                    <AiFillCloseCircle className="invalid-cref" />
                  </Tooltip>
                )}
              </div>
            }
            description={
              <ContainerDescription>
                <div className="rate-description">
                  {drawRate(personal.rateCount, personal.averageRate)}
                </div>
                <p className="text-description">
                  {personal.aboutClass.length > 50
                    ? `${personal.aboutClass.slice(0, 50)}...`
                    : personal.aboutClass}
                </p>
                {personal.classModality?.includes('online')
                  ? drawTags({
                      tags: [personal.classOnlinePrice],
                      title: 'Aula online:',
                      color: 'green',
                    })
                  : null}
                {personal.classModality?.includes('in_person')
                  ? drawTags({
                      tags: [personal.classPersonPrice],
                      title: 'Aula presencial:',
                      color: 'green',
                    })
                  : null}
              </ContainerDescription>
            }
          />
        </Card>
      ))}
    </Container>
  );
};

export default CardPersonal;
