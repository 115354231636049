import React from 'react';
import { Divider } from 'antd';

import Button from '../../elements/Button';

import { FooterStyled } from './styles';

const Footer = () => {
  const handleOpenNewTab = (url) => {
    window.open(url, '_blank');
  };

  const yearDate = new Date().getFullYear();

  return (
    <FooterStyled>
      <div className="footer__bloc1">
        <h4>Sobre</h4>
        <div>
          <Button
            type="link"
            onClick={() => handleOpenNewTab('/privacy-policy')}
          >
            Políticas de privacidade
          </Button>
          <Button type="link" onClick={() => handleOpenNewTab('/terms-of-use')}>
            Termos de uso
          </Button>
        </div>
      </div>

      <Divider className="footer__divider" />

      <p>© {yearDate} Personally.</p>
    </FooterStyled>
  );
};

export default Footer;
