import React from 'react';

import { Container } from './styles';

const TermsOfUse = () => {
  return (
    <Container>
      <h4>Termos e condições gerais de uso de aplicativo</h4>
      <p>
        Pelo presente estipulam-se os serviços de uso fornecidos pela empresa
        Personally.fit, neste ato representado por seus sócios proprietários, Sr
        Alessandro Cianbarelle, Felipe Oliveira, Gabryelle Silva e Pedro
        Barricelli. Titulares sabidos da propriedade intelectual e industrial
        sobre o software, sítio eletrônico, website, eventuais aplicativos e
        aplicações adjacentes, conteúdos e demais disposições relacionadas à
        propriamente dita plataforma Personally.
      </p>

      <h4>Do objeto descrito</h4>

      <p>
        O presente aplicativo e suas plataformas caracterizam-se pela prestação
        de serviço na modalidade B2B, à partir da aproximação de
        clientes/terceiros interessados sobre os serviços de personal trainer
        fornecidos por colaboradores e profissionais habilitados, reservando-se
        a especificidade e os objetivos persistentes. Por conseguinte, visa a
        plataforma licenciar o uso de seu conseguinte sítio e tangenciais,
        fornecendo ferramentas para o auxílio e dinamização. Ressalte-se que o
        aplicativo e suas produções não participam ativamente das vendas e
        produções ou serviços fornecidos pelos profissionais habilitados de
        maneira que estes responsabilizam-se pela execução das atividades,
        esmero do serviço, cobrança de valores e sua percepção, à aplicabilidade
        da lei vigente acerca da matéria.{' '}
      </p>

      <h4>Do aceite</h4>
      <p>
        Neste acordo, estamos definindo obrigações que todos concordam em
        aceitar de forma voluntária e por tempo indeterminado. Essas
        responsabilidades são válidas para a utilização da da plataforma
        Personally. Quando as pessoas usam a plataforma, seja como clientes em
        busca de profissionais, seja como profissionais qualificados, elas
        concordam completamente com as regras estabelecidas e se comprometem a
        segui-las. É importante ressaltar que o não cumprimento dessas normas
        pode acarretar em penalidades legais e administrativas, de acordo com a
        lei. O aceite, portanto, é imprescindível ao acesso e utilização dos
        serviços e produtividade promocional fornecidos pela empresa. No caso de
        DISCORDÂNCIA com as disposições, termos e condições deste instrumento, o
        usuário não deverá, de maneira veemente, utilizá-los.{' '}
      </p>

      <h4>Do acesso a plataforma</h4>

      <p>
        Para acesso e funcionalidade da mesma serão utilizadas todas as soluções
        técnicas disponíveis, permitindo-se a seus usuários e utilizadores o
        acesso a mesma e aos serviços ofertados 24/7. Entretanto, fique
        assertivamente ressaltado que a navegação no aplicativo ou em alguma de
        suas páginas poderá ser eventualmente interrompida, suspensa ou até
        mesmo limitada para que sejam promovidas as necessárias e cabíveis
        atualizações, modificações ou qualquer outra ação que seja necessária a
        seu contínuo bom funcionamento.{' '}
      </p>

      <h4>Do cadastro de usuários</h4>

      <p>
        O acesso a todas as funcionalidades da plataforma irá exigir, tanto aos
        profissionais habilitados/professores, quanto aos clientes à busca
        destes, cadastro prévio, ofertando-se ao aplicativo e a plataforma, por
        conseguinte, informações basais capazes de promover a individualização
        dos usuários ali configurados. A depender da modalidade promocional e
        dos serviços escolhidos pelos profissionais, será necessário além do
        cadastro prévio o pagamento de determinados valores, expressando seu
        quantum previamente com o cadastro e validação do seu número de CREF -
        Conselho Regional de Educação Física com ressalto de que a plataforma
        oferecerá assim o serviço gratuito sem cobrança prévia após a conclusão
        do cadastro na plataforma. Os pagamentos realizados pela prestação de
        serviço dos profissionais aos usuários/clientes destes será realizada,
        por absoluto, fora da plataforma, não existindo envolvimento da
        plataforma neste quesito, tampouco responsabilização consequente. A
        plataforma se compromete a não informar os dados cadastrais e/ou de
        acesso à terceiros, responsabilizando-se integralmente pelo uso que
        deles seja feito e reservada a sua confidencialidade. Menores de 18
        (dezoito) anos e aqueles que não possuírem plenitude à capacidade civil
        e seu exercício não poderão realizar cadastro na plataforma,
        restringindo-se a sua consequencial utilização. A realização do cadastro
        prévio declarará, pelo usuário, e garantirá expressamente a capacidade
        para exercer e usufruir livremente dos serviços ofertados. O usuário
        deve fornecer um endereço de e-mail válido e sempre atualizado, por meio
        do qual o aplicativo e sua plataforma enviarão todas as comunicações
        necessárias. Após a confirmação do cadastro, o usuário receberá um
        email, que garantirá a ele acesso individual. É responsabilidade
        exclusiva do usuário manter sua senha de forma confidencial e segura, a
        fim de evitar que terceiros acessem suas informações pessoais
        indevidamente. Qualquer atividade realizada na plataforma usando a senha
        pessoal, seja pelo próprio usuário ou por terceiros, é de
        responsabilidade do usuário, que deve comunicar prontamente à plataforma
        qualquer uso indevido da senha via os canais de suporte disponíveis.
        Lembramos que não é permitido, vender, alugar ou transferir de qualquer
        forma a conta pessoal para qualquer finalidade, pois cada conta é
        estritamente pessoal e intransferível. É responsabilidade do usuário
        garantir que seu equipamento seja compatível com as especificações
        técnicas necessárias para usar a plataforma e seus serviços e produtos.
        O usuário tem o direito de solicitar o cancelamento de sua conta na
        plataforma a qualquer momento. O processo de cancelamento será efetuado
        de forma rápida, desde que não haja pendências que impeçam a sua
        conclusão. Após o cancelamento da conta, esclarecemos que a
        responsabilidade pelo armazenamento dos dados pessoais permanece sob as
        diretrizes estabelecidas pelas políticas de privacidade. Os dados do
        usuário serão mantidos de forma segura e em conformidade com as
        regulamentações vigentes. É assegurado que, uma vez encerrada a conta,
        serão adotadas medidas adequadas para preservar a confidencialidade e a
        integridade dos dados armazenados. Qualquer retenção de informações
        seguirá os termos explicitados nas políticas de privacidade da
        plataforma, garantindo a proteção dos direitos individuais dos usuários.
        Caso haja necessidade ou solicitação por parte do usuário para a
        exclusão definitiva de seus dados após o cancelamento da conta, a
        plataforma se compromete a realizar tal procedimento de maneira oportuna
        e em conformidade com as disposições legais aplicáveis. O respeito à
        privacidade e a conformidade com as regulamentações de proteção de dados
        são prioridades fundamentais para garantir a transparência e a confiança
        dos usuários. O aceite aos termos de Termos e Condições Gerais de Uso de
        Aplicativo, pelo usuário, promove a autorização expressa para que a
        plataforma promova a coleta, uso, armazenamento, tratamento, cessão ou
        utilização de informações derivadas do uso dos serviços, do site e
        quaisquer plataformas, incluindo todas as informações preenchidas pelo
        usuário no momento em que realizar ou atualizar o seu cadastro, além de
        outras descritas e previamente aceitas na Política, que deverá ser
        autorizada pelo usuário.{' '}
      </p>

      <h4>Dos preços</h4>

      <p>
        A plataforma se reserva no direito de reajustar unilateralmente, a
        qualquer tempo, os valores dos serviços descritos e destinados aos
        profissionais habilitados, sem consulta ou anuência prévia do usuário.
        Os valores aplicados aos serviços utilizados pelos profissionais
        habilitados serão aqueles demonstrados no momento da aquisição. Os
        preços serão indicados em reais e incluem os produtos e promocionais ali
        configurados, reservada a sua especificação à parte e são informadas ao
        usuário/profissional habilitado antes da finalização do pedido. Na
        contratação dos serviços da plataforma, pelo profissional habilitado,
        esta poderá solicitar as informações financeiras do mesmo, como CPF,
        endereço de cobrança e dados de cartão. Ao inserir referidos dados o
        usuário concorda que sejam cobrados os valores postos, de acordo com a
        forma de pagamento que venha a ser escolhida, os preços vigentes e
        informados, finda a contratação. Referidos dados financeiros, à opção do
        usuário, poderão ser armazenados para facilitar acessos e contratações
        futuras. A contratação dos serviços será renovada AUTOMATICAMENTE pela
        plataforma, independentemente de prévia comunicação ao usuário
        contratante, mediante cobrança periódica da mesma forma de pagamento
        indicada, quando os serviços foram contratados. Portanto, para
        finalização dos serviços deverá ser operado o prévio CANCELAMENTO, na
        mesma plataforma de contratação.
      </p>

      <h4>Do cancelamento. </h4>

      <p>
        Poderá ser operado o cancelamento da contratação dos serviços de acordo
        com os termos apresentados quando de sua contratação em gênese. Com a
        aplicação do CDC, L8078/90, o usuário ainda poderá valer-se de
        cancelamento da contratação 7 (sete) dias após a sua contratação,
        mediante contato com a mesma. O cancelamento dos serviços poderá ser
        efetivado a partir do usuário quando, nessas condições, os serviços
        cessarão quando concluída a vigência do contratado. Os serviços serão
        cancelados pela plataforma pela VIOLAÇÃO DOS TERMOS DE USO, pelos
        usuários, promovendo a sua cessão imediata.{' '}
      </p>

      <h4>Do suporte</h4>

      <p>
        No caso de dúvidas, sugestões ou problemas no que tange a utilização da
        plataforma, deverá o usuário contatar o suporte através do endereço
        eletrônico suporte@personally.fit. Os serviços de atendimento ao usuário
        e colaboradores estarão disponíveis, via portal/aplicativo, de segunda à
        domingo, 24 horas por dia.{' '}
      </p>

      <h4>Da responsabilidade</h4>

      <p>
        Será de responsabilidade dos usuários, colaboradores e profissionais
        habilitados a correta utilização da plataforma e dos serviços ofertados,
        a boa convivência com terceiros, respeito para com demais usuários,
        cumprimento e respeito irrestrito ao conjunto de regras posto sobre
        Termo de Condições Gerais de Uso e sobre respectiva Política de
        Privacidade, bem como o que consta da legislação vigente aplicável à
        matéria, proteção de dados de acesso a conta pessoal e suas adjacências.
        A responsabilidade da plataforma, por sua vez, será pela boa, expressa e
        irrestrita indicação dos serviços prestados e eventuais produtos
        fornecidos, conteúdos ou atividades praticadas através da plataforma e
        suas tangenciais similares. Não há responsabilidade, pela plataforma, de
        comentários ou informações divulgados e fornecidas pelos usuários,
        tampouco responsabilização por links externos contidos no sistema com
        promoção de redirecionamento a ambientes externos.
      </p>

      <h4>Dos direitos autorais</h4>

      <p>
        O presente Termo de Uso concede à plataforma, e pela estrutura do
        site/aplicativo, marcas advindas deste, logo, nome comercial, layouts
        gráficos, designs, imagens, apresentações, conteúdos escritos, som
        áudio, arquivos de transmissão e qualquer outra informação e direitos de
        propriedade intelectual da razão social, observados os termos descritos
        legalmente, composto pela L9279/96, L9610/98, L9609/98. O Termo de Uso
        não cede ou transfere aos usuários qualquer direito, de maneira que o
        acesso deste não gerará qualquer direito consequente sobre propriedade
        intelectual ao mesmo. O uso da plataforma é pessoal, individual e
        intransferível, sendo vedada a sua utilização não autorizada para fins
        comerciais ou até não comerciais.{' '}
      </p>

      <h4>Das penalidades</h4>

      <p>
        Se um profissional ou usuário receber cinco denúncias na plataforma, a
        equipe da Personally vai tomar a decisão de bloquear temporariamente
        pelo o tempo que decorrer as investigações. Essa medida é adotada para
        possibilitar uma análise mais profunda dos incidentes relatados. Durante
        o período de bloqueio, a equipe que gerencia a plataforma vai analisar
        minuciosamente as denúncias, assegurando imparcialidade e rigor na
        apuração dos fatos. O objetivo é preservar um ambiente seguro e
        confiável para todos os usuários e profissionais que participam da
        plataforma. É importante destacar que a decisão de bloqueio temporário
        seguirá os termos e condições estabelecidos nas políticas da plataforma.
        A suspensão será aplicada caso seja identificada a necessidade de uma
        investigação mais detalhada diante das denúncias acumuladas. A
        plataforma está comprometida em assegurar a integridade e segurança de
        seus usuários, sendo essas medidas implementadas para manter um ambiente
        saudável e respeitoso para todos os envolvidos. Dispara-se medidas
        legalmente cabíveis ao sancionamento, poderá a plataforma, no caso de
        infração sobre os Termos de Uso, a qualquer tempo, suspender ou cancelar
        a conta do usuário.
      </p>

      <h4>Das alterações </h4>

      <p>
        Os itens descritos poderão sofrer alteração unilateral, a qualquer
        tempo, para alteração necessária ou modificação dos serviços, em prol do
        atendimento de melhor funcionalidade e adequação às exigências legais.
        Poderão ainda, os serviços promocionais fornecidos, a qualquer tempo e
        unilateralmente, sem mediante aviso prévio, terem sua concessão
        encerrada, alteradas as suas características ou ainda restrita a sua
        utilização/acesso.{' '}
      </p>

      <h4>Da política de privacidade</h4>

      <p>
        Concomitante a obediência sobre os Termos de Uso, o usuário deve
        consentir com o todo trazido sobre Política de Privacidade dada é
        apresentada em plataforma.{' '}
      </p>

      <h4>Do foro</h4>

      <p>
        Para a solução de eventuais problemáticas havidas e oriundas da
        utilização da plataforma, ou ainda controvérsias que decorram deste
        instrumento, será eleito o foro da comarca de São Paulo, com aplicação
        integral do direito brasileiro.
      </p>
    </Container>
  );
};

export default TermsOfUse;
