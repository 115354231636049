export const CALL_CUSTOMER_DATA = '@user/CALL_CUSTOMER_DATA';
export const CUSTOMER_DATA_FAILED = '@user/CUSTOMER_DATA_FAILED';
export const CUSTOMER_DATA_SUCCESS = '@user/CUSTOMER_DATA_SUCCESS';

export const callCustomerData = () => ({
  type: CALL_CUSTOMER_DATA,
});

export const customerDataFailed = (error) => ({
  type: CUSTOMER_DATA_FAILED,
  error,
});

export const customerDataSuccess = (data) => ({
  type: CUSTOMER_DATA_SUCCESS,
  data,
});

export const onCallCustomerData = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    customerData: true,
  },
  errors: {
    ...state.errors,
    customerData: null,
  },
});

export const onCallCustomerDataFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    customerData: false,
  },
  errors: {
    ...state.errors,
    customerData: error,
  },
});

export const onCallCustomerDataSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    customerData: false,
  },
  errors: {
    ...state.errors,
    customerData: null,
  },
  data: {
    ...state.data,
    customer: data,
  },
});
