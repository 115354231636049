import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../../defaults/Breakpoints';
import { PRIMARY_COLOR } from '../../../../defaults/Colors';

export const Container = styled.p.attrs({
  className: 'container-qualifications',
})`
  &.container-qualifications {
    margin-top: 24px;
  }
`;

export const ContainerTimeline = styled.div.attrs({
  className: 'container-qualifications__timeline',
})`
  &.container-qualifications__timeline {
    & span {
      font-weight: 700;
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'container-qualifications__text',
})`
  &.container-qualifications__text {
    color: ${PRIMARY_COLOR};
    font-size: 1.5rem;
    margin: 24px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export default { Container, ContainerTimeline, Title };
