import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { callUserInfo } from '../../ducks/UserDucks/UserInfo';

import { parseJWT } from '../../utility/parseJWT';
import { useDispatch, useSelector } from '../../utility/Context';
import { USER_TYPE_PROFESSIONAL } from '../../defaults/userType';

import Client from '../Client';
import Personal from '../Personal';

import { Container, ContainerSpin } from './styles';

const Home = () => {
  const [userType, setUserType] = useState(null);
  const token = localStorage.getItem('token');
  const parseJWTToken = parseJWT(token);

  const dispatch = useDispatch();

  const userInfo = useCallback(() => {
    localStorage.setItem('auth', JSON.stringify(parseJWTToken));
    dispatch(callUserInfo(parseJWTToken));
  }, [dispatch]);

  useEffect(() => {
    userInfo();
  }, []);

  const auth = useSelector(({ user }) => user.auth);

  useEffect(() => {
    setUserType(auth?.userType);
  }, [auth]);

  if (!userType) {
    return (
      <ContainerSpin>
        <Spin
          spinning
          indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
        />
      </ContainerSpin>
    );
  }

  return (
    <Container>
      {userType === USER_TYPE_PROFESSIONAL ? <Personal /> : <Client />}
    </Container>
  );
};

export default Home;
