import styled from 'styled-components';

export const ContainerButton = styled.div.attrs({
  className: 'container-btn',
})`
  &.container-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    margin-top: 50px;
  }
`;

export default {
  ContainerButton,
};
