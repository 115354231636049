import styled from 'styled-components';

import { PRIMARY_COLOR, WHITE_COLOR } from '../../defaults/Colors';
import { MOBILE_WIDTH } from '../../defaults/Breakpoints';

export const FooterStyled = styled.footer.attrs({
  className: 'footer',
})`
  &.footer {
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    padding: 50px 100px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media (max-width: ${MOBILE_WIDTH}px) {
      padding: 30px 40px;
    }

    & .footer__divider {
      background-color: ${WHITE_COLOR};
    }

    & .footer__bloc1 {
      text-align: start;

      & div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      }

      & button {
        color: #e8e8e8;
        font-weight: 500;
        font-size: 1rem;
        padding: 0;

        &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
          color: ${WHITE_COLOR};
          font-weight: 700;
        }
      }

      & li {
        margin-bottom: 12px;
      }

      & h4 {
        color: ${WHITE_COLOR};
        margin-bottom: 24px;
      }
    }
  }
`;

export default {
  FooterStyled,
};
