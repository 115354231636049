import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../defaults/Colors';

export const AvatarUploadWrapper = styled.div`
  position: relative;
  max-width: 205px;
`;

export const AvatarEdit = styled.div`
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;

  input {
    display: none;
  }

  input + button {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #f1f1f1;
      border-color: #d6d6d6;
    }

    /* &:after {
      content: "\f040";
      font-family: 'FontAwesome';
      color: #F6B21B;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto;
    } */
  }
`;

export const AvatarPreview = styled.img.attrs(({ src }) => ({
  src,
  alt: 'Imagem de perfil'
}))`
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #7928C8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
`;

export default {
  AvatarUploadWrapper,
  AvatarEdit,
  AvatarPreview,
};
