import React, { useCallback, useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import { useDispatch, useSelector } from '../../../utility/Context';
import { getAuthLocalStorage } from '../../../utility/localStorage';

import Empty from '../../Empty';

import ModalCancel from './ModalCancel';
import ModalReject from './ModalReject';
import tableSettings from './tableSettings';

import { Container, Title } from './styles';
import { fetchProfessionalsAppointment } from '../../../ducks/ApplicationDucks/FetchProfessionalsAppointments';

const Appointments = () => {
  const { isMobile, isTablet } = useWindowDimensions();
  const { id: userId, userType } = getAuthLocalStorage();

  const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);
  const [isOpenModalReject, setIsOpenModalReject] = useState(false);
  const [modalData, setModalData] = useState(null);

  const showModalCancel = () => {
    setIsOpenModalCancel(true);
  };

  const handleCancelModalCancel = () => {
    setIsOpenModalCancel(false);
  };

  const showModalReject = () => {
    setIsOpenModalReject(true);
  };

  const handleCancelModalReject = () => {
    setIsOpenModalReject(false);
  };

  const dispatch = useDispatch();

  const professionalAppointment = useCallback(() => {
    dispatch(fetchProfessionalsAppointment(userId));
  }, [dispatch]);

  useEffect(() => {
    professionalAppointment();
  }, []);

  const [appointments, loading] = useSelector(({ application }) => [
    application.appointments.all,
    application.loading.professionalAppointment,
  ]);

  if (!appointments) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <h3>Carregando...</h3>
      </Spin>
    );
  }

  const configModal = {
    isModalOpen: isOpenModalCancel,
    handleCancel: handleCancelModalCancel,
    showModalCancel: () => showModalCancel(),
    userId,
    userType,
    modalData,
    setModalData: (data) => setModalData(data),
    isOpenModalReject,
    showModalReject: (data) => showModalReject(data),
    handleCancelReject: (data) => handleCancelModalReject(data),
  };

  return (
    <Container>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        <div className="container-table">
          <Title>Meus agendamentos</Title>

          <Table
            columns={tableSettings(isMobile, configModal)}
            dataSource={appointments}
            locale={{
              emptyText: <Empty text="Não há agendamentos" />,
            }}
          />
        </div>

        <ModalCancel configModal={configModal} />
        <ModalReject configModal={configModal} />
      </Spin>
    </Container>
  );
};

export default Appointments;
