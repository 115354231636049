import React, { useCallback } from 'react';
import {
  Form,
  Modal as ModalAntd,
  notification,
  DatePicker as DatePickerAntd,
} from 'antd';
import dayjs from 'dayjs';

import Input from '../../../../../elements/Input';
import Button from '../../../../../elements/Button';

import { getRuleField } from '../../../../../utility/RuleField';
import { useDispatch } from '../../../../../utility/Context';

import { addPersonalEducation } from '../../../../../ducks/UserDucks/PersonalEducation';

import {
  INPUT_STUDY_INSTITUTION,
  INPUT_STUDY_DEGREE,
  INPUT_FIELD_OF_STUDY,
} from '../../../../../defaults/RegisterUserFields';

import { ContainerButton, ModalEducation } from './styles';

const monthFormat = 'YYYY/MM';

const Modal = ({ open, handleCancel }) => {
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const dispatch = useDispatch();

  const addEducation = useCallback(
    (body) => {
      dispatch(addPersonalEducation([body]));
    },
    [dispatch]
  );

  const handleReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    const { startDate, endDate, ...rest } = values;

    const currStartDate = dayjs(startDate, monthFormat).format('MM/YYYY');

    const currEndDate = endDate
      ? dayjs(endDate, monthFormat).format('MM/YYYY')
      : null;

    const body = {
      startDate: currStartDate,
      endDate: currEndDate,
      ...rest,
    };

    addEducation(body);
    message('success', 'Sucesso!', 'Formação acadêmica adicionada com sucesso');
    handleReset();
    handleCancel();
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel salvar os dados');
  };

  return (
    <ModalEducation>
      {contextHolder}
      <ModalAntd
        title="Adicionar formação acadêmica"
        footer={null}
        open={open}
        onCancel={() => {
          handleCancel();
          handleReset();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Input
            inputConfig={INPUT_STUDY_INSTITUTION}
            rules={[getRuleField(INPUT_STUDY_INSTITUTION)]}
          />
          <Input
            inputConfig={INPUT_STUDY_DEGREE}
            rules={[getRuleField(INPUT_STUDY_DEGREE)]}
          />
          <Input
            inputConfig={INPUT_FIELD_OF_STUDY}
            rules={[getRuleField(INPUT_FIELD_OF_STUDY)]}
          />

          <div className="container-date">
            <Form.Item
              name="startDate"
              label="Data de início"
              isRequired
              rules={[
                {
                  required: true,
                  message: `O campo ${'Data de início'.toLocaleLowerCase()} é obrigatório`,
                },
              ]}
            >
              <DatePickerAntd
                picker="month"
                placeholder="Digite a data de ínicio"
                format="MM/YYYY"
              />
            </Form.Item>

            <Form.Item name="endDate" label="Data de término (ou prevista)">
              <DatePickerAntd
                picker="month"
                placeholder="Digite a data de termino"
                format="MM/YYYY"
              />
            </Form.Item>
          </div>
          <ContainerButton>
            <Button
              key="back"
              onClick={() => {
                handleCancel();
                handleReset();
              }}
            >
              Voltar
            </Button>
            <Button key="submit" type="primary" htmlType="submit">
              Confirmar
            </Button>
          </ContainerButton>
        </Form>
      </ModalAntd>
    </ModalEducation>
  );
};

// Modal.defaultProps = {
//   editEducation: null,
// };

// Modal.propTypes = {
//   editEducation: PropTypes.shape({
//     institution: PropTypes.string,
//     degree: PropTypes.string,
//     fieldOfStudy: PropTypes.string,
//   }),
// };

export default Modal;
