export const PRIMARY_COLOR = '#7928C8';
export const LIGHT_RED_COLOR = '#F47467';
export const MEDIUM_RED_COLOR = '#DE4848';
export const WHITE_COLOR = '#FFFFFF';
export const WHITE_LIGHT_COLOR = '#F5F5F6';
export const BLUE_LIGHT = '#BCD2E8';
export const YELLOW_COLOR = '#F2A73A';
export const BLACK_COLOR = '#000000';
export const MEDIUM_GREEN_COLOR = '#65A556';

export const CATEGORY_COLOR_BLUE = '#3899FA';
export const CATEGORY_COLOR_GREEN = '#53A333';
export const CATEGORY_COLOR_ORANGE = '#EA9617';

export const BACKGROUND_BODY = '#F8F8F8';
export const SHADOW_COLOR = '#9b9b9b';
export const BORDER_COLOR = '#d9d9d9';

export default {
  PRIMARY_COLOR,
  LIGHT_RED_COLOR,
  MEDIUM_RED_COLOR,
  WHITE_COLOR,
  WHITE_LIGHT_COLOR,
  BLUE_LIGHT,
  YELLOW_COLOR,
  BLACK_COLOR,
  MEDIUM_GREEN_COLOR,
  CATEGORY_COLOR_BLUE,
  CATEGORY_COLOR_GREEN,
  CATEGORY_COLOR_ORANGE,
  BACKGROUND_BODY,
  SHADOW_COLOR,
  BORDER_COLOR,
};
