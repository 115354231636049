import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container-availability',
})`
  &.container-availability {
    background: #fff;
    padding: 10px 25px;
    border-radius: 30px;

    & .custom-calendar {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
    }

    & .custom-header-calendar {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .custom-main-calendar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px 0;
    }

    & .custom-carousel {
      width: 500px;
    }
  }
`;

export const ContainerButton = styled.div.attrs({
  className: 'container-btn',
})`
  &.container-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export const ContainerModal = styled.div.attrs({
  className: 'container-modal',
})`
  &.container-modal {
  }
`;

export const ContentForm = styled.div.attrs({
  className: 'content-form',
})`
  &.content-form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    & .ant-alert-info {
      margin-bottom: 24px;
    }
  }
`;

export default {
  Container,
  ContainerButton,
  ContainerModal,
  ContentForm,
};
