import styled, { createGlobalStyle } from 'styled-components';

import { PRIMARY_COLOR, WHITE_COLOR } from '../../defaults/Colors';
import { MOBILE_WIDTH } from '../../defaults/Breakpoints';

export const FooterStyled = styled.header.attrs({
  className: 'header',
})`
  &.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    margin-bottom: 40px;
    background-color: ${WHITE_COLOR};

    & .header-left {
      display: flex;
      align-items: center;
    }

    & .header__nav-bar-container {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    & .header__nav-bar {
      display: flex;
      align-items: center;
      gap: 24px;

      & button {
        font-weight: 600;
      }
    }

    & .header__container-access-btn {
      display: flex;
      gap: 12px;
    }

    & .dropdown__icon {
      color: ${PRIMARY_COLOR};
      font-size: 24px;
      cursor: pointer;

      @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 2rem;
        margin: 20px 0;
      }
    }

    & .container-notification {
      margin-right: 24px;
      cursor: pointer;
    }

    & .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #7928c8;
    }

    & .header__dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export const ContainerAvatar = styled.div.attrs({
  className: 'container-notification',
})`
  &.container-notification {
    & span.ant-avatar {
      align-items: center;
          display: flex;
          justify-content: center;
      }
    }
  }
`;

export const ContainerTitleNotification = styled.div.attrs({
  className: 'container-title-notification',
})`
  &.container-title-notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BodyGlobalStyle = createGlobalStyle`
  .ant-popover-inner {
    min-width: 350px;
    max-height: 450px;
    overflow-y: auto;
  }
`;

export default {
  FooterStyled,
  ContainerAvatar,
  ContainerTitleNotification,
};
