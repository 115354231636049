import React from 'react';
import PropTypes from 'prop-types';

import { ButtonStyled } from './styles';

const Button = ({
  icon,
  className,
  type,
  children,
  onClick,
  loading,
  htmlType,
  shape,
  danger,
  disabled,
  size,
}) => (
  <ButtonStyled
    type={type}
    icon={icon}
    htmlType={htmlType}
    className={className}
    onClick={onClick}
    loading={loading}
    shape={shape}
    danger={danger}
    disabled={disabled}
    size={size}
  >
    {children}
  </ButtonStyled>
);

Button.defaultProps = {
  onClick: () => {},
  loading: false,
  icon: null,
  children: null,
  className: null,
  danger: false,
  disabled: false,
};

Button.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
