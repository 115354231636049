import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'antd';

import { getAuthLocalStorage } from '../../../utility/localStorage';
import { useDispatch } from '../../../utility/Context';

import { callCustomerData } from '../../../ducks/UserDucks/FetchCustomerData';

import Profile from './Profile';
import FavoriteClasses from './FavoriteClasses';
import Measures from './Measures';
import Security from './Security';

import { Container, Wrapper } from './styles';

const AccountSettings = () => {
  const userId = getAuthLocalStorage()?.id;

  const dispatch = useDispatch();

  const customerData = useCallback(() => {
    dispatch(callCustomerData());
  }, [dispatch]);

  useEffect(() => {
    customerData();
  }, []);

  const items = [
    {
      key: '1',
      label: 'Perfil',
      children: (
        <Wrapper>
          <Profile userId={userId} />
        </Wrapper>
      ),
    },
    {
      key: '2',
      label: 'Atualizar medidas',
      children: (
        <Wrapper>
          <Measures userId={userId} />
        </Wrapper>
      ),
    },
    {
      key: '3',
      label: 'Profissionais favoritos',
      children: <FavoriteClasses />,
    },
    {
      key: '4',
      label: 'Senha',
      children: (
        <Wrapper>
          <Security userId={userId} />
        </Wrapper>
      ),
    },
  ];

  const onChange = (key) => {};

  return (
    <Container>
      <div className="account-settings__container">
        <main>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            centered
            size="large"
          />
        </main>
      </div>
    </Container>
  );
};

export default AccountSettings;
