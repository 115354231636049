import React, { useState, useCallback, useEffect } from 'react';
import { Collapse } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';

import { useDispatch, useSelector } from '../../../../../utility/Context';

import {
  addPersonalExperience,
  removePersonalExperience,
} from '../../../../../ducks/UserDucks/PersonalExperience';

import Button from '../../../../../elements/Button';

import Modal from './Modal';

import {
  Container,
  EducationExpand,
  EducationIconContainer,
  EducationTitle,
  EducationDescription,
} from './styles';

const Experience = ({ userData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [personalExperience] = useSelector(({ user }) => [
    user.infoPersonal.experience,
  ]);

  const dispatch = useDispatch();

  const removeEducation = useCallback(
    (index) => {
      dispatch(removePersonalExperience(index));
    },
    [dispatch]
  );

  const addExperience = useCallback(
    (body) => {
      dispatch(addPersonalExperience(body));
    },
    [dispatch]
  );

  useEffect(() => {
    const userDataEducations = userData?.experiences;

    if (userDataEducations?.length && !personalExperience.length) {
      addExperience(userDataEducations);
    }
  }, [userData]);

  const genExtra = (index) => (
    <EducationIconContainer>
      <Button
        type="link"
        icon={<AiOutlineClose />}
        onClick={(event) => {
          event.stopPropagation();
          removeEducation(index);
        }}
      />
    </EducationIconContainer>
  );

  const items = personalExperience?.map((experience, index) => ({
    key: index,
    label: experience?.company,
    children: (
      <EducationExpand>
        <div>
          <EducationTitle>Nome da empresa:</EducationTitle>
          <EducationDescription>{experience?.company}</EducationDescription>
        </div>
        <div>
          <EducationTitle>Atividades:</EducationTitle>
          <EducationDescription>{experience?.activities}</EducationDescription>
        </div>
        <div>
          <EducationTitle>Data de início:</EducationTitle>
          <EducationDescription>{experience?.startDate}</EducationDescription>
        </div>
        <div>
          <EducationTitle>Data de término:</EducationTitle>
          <EducationDescription>
            {experience?.endDate || 'Até o momento'}
          </EducationDescription>
        </div>
      </EducationExpand>
    ),
    extra: genExtra(index),
  }));

  return (
    <Container>
      <header>
        <h3>Experiência</h3>
        <Button
          type="default"
          className="btn-add-education"
          loading={false}
          onClick={showModal}
        >
          Adicionar
        </Button>
      </header>

      <Collapse defaultActiveKey={['0']} items={items} />

      <Modal open={isModalOpen} handleCancel={handleCancel} />
    </Container>
  );
};

export default Experience;
