import React from 'react';
import { useParams } from 'react-router-dom';

import Personal from '../Personal';

const ProfessionalPublic = () => {
  const { nickname } = useParams();

  return (
    <div>
      <Personal paramsUrl={nickname} />
    </div>
  );
};

export default ProfessionalPublic;
