export const CALL_REJECT_CLASS = '@application/CALL_REJECT_CLASS';
export const REJECT_CLASS_FAILED = '@application/REJECT_CLASS_FAILED';
export const REJECT_CLASS_SUCCESS = '@application/REJECT_CLASS_SUCCESS';

export const callRejectClass = (id, reason, userId) => ({
  type: CALL_REJECT_CLASS,
  id,
  reason,
  userId,
});

export const rejectClassFailed = (error) => ({
  type: REJECT_CLASS_FAILED,
  error,
});

export const rejectClassSuccess = () => ({
  type: REJECT_CLASS_SUCCESS,
});

export const onCallRejectClass = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    rejectClass: true,
  },
  errors: {
    ...state.errors,
    rejectClass: null,
  },
});

export const onRejectClassFailed = (state, { error }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    rejectClass: false,
  },
  errors: {
    ...state.errors,
    rejectClass: error,
  },
});

export const onRejectClassSuccess = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    rejectClass: false,
  },
  errors: {
    ...state.errors,
    rejectClass: null,
  },
});
