import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH, TABLET_WIDTH } from '../../../defaults/Breakpoints';

export const FilterContainer = styled.div.attrs({
  className: 'filter__container',
})`
  &.filter__container {
    display: flex;
    gap: 12px;
    margin: 0px 50px 50px 50px;

    & form.form-filters {
      display: flex;
      gap: 12px;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }

    & .btn-filter {
      display: flex;
      align-items: center;
      gap: 3px;
    }

    & .form-filters > .btn-search .ant-form-item {
      width: 450px;
      margin: 0;

      @media (max-width: ${TABLET_WIDTH}px) {
        width: 300px;
      }
    }
  }
`;

export const ContainerButtonFooter = styled.div.attrs({
  className: 'container-button-footer',
})`
  &.container-button-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
      align-items: end;
    }

    & button {
      align-items: center;
      display: flex;
    }
  }

  &.--is-modal {
    flex-direction: row;
  }
`;

export const GlobalStyle = createGlobalStyle`
  & .ant-popover-inner {
    width: 500px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      width: 200px;
    }
  }

  & .ant-popconfirm-message-text {
    width: 100%;
  }

  & .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .ant-col.ant-form-item-control {
    width: 100%;
  }

  & .ant-select-dropdown {
    z-index: 99999;
  }

  & .ant-popconfirm-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .popconfirm-btn-ok,
    & .popconfirm-btn-cancel {
      padding: 14px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
`;

export const ContainerPopconfirm = styled.div.attrs({
  className: 'container-popconfirm',
})`
  &.container-popconfirm {
    width: 100%;
    margin: 12px 0;
  }
`;

export default {
  GlobalStyle,
  FilterContainer,
  ContainerButtonFooter,
  ContainerPopconfirm,
};
