import React from 'react';
import PropTypes from 'prop-types';
import { Select as SelectAntd, Tooltip } from 'antd';
import { BsQuestionCircle } from 'react-icons/bs';
import { MdSearchOff } from 'react-icons/md';

import { FormItem, TooltipContainer, NotFound } from './styles';

const Select = ({ selectConfig, hasFeedback, rules }) => {
  const {
    placeholder,
    options,
    mode,
    allowClear,
    tip,
    label,
    showSearch,
    disabled,
    maxTagCount,
    size,
    ...rest
  } = selectConfig;

  const noContent = (
    <NotFound>
      <MdSearchOff />
      <p>Ops, não encontrado</p>
    </NotFound>
  );

  const formItemLabel = tip ? (
    <TooltipContainer>
      {label}
      <Tooltip title={tip}>
        <BsQuestionCircle />
      </Tooltip>
    </TooltipContainer>
  ) : (
    label
  );

  return (
    <FormItem
      {...rest}
      hasFeedback={hasFeedback}
      rules={rules}
      label={formItemLabel}
    >
      <SelectAntd
        placeholder={placeholder}
        options={options}
        mode={mode}
        allowClear={allowClear}
        notFoundContent={noContent}
        showSearch={showSearch}
        disabled={disabled}
        maxTagCount={maxTagCount}
        size={size}
      />
    </FormItem>
  );
};

Select.defaultProps = {
  hasFeedback: false,
};

Select.propTypes = {
  selectConfig: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.shape({
      marginBottom: PropTypes.string,
    }),
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default Select;
