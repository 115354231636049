export const CALL_UPDATE_PERSONAL_DATA = '@user/CALL_UPDATE_PERSONAL_DATA';
export const UPDATE_PERSONAL_DATA_FAILED = '@user/UPDATE_PERSONAL_DATA_FAILED';
export const UPDATE_PERSONAL_DATA_SUCCESS =
  '@user/UPDATE_PERSONAL_DATA_SUCCESS';

export const callUpdatePersonalData = ({ data, userId }) => ({
  type: CALL_UPDATE_PERSONAL_DATA,
  data,
  userId,
});

export const updatePersonalDataFailed = (error) => ({
  type: UPDATE_PERSONAL_DATA_FAILED,
  error,
});

export const updatePersonalDataSuccess = () => ({
  type: UPDATE_PERSONAL_DATA_SUCCESS,
});

export const onCallUpdatePersonalData = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    updatePersonalData: true,
  },
  errors: {
    ...state.errors,
    updatePersonalData: null,
  },
});

export const onUpdatePersonalDataFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    updatePersonalData: false,
  },
  errors: {
    ...state.errors,
    updatePersonalData: error,
  },
});

export const onUpdatePersonalDataSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    updatePersonalData: false,
  },
  errors: {
    ...state.errors,
    updatePersonalData: null,
  },
});
