// routes system
export const ROUTE_LOGIN = '/login';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_HOME = '/workspace';

// routes client
export const ROUTE_SETTINGS = 'settings';
export const ROUTE_DASHBOARD_MEASURES = 'dashboard/measures';
export const ROUTE_SEARCH = 'search';
export const ROUTE_APPOINTMENTS = 'appointments';
export const ROUTE_MY_PROFESSIONALS = 'my-professionals';


// routes private client
export const PRIVATE_ROUTE_SETTINGS = '/workspace/settings';
export const PRIVATE_ROUTE_DASHBOARD_MEASURES = '/workspace/dashboard/measures';
export const PRIVATE_ROUTE_SEARCH = '/workspace/search';
export const PRIVATE_ROUTE_APPOINTMENTS = '/workspace/appointments';
export const PRIVATE_ROUTE_MY_PROFESSIONALS = '/workspace/my-professionals';


// routes personal
export const ROUTE_PERSONAL_SETTINGS = 'personal/settings';
export const ROUTE_PERSONAL_MY_CLIENTS = 'personal/clients';
export const ROUTE_PERSONAL_APPOINTMENTS = 'personal/appointments';

// routes private personal
export const PRIVATE_ROUTE_PERSONAL_SETTINGS = '/workspace/personal/settings';
export const PRIVATE_ROUTE_MY_CLIENTS = '/workspace/personal/clients';
export const PRIVATE_ROUTE_PERSONAL_APPOINTMENTS =
  '/workspace/personal/appointments';

export default {
  ROUTE_LOGIN,
  ROUTE_RESET_PASSWORD,
  ROUTE_HOME,
  ROUTE_SETTINGS,
  ROUTE_DASHBOARD_MEASURES,
  ROUTE_SEARCH,
  ROUTE_APPOINTMENTS,
  ROUTE_MY_PROFESSIONALS,

  PRIVATE_ROUTE_DASHBOARD_MEASURES,
  PRIVATE_ROUTE_SETTINGS,
  PRIVATE_ROUTE_SEARCH,
  PRIVATE_ROUTE_APPOINTMENTS,
  PRIVATE_ROUTE_MY_PROFESSIONALS,

  ROUTE_PERSONAL_SETTINGS,
  ROUTE_PERSONAL_MY_CLIENTS,
  ROUTE_PERSONAL_APPOINTMENTS,

  PRIVATE_ROUTE_PERSONAL_SETTINGS,
  PRIVATE_ROUTE_MY_CLIENTS,
  PRIVATE_ROUTE_PERSONAL_APPOINTMENTS,
};
