import React, { useCallback, useEffect } from 'react';
import { Modal, Form, notification } from 'antd';

import Textarea from '../../../../../elements/Textarea';
import Input from '../../../../../elements/Input';
import Button from '../../../../../elements/Button';

import { useDispatch, useSelector } from '../../../../../utility/Context';

import { callCreatePost } from '../../../../../ducks/ApplicationDucks/CreatePost';

import { ContainerFooterModal } from '../../styles';

const ModalPost = ({ userId, isModalOpen, handleCancel }) => {
  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    handleReset();
  }, []);

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel salvar a publicação');
  };

  const dispatch = useDispatch();

  const createPost = useCallback(
    (title, content) => {
      dispatch(callCreatePost({ userId, title, content }));
    },
    [dispatch]
  );

  const onFinish = ({ titlePost, contentPost }) => {
    if (titlePost.length < 2) {
      message(
        'warning',
        'Atenção!',
        'Para criar uma publicação o título precisa ter no mínimo 2 caracteres'
      );
      return;
    }

    if (contentPost.length < 10) {
      message(
        'warning',
        'Atenção!',
        'Para criar uma publicação o conteúdo precisa ter no mínimo 10 caracteres'
      );
      return;
    }

    createPost(titlePost, contentPost);
    handleReset();
    handleCancel();
  };

  const [loading] = useSelector(({ application }) => [
    application.loading.createPost,
  ]);

  return (
    <>
      {contextHolder}

      <Modal
        title="Criar publicação"
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          handleCancel();
          handleReset();
        }}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Input
            inputConfig={{
              showCount: true,
              maxLength: 150,
              label: 'Título da publicação',
              placeholder: 'Digite o título da publicação',
              name: 'titlePost',
            }}
          />

          <Textarea
            textareaConfig={{
              name: 'contentPost',
              placeholder: 'O que está acontecendo?',
              autoSize: { minRows: 2, maxRows: 4 },
              className: 'post-field',
              maxLength: 5000,
              showCount: true,
            }}
          />

          <ContainerFooterModal>
            <Button
              key="back"
              disabled={loading}
              onClick={() => {
                handleCancel();
                handleReset();
              }}
            >
              Voltar
            </Button>
            <Button
              key="submit"
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Criar publicação
            </Button>
          </ContainerFooterModal>
        </Form>
      </Modal>
    </>
  );
};

export default ModalPost;
