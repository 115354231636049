import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Row } from 'antd';

import Button from '../../../elements/Button';

import { getAuthLocalStorage } from '../../../utility/localStorage';
import { useDispatch, useSelector } from '../../../utility/Context';
import { parseGramsToKg } from '../../../utility/parseKgToGrams';

import { callCustomerData } from '../../../ducks/UserDucks/FetchCustomerData';
import { callHeights } from '../../../ducks/UserDucks/Heights';
import { callWeights } from '../../../ducks/UserDucks/Weights';
import { callBodyFat } from '../../../ducks/UserDucks/BodyFat';

import { PRIMARY_COLOR } from '../../../defaults/Colors';

import { Title, ContainerHeader } from './styles';
import ModalMeasures from './ModalMeasures';

const Dashboards = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userId = getAuthLocalStorage()?.id;

  const [userData, weights, bodyFat] = useSelector(({ user }) => [
    user.data.customer,
    user.data.weights,
    user.data.bodyFat,
  ]);

  const dispatch = useDispatch();

  const customerData = useCallback(() => {
    dispatch(callCustomerData());
  }, [dispatch]);

  const fetchHeights = useCallback(() => {
    dispatch(callHeights(userId));
  }, [dispatch]);

  const fetchWeights = useCallback(() => {
    dispatch(callWeights(userId));
  }, [dispatch]);

  const fetchBodyFat = useCallback(() => {
    dispatch(callBodyFat(userId));
  }, [dispatch]);

  useEffect(() => {
    customerData();
    fetchHeights();
    fetchWeights();
    fetchBodyFat();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      <ContainerHeader>
        <Title>Minhas medidas</Title>
        <Button type="primary" onClick={showModal}>
          Atualizar medidas
        </Button>
      </ContainerHeader>
      <main style={{ padding: '0 40px' }}>
        <Row>
          <Col className="gutter-row" flex="auto">
            <Title>Peso (kg)</Title>
            <div>
              <Chart
                type="line"
                width="100%"
                height="300px"
                series={[
                  {
                    name: 'Peso',
                    data: weights.map(
                      (weight) => `${parseGramsToKg(weight.value)} kgs`
                    ),
                  },
                ]}
                options={{
                  chart: {
                    id: 'grafico-peso',
                  },
                  xaxis: {
                    categories: weights.map((weight) => weight.createdAt),
                  },
                  dataLabels: {
                    style: {
                      colors: ['#FFF'],
                    },
                  },
                  fill: {
                    colors: [PRIMARY_COLOR],
                  },
                  colors: [PRIMARY_COLOR],
                  markers: {
                    size: 6,
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" flex="auto">
            <Title>Taxa de gordura corporal (em porcentagem)</Title>
            <div>
              <Chart
                type="line"
                width="100%"
                height="300px"
                series={[
                  {
                    name: 'Taxa de gordura',
                    data: bodyFat.map((fat) => fat.value),
                  },
                ]}
                options={{
                  chart: {
                    id: 'grafico-gordura',
                  },
                  xaxis: {
                    categories: bodyFat.map((fat) => fat.createdAt),
                  },
                  dataLabels: {
                    style: {
                      colors: ['#FFF'],
                    },
                  },
                  colors: [PRIMARY_COLOR],
                  markers: {
                    size: 6,
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </main>

      {userData && isModalOpen && (
        <ModalMeasures
          userId={userId}
          userData={userData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default Dashboards;
