import React, { useCallback, useEffect } from 'react';
import { Statistic, Form, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { BsQuestionCircle } from 'react-icons/bs';

import Select from '../../elements/Select';
import Button from '../../elements/Button';

import { useDispatch, useSelector } from '../../utility/Context';
import { convertPersonalDataFilters } from '../../utility/convertPersonalData';

import { callCategory } from '../../ducks/ApplicationDucks/Category';
import { callProfessionalCount } from '../../ducks/ApplicationDucks/ProfessionalCount';
import { fetchCatalog } from '../../ducks/ApplicationDucks/FetchCatalog';

import {
  SELECT_SKILLS,
  SELECT_ADDRESS_STATE,
} from '../../defaults/LandingPageClientFields';

import {
  Title,
  Text,
  ContainerSearch,
  ContainerSearchPersonal,
  ContainerSearchImg,
  TextSpan,
} from './styles';

const SearchPersonal = () => {
  const [categories, categoriesLoading, professionalsCount] = useSelector(
    ({ user, application }) => [
      application.category,
      application.loading.category,
      application.professionalCount,
    ]
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const category = useCallback(() => {
    dispatch(callCategory());
  }, [dispatch]);

  const professionalCount = useCallback(() => {
    dispatch(callProfessionalCount());
  }, [dispatch]);

  const formatter = (value) => (
    <CountUp end={value} separator="" duration={2} />
  );

  useEffect(() => {
    professionalCount();
    category();
  }, []);

  const onFinish = (values) => {
    let currQuery = '';

    if (values) {
      const filteredFilters =
        values &&
        Object.fromEntries(
          Object.entries(values).filter(
            ([key, value]) =>
              value !== undefined &&
              value !== '' &&
              value !== null &&
              value.length
          )
        );

      const queryString = Object.entries(filteredFilters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      currQuery = `?${queryString}`;
    }

    redirect(`/search${currQuery}`);
  };

  return (
    <ContainerSearch>
      <ContainerSearchPersonal>
        <div>
          <div className="container-text">
            <div className="text-dinamic">
              <Title>Sua melhor aula a um clique.</Title>

              <TextSpan>
                <div className="text-dinamic-count">
                  Escolha entre nossos
                  <Statistic
                    value={professionalsCount}
                    formatter={formatter}
                  />{' '}
                </div>
                profissionais. Apenas disponível em São Paulo.
              </TextSpan>
            </div>
          </div>
        </div>

        <Form onFinish={onFinish}>
          <div className="search-personal-form">
            <div className="search-container">
              <Select
                selectConfig={{
                  ...SELECT_SKILLS,
                  mode: 'default',
                  options: categories,
                  disabled: categoriesLoading,
                  placeholder: 'O que gostaria de treinar hoje?',
                  size: 'large',
                }}
              />
            </div>

            <Button type="primary" htmlType="submit" size="large">
              Buscar
            </Button>
          </div>
        </Form>
      </ContainerSearchPersonal>

      <ContainerSearchImg>
        <img src="/image/Personal-Trainer-bro.svg" alt="Personal trainer" />
      </ContainerSearchImg>
    </ContainerSearch>
  );
};

export default SearchPersonal;
