import styled from 'styled-components';

import { PRIMARY_COLOR } from '../../../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'container-rate',
})`
  &.container-rate {
    & .icon-rate {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${PRIMARY_COLOR};
    }
  }
`;

export const Description = styled.div.attrs({
  className: 'rates__description',
})`
  &.rates__description {
    & > div {
      display: flex;
      gap: 6px;
      align-items: center;
      margin-bottom: 6px;
    }

    & .strong {
      font-weight: 700px;
    }
  }
`;

export default { Container, Description };
