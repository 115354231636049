import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Checkbox,
  Popover,
  Row,
  Col,
  Input as InputAntd,
  Switch,
} from 'antd';
import {
  AiOutlineSearch,
  AiOutlineClear,
  AiOutlineClose,
} from 'react-icons/ai';
import { BiFilterAlt } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import classModalityDefault from '../../../defaults/ClassModality';
import week from '../../../defaults/week';

import { fetchCatalog } from '../../../ducks/ApplicationDucks/FetchCatalog';
import { callCategory } from '../../../ducks/ApplicationDucks/Category';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import Select from '../../../elements/Select';
import InputNumber from '../../../elements/InputNumber';
import Button from '../../../elements/Button';

import {
  SELECT_TARGET_MARKET,
  SELECT_SKILLS,
  SELECT_PAYMENT_METHOD,
  SELECT_WEEKDAY,
  SELECT_WORK_LOCATIONS,
} from '../../../defaults/RegisterUserFields';

import { useDispatch, useSelector } from '../../../utility/Context';

import { GlobalStyle, ContainerButtonFooter, FilterContainer } from './styles';
import ModalFilter from './ModalFilter.js';

const MODALITY = {
  online: 'online',
  inPerson: 'in_person',
};

const WEEK = {
  Domingo: 'sunday',
  Segunda: 'monday',
  Terça: 'tuesday',
  Quarta: 'wednesday',
  Quinta: 'thursday',
  Sexta: 'friday',
  Sábado: 'saturday',
};

const Filters = () => {
  const [openModalFilter, setOpenModalFilter] = useState(false);

  const [form] = Form.useForm();

  const { isMobile } = useWindowDimensions();

  const showModal = () => {
    setOpenModalFilter(true);
  };

  const handleCancel = () => {
    setOpenModalFilter(false);
  };

  const [filters, categories] = useSelector(({ application }) => [
    application.catalog.filters,
    application.category,
  ]);

  const dispatch = useDispatch();

  const callCatalog = useCallback(
    (values) => {
      dispatch(fetchCatalog(values));
    },
    [dispatch]
  );

  const category = useCallback(() => {
    dispatch(callCategory());
  }, [dispatch]);

  useEffect(() => {
    category();
  }, []);

  const onFinish = (values) => {
    const { classModality, weekday, ...rest } = values;

    const currModality =
      classModality?.length && classModality?.map((item) => MODALITY[item]);

    const currWeekday = weekday?.length
      ? weekday?.map((item) => WEEK[item])
      : null;

    const body = {
      ...filters,
      ...rest,
      classModality: currModality,
      weekday: currWeekday,
    };

    callCatalog(body);
  };

  const onSearch = (value) => {
    onFinish({ personalName: value });
  };

  const handleReset = () => {
    form.resetFields();
  };

  const btnFooterForm = (size = 'default') => (
    <ContainerButtonFooter>
      <Button
        key="back"
        disabled={false}
        onClick={handleReset}
        size={size}
        icon={<AiOutlineClear />}
      >
        Limpar filtros
      </Button>
      <Button
        key="submit"
        loading={false}
        type="primary"
        htmlType="submit"
        size={size}
        icon={<BiFilterAlt />}
      >
        Buscar
      </Button>
    </ContainerButtonFooter>
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const skills = queryParams.get('skills') ?? undefined;

  const content = (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={{ skills }}
    >
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Select
            selectConfig={{
              ...SELECT_SKILLS,
              options: categories,
              placeholder: 'Selecione o tipo de aula',
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_WEEKDAY,
              placeholder: 'Selecione o dia da semana ',
              options: week,
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_WORK_LOCATIONS,
              placeholder: 'Selecione a localidade',
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_TARGET_MARKET,
              placeholder: 'Selecione o público alvo',
            }}
          />
        </Col>

        <Col span={12}>
          <InputNumber
            inputNumberConfig={{
              label: 'Valor máximo',
              name: 'maxPrice',
              placeholder: 'Digite o valor máximo',
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_PAYMENT_METHOD,
              tip: null,
              placeholder: 'Selecione o método de pagamento',
            }}
          />

          <Form.Item name="isCrefValid" label="Apenas CREFs verificados?">
            <Switch
              checkedChildren={<FaCheck />}
              unCheckedChildren={<AiOutlineClose />}
            />
          </Form.Item>

          <Form.Item name="classModality" label="Modalidade">
            <Checkbox.Group options={classModalityDefault} />
          </Form.Item>
        </Col>
      </Row>

      {btnFooterForm('small')}
    </Form>
  );

  return (
    <FilterContainer>
      <GlobalStyle />
      <Form
        form={form}
        className="form-filters"
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="btn-search">
          <Form.Item name="personalName">
            <InputAntd.Search
              placeholder="Pesquise pelo nome do profissional"
              enterButton="Pesquisar"
              size={isMobile ? 'default' : 'large'}
              suffix={<AiOutlineSearch />}
              onSearch={onSearch}
            />
          </Form.Item>
        </div>

        {isMobile ? (
          <Button
            className="btn-filter"
            size="default"
            icon={<MdKeyboardArrowDown />}
            onClick={showModal}
          >
            Filtros
          </Button>
        ) : (
          <div>
            <Popover
              trigger={['click']}
              title="Filtros"
              content={content}
              placement="bottomRight"
            >
              <Button
                className="btn-filter"
                size="large"
                icon={<MdKeyboardArrowDown />}
              >
                Filtros
              </Button>
            </Popover>
          </div>
        )}
      </Form>

      <ModalFilter open={openModalFilter} handleCancel={handleCancel} />
    </FilterContainer>
  );
};

export default Filters;
