import React, { useEffect, useState, useCallback } from 'react';
import { Avatar, List } from 'antd';
import { TbBook } from 'react-icons/tb';

import Button from '../../../../elements/Button';

import Empty from '../../../Empty';

import { useSelector, useDispatch } from '../../../../utility/Context';
import { callDeletePost } from '../../../../ducks/ApplicationDucks/DeletePost';

import ModalPost from './ModalPost';

import { PostContainer, ContainerPost } from '../styles';

const Post = ({ userId, paramsUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [posts, setPosts] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userData, loadingDeletePost] = useSelector(({ user, application }) => [
    user.data.personal,
    application.loading.deletePost,
  ]);

  useEffect(() => {
    setPosts(userData?.posts);
  }, [userData]);

  const dispatch = useDispatch();

  const handleDeletePost = useCallback(
    (postId) => {
      dispatch(callDeletePost({ postId, userId }));
    },
    [dispatch]
  );

  return (
    <PostContainer>
      {!paramsUrl ? (
        <div className="container-btn-create-post">
          <Button type="primary" onClick={showModal}>
            Criar publicação
          </Button>
        </div>
      ) : null}

      <ContainerPost>
        <List
          itemLayout="horizontal"
          dataSource={posts}
          locale={{
            emptyText: <Empty text="Nenhuma publicação por aqui..." />,
          }}
          renderItem={(post) => (
            <List.Item
              actions={
                !paramsUrl
                  ? [
                      <Button
                        key="delete-post"
                        type="link"
                        onClick={() => handleDeletePost(post.id)}
                        loading={loadingDeletePost}
                      >
                        Apagar
                      </Button>,
                    ]
                  : null
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    size="large"
                    icon={<TbBook />}
                    className="icon-post"
                  />
                }
                title={post.title}
                description={post.content}
              />
            </List.Item>
          )}
        />
      </ContainerPost>

      <ModalPost
        userId={userId}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
      />
    </PostContainer>
  );
};

export default Post;
