export const CALL_CLIENTS_PROFESSIONALS_FAVORITES = '@application/CALL_CLIENTS_PROFESSIONALS_FAVORITES';
export const CALL_CLIENTS_PROFESSIONALS_FAVORITES_FAILED = '@application/CALL_CLIENTS_PROFESSIONALS_FAVORITES_FAILED';
export const CALL_CLIENTS_PROFESSIONALS_FAVORITES_SUCCESS = '@application/CALL_CLIENTS_PROFESSIONALS_FAVORITES_SUCCESS';

export const fetchClientsProfessionalsFavorites = (userId) => ({
  type: CALL_CLIENTS_PROFESSIONALS_FAVORITES,
  userId,
});

export const clientsProfessionalsFavoritesFailed = (error) => ({
  type: CALL_CLIENTS_PROFESSIONALS_FAVORITES_FAILED,
  error,
});

export const clientsProfessionalsFavoritesSuccess = (data) => ({
  type: CALL_CLIENTS_PROFESSIONALS_FAVORITES_SUCCESS,
  data,
});

export const onCallClientsProfessionalsFavorites = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    clientsProfessionalsFavorites: true,
  },
  errors: {
    ...state.errors,
    clientsProfessionalsFavorites: null,
  },
  clientsProfessionalsFavorites: [],
});

export const onClientsProfessionalsFavoritesFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    clientsProfessionalsFavorites: false,
  },
  errors: {
    ...state.errors,
    clientsProfessionalsFavorites: error,
  },
  clientsProfessionalsFavorites: [],
});

export const onClientsProfessionalsFavoritesSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    clientsProfessionalsFavorites: false,
  },
  errors: {
    ...state.errors,
    clientsProfessionalsFavorites: null,
  },
  clientsProfessionalsFavorites: data,
});
