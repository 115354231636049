export const FETCH_CATALOG = '@application/FETCH_CATALOG';
export const CATALOG_FAILED = '@application/CATALOG_FAILED';
export const CATALOG_SUCCESS = '@application/CATALOG_SUCCESS';

export const fetchCatalog = (filters) => ({
  type: FETCH_CATALOG,
  filters,
});

export const catalogFailed = (error) => ({
  type: CATALOG_FAILED,
  error,
});

export const catalogSuccess = (data) => ({
  type: CATALOG_SUCCESS,
  data,
});

export const onFetchCatalog = (state, { filters }) => ({
  ...state,
  loading: {
    ...state.loading,
    catalog: true,
  },
  errors: {
    ...state.errors,
    catalog: null,
  },
  catalog: {
    ...state.catalog,
    filters: {
      ...state.catalog.filters,
      ...filters,
    },
  },
});

export const onCatalogFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    catalog: false,
  },
  errors: {
    ...state.errors,
    catalog: error,
  },
});

export const onCatalogSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    catalog: false,
  },
  errors: {
    ...state.errors,
    catalog: null,
  },
  catalog: {
    ...state.catalog,
    data,
  },
});
