import React, { useCallback } from 'react';
import { Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { INPUT_EMAIL } from '../../defaults/RegisterUserFields';
import { useDispatch, useSelector } from '../../utility/Context';

import { callResetPassword } from '../../ducks/UserDucks/ResetPassword';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import Input from '../../elements/Input';
import Button from '../../elements/Button';

import Logo from '../Logo';

import { Container, Header, ContainerButton } from './styles';

const ResetPassword = () => {
  const [api, contextHolder] = notification.useNotification();

  const loading = useSelector(({ user }) => user.loading.resetPassword);

  const { isMobile } = useWindowDimensions();

  const dispatch = useDispatch();

  const resetPassword = useCallback(
    (values) => dispatch(callResetPassword(values)),
    [dispatch]
  );

  const onFinish = (values) => {
    const { email } = values;
    if (!email) {
      api.warning(
        {
          message: 'Atenção',
          description: 'Credenciais inválidas',
        },
        1000
      );

      return;
    }

    resetPassword(values);
  };

  const onFinishFailed = (errors) => {
    api.warning(
      {
        message: 'Atenção',
        description: 'Credenciais inválidas',
      },
      1000
    );
  };

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  return (
    <div>
      {contextHolder}
      <Container>
        <img src="/image/Reset-password-amico.svg" alt="" />

        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="reset-password__form"
        >
          <Input inputConfig={INPUT_EMAIL} />
          <Button type="primary" htmlType="submit" loading={loading}>
            Recuperar senha
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default ResetPassword;
