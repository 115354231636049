const week = [
  {
    value: 'Domingo',
    name: 'sunday',
  },
  {
    value: 'Segunda',
    name: 'monday',
  },
  {
    value: 'Terça',
    name: 'tuesday',
  },
  {
    value: 'Quarta',
    name: 'wednesday',
  },
  {
    value: 'Quinta',
    name: 'thursday',
  },
  {
    value: 'Sexta',
    name: 'friday',
  },
  {
    value: 'Sábado',
    name: 'saturday',
  },
];

export default week;
