import React, { useCallback } from 'react';
import { Divider, notification } from 'antd';

import { getRuleField } from '../../../../utility/RuleField';
import { useDispatch, useSelector } from '../../../../utility/Context';

import Input from '../../../../elements/Input';
import Button from '../../../../elements/Button';

import {
  INPUT_CURRENT_PASSWORD,
  INPUT_NEW_PASSWORD,
  INPUT_CONFIRM_NEW_PASSWORD,
} from '../../../../defaults/ChangePasswordFields';
import passwordRegex from '../../../../defaults/passwordRegex';
import { USER_TYPE_PROFESSIONAL } from '../../../../defaults/userType';

import { callResetPassword } from '../../../../ducks/ApplicationDucks/ResetPassword';

import { Form, Title } from '../styles';

const Security = ({ userId }) => {
  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const [loading] = useSelector(({ application }) => [
    application.loading.resetPassword,
  ]);

  const dispatch = useDispatch();

  const resetPassword = useCallback(
    (body) => {
      dispatch(
        callResetPassword({ ...body, userType: USER_TYPE_PROFESSIONAL, userId })
      );
    },
    [dispatch]
  );

  const onFinish = (values) => {
    resetPassword(values);
  };

  const onFinishFailed = () => {
    message(
      'warning',
      'Atenção!',
      'Não foi possivel alterar senha. Preencha os campos corretamente'
    );
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Title>Segurança</Title>
        <Divider />

        <Input
          inputConfig={INPUT_CURRENT_PASSWORD}
          rules={[getRuleField(INPUT_CURRENT_PASSWORD)]}
        />

        <Input
          inputConfig={INPUT_NEW_PASSWORD}
          hasFeedback
          rules={[
            getRuleField(INPUT_NEW_PASSWORD),
            {
              validator(_, value) {
                if (passwordRegex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    'A senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número, 1 caractere especial e ter no mínimo 6 caracteres.'
                  )
                );
              },
            },
          ]}
        />

        <Input
          inputConfig={INPUT_CONFIRM_NEW_PASSWORD}
          hasFeedback
          rules={[
            getRuleField(INPUT_CONFIRM_NEW_PASSWORD),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('A confirmação de senha não confere.')
                );
              },
            }),
          ]}
        />

        <Button
          type="primary"
          className="btn-save-data"
          htmlType="submit"
          loading={loading}
        >
          Salvar dados
        </Button>
      </Form>
    </>
  );
};

export default Security;
