export const convertClientProfessionalData = (data) => {
  if (!data) {
    return null;
  }

  return {
    professionalId: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    nickname: data.nickname,
    avatar: data.avatar,
    crefNumber: data.cref_number,
    // isCrefValid: data.is_cref_valid,
    isFavorite: data.is_favorite,
    // instagram: data.instagram,
    paymentMethods: data.payment_methods || [],
    classModalities: data.class_modality || [],
    phone: data.phone_number,
    skills: data.skills || [],
    targetMarket: data.target_market || [],
    workLocations: data.work_locations || [],
    totalClasses: data.total_classes,
    aboutClass: data.about_my_class,
    averageRate: data.average_rate,
    rateCount: data.rate_count,
  };
};
