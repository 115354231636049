import React from 'react';

import Main from './Main';

import { BodyGlobalStyle } from './styles';

const LandingPageClient = () => {
  return (
    <>
      <BodyGlobalStyle />
      <Main />
    </>
  );
};

export default LandingPageClient;
