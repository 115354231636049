export const ADD_PROFILE_TYPE_REGISTER = '@user/ADD_PROFILE_TYPE_REGISTER';

export const addProfileTypeRegister = (profileType) => ({
  type: ADD_PROFILE_TYPE_REGISTER,
  profileType,
});

export const onAddProfileTypeRegister = (state, { profileType }) => ({
  ...state,
  profileType,
});
