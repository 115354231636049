export const CALL_REPORT = '@application/CALL_REPORT';
export const REPORT_FAILED = '@application/REPORT_FAILED';
export const REPORT_SUCCESS = '@application/REPORT_SUCCESS';

export const callReport = (userId, reason) => ({
  type: CALL_REPORT,
  userId,
  reason,
});

export const reportFailed = (error) => ({
  type: REPORT_FAILED,
  error,
});

export const reportSuccess = (data) => ({
  type: REPORT_SUCCESS,
  data,
});

export const onCallReport = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    report: true,
  },
  errors: {
    ...state.errors,
    report: null,
  },
  report: [],
});

export const onReportFailed = (state, { error }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    report: false,
  },
  errors: {
    ...state.errors,
    report: error,
  },
});

export const onReportSuccess = (state, { data }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    report: false,
  },
  errors: {
    ...state.errors,
    report: null,
  },
  report: data,
});
