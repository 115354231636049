import React, { useCallback } from 'react';
import { Modal as ModalAntd, Form, Checkbox, Switch } from 'antd';
import { FaCheck } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

import Select from '../../../../elements/Select';
import Button from '../../../../elements/Button';
import InputNumber from '../../../../elements/InputNumber';

import {
  SELECT_TARGET_MARKET,
  SELECT_SKILLS,
  SELECT_WORK_LOCATIONS,
  SELECT_PAYMENT_METHOD,
  SELECT_WEEKDAY,
} from '../../../../defaults/RegisterUserFields';
import week from '../../../../defaults/week';
import classModalityDefault from '../../../../defaults/ClassModality';

import { fetchCatalog } from '../../../../ducks/ApplicationDucks/FetchCatalog';

import useNotification from '../../../../hooks/useNotification';

import { useDispatch, useSelector } from '../../../../utility/Context';

import { ContainerButtonFooter } from '../styles';

const MODALITY = {
  online: 'online',
  inPerson: 'in_person',
};

const WEEK = {
  Domingo: 'sunday',
  Segunda: 'monday',
  Terça: 'tuesday',
  Quarta: 'wednesday',
  Quinta: 'thursday',
  Sexta: 'friday',
  Sábado: 'saturday',
};

const ModalFilter = ({ open, handleCancel }) => {
  const [categories, filters] = useSelector(({ application }) => [
    application.category,
    application.catalog.filters,
  ]);

  const { showNotification, contextHolder } = useNotification();

  const onFinishFailed = () => {
    showNotification('warning', 'Atenção', 'Não foi possivel filtrar');
  };

  const dispatch = useDispatch();

  const callCatalog = useCallback(
    (values) => {
      dispatch(fetchCatalog(values));
    },
    [dispatch]
  );

  const onFinish = (values) => {
    const { classModality, weekday, ...rest } = values;

    const currModality =
      classModality?.length && classModality?.map((item) => MODALITY[item]);

    const currWeekday = weekday?.length
      ? weekday?.map((item) => WEEK[item])
      : null;

    const body = {
      ...filters,
      ...rest,
      classModality: currModality,
      weekday: currWeekday,
    };

    callCatalog(body);
    handleCancel();
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const skills = queryParams.get('skills') ?? undefined;

  return (
    <>
      {contextHolder}

      <ModalAntd
        title="Minhas medidas"
        open={open}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ skills }}
        >
          <Select
            selectConfig={{
              ...SELECT_SKILLS,
              options: categories,
              placeholder: 'Selecione o tipo de aula',
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_WEEKDAY,
              placeholder: 'Selecione o dia da semana ',
              options: week,
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_WORK_LOCATIONS,
              placeholder: 'Selecione a localidade',
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_TARGET_MARKET,
              placeholder: 'Selecione o público alvo',
            }}
          />

          <InputNumber
            inputNumberConfig={{
              label: 'Valor máximo',
              name: 'maxPrice',
              placeholder: 'Digite o valor máximo',
            }}
          />

          <Select
            selectConfig={{
              ...SELECT_PAYMENT_METHOD,
              tip: null,
              placeholder: 'Selecione o método de pagamento',
            }}
          />

          <Form.Item name="isCrefValid" label="Apenas CREFs verificados?">
            <Switch
              checkedChildren={<FaCheck />}
              unCheckedChildren={<AiOutlineClose />}
            />
          </Form.Item>

          <Form.Item name="classModality" label="Modalidade">
            <Checkbox.Group options={classModalityDefault} />
          </Form.Item>

          <ContainerButtonFooter className="--is-modal">
            <Button key="back" disabled={false} onClick={handleCancel}>
              Voltar
            </Button>
            <Button key="submit" type="primary" htmlType="submit">
              Filtrar
            </Button>
          </ContainerButtonFooter>
        </Form>
      </ModalAntd>
    </>
  );
};

export default ModalFilter;
