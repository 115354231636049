import React, { useCallback } from 'react';
import { Modal, Form, notification } from 'antd';

import Textarea from '../../../../elements/Textarea';
import Button from '../../../../elements/Button';

import { TEXTAREA_REASON } from '../../../../defaults/RegisterUserFields';

import { getRuleField } from '../../../../utility/RuleField';
import { useDispatch, useSelector } from '../../../../utility/Context';

import { callRejectClass } from '../../../../ducks/ApplicationDucks/Reject';

import { ContainerForm, ContainerButtonFooter } from '../styles';

const ModalReject = ({ configModal }) => {
  const [loading] = useSelector(({ application }) => [
    application.loading.rejectClass,
  ]);

  const { isOpenModalReject, handleCancelReject, userId, modalData } =
    configModal;

  const dispatch = useDispatch();

  const rejectClass = useCallback(
    (reason) => {
      dispatch(callRejectClass(modalData?.id, reason, userId));
    },
    [dispatch, modalData]
  );

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel cancelar a aula');
  };

  const handleReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    const reason = values?.reason.trim();

    if (!reason) {
      message('warning', 'Atenção!', 'O campo motivo é obrigatório');
      handleReset();
      return;
    }

    rejectClass(reason);
    handleCancelReject();
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Motivo do rejeitar a aula"
        open={isOpenModalReject}
        footer={null}
        onCancel={() => {
          handleReset();
          handleCancelReject();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ContainerForm>
            <Textarea
              textareaConfig={TEXTAREA_REASON}
              rules={[getRuleField(TEXTAREA_REASON)]}
            />
          </ContainerForm>

          <ContainerButtonFooter>
            <Button
              key="back"
              disabled={loading}
              onClick={() => {
                handleReset();
                handleCancelReject();
              }}
            >
              Voltar
            </Button>
            <Button
              key="submit"
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Confirmar
            </Button>
          </ContainerButtonFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ModalReject;
