export const CALL_PERSONAL_CLIENTS = '@application/CALL_PERSONAL_CLIENTS';
export const PERSONAL_CLIENTS_FAILED = '@application/PERSONAL_CLIENTS_FAILED';
export const PERSONAL_CLIENTS_SUCCESS = '@application/PERSONAL_CLIENTS_SUCCESS';

export const callPersonalClients = (userId) => ({
  type: CALL_PERSONAL_CLIENTS,
  userId,
});

export const personalClientsFailed = (error) => ({
  type: PERSONAL_CLIENTS_FAILED,
  error,
});

export const personalClientsSuccess = (data) => ({
  type: PERSONAL_CLIENTS_SUCCESS,
  data,
});

export const onCallPersonalClients = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    personalClients: true,
  },
  errors: {
    ...state.errors,
    personalClients: null,
  },
  personalClients: [],
});

export const onPersonalClientsFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    personalClients: false,
  },
  errors: {
    ...state.errors,
    personalClients: error,
  },
  personalClients: [],
});

export const onPersonalClientsSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    personalClients: false,
  },
  errors: {
    ...state.errors,
    personalClients: null,
  },
  personalClients: data,
});
