import React, { useCallback, useEffect } from 'react';
import { Tag } from 'antd';

const MoreInfo = ({ label, icon }) => {
  if (!label) {
    return null;
  }

  return (
    <div className="more-info">
      {icon}
      <p>{label}</p>
    </div>
  );
};

export default MoreInfo;
