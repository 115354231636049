export const STATUS_CLASS = {
  pending_confirmation: {
    label: 'Pendente',
    color: 'gold',
    popover:
      'Este agendamento ainda está pendente de confimacação do profissional.',
  },
  confirmed: {
    label: 'Confirmada',
    color: 'geekblue',
    popover: 'Aula confirmada pelo profissional.',
  },
  canceled: {
    label: 'Cancelada',
    color: 'red',
    popover: 'Aula cancelada.',
  },
  rejected: {
    label: 'Rejeitada',
    color: 'volcano',
    popover: 'Aula rejeitada pelo profissional.',
  },
  finished: {
    label: 'Finalizada',
    color: 'green',
    popover: 'Aula finalizada.',
  },
};

export default STATUS_CLASS;
