import React from 'react';
import { TimePicker } from 'antd';

import { Container, ContainerSwitch } from './styles';
import { Form } from '../../styles';

const format = 'HH';

const WorkSchedules = () => {
  const weeks = [
    {
      label: 'Domingo',
      name: 'sunday',
    },
    {
      label: 'Segunda',
      name: 'monday',
    },
    {
      label: 'Terça',
      name: 'tuesday',
    },
    {
      label: 'Quarta',
      name: 'wednesday',
    },
    {
      label: 'Quinta',
      name: 'thursday',
    },
    {
      label: 'Sexta',
      name: 'friday',
    },
    {
      label: 'Sábado',
      name: 'saturday',
    },
  ];

  return (
    <Container>
      {weeks?.map((item, index) => {
        return (
          <ContainerSwitch key={index}>
            <p>{item.label}</p>

            <div className="time-picker-week">
              <Form.Item name={`${item.name}StartDate`}>
                <TimePicker format={format} placeholder="Início" />
              </Form.Item>

              <Form.Item name={`${item.name}EndDate`}>
                <TimePicker format={format} placeholder="Fim" />
              </Form.Item>
            </div>
          </ContainerSwitch>
        );
      })}
    </Container>
  );
};

export default WorkSchedules;
