import styled from 'styled-components';

import { PRIMARY_COLOR, WHITE_COLOR } from '../../defaults/Colors';
import { MOBILE_WIDTH } from '../../defaults/Breakpoints';

export const Container = styled.div.attrs({
  className: 'catalog__container',
})`
  &.catalog__container {
    & .container-table {
      padding: 0 40px;
    }
  }
`;

export const ContainerEmpty = styled.div.attrs({
  className: 'container-empty',
})`
  &.container-empty {
    margin: 24px 0;

    & .ant-empty-image {
      height: 350px;
    }

    & .ant-empty-description span {
      color: #6e6e6e;
      font-weight: 700;
      font-size: 1rem;
    }
  }
`;

export default {
  Container,
  ContainerEmpty,
};
