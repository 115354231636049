import React from 'react';
import { List, Avatar, Rate } from 'antd';

import Empty from '../../../Empty';

import { Container, Description } from './styles';

const Rates = ({ userData }) => {
  const { rates } = userData;

  return (
    <Container>
      <List
        itemLayout="horizontal"
        dataSource={rates}
        renderItem={({ clientName, description, rate }) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar size="large" className="icon-rate">
                  {clientName[0]}
                </Avatar>
              }
              title={<p href="https://ant.design">{clientName}</p>}
              description={
                <Description>
                  <div>
                    <p className="strong">{rate}</p>
                    <Rate defaultValue={rate} count={5} disabled />
                  </div>

                  <p>{description}</p>
                </Description>
              }
            />
          </List.Item>
        )}
        locale={{
          emptyText: <Empty text="Não há avaliações no momento..." />,
        }}
      />
    </Container>
  );
};

export default Rates;
