import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'antd';

import {
  getTokenLocalStorage,
  getAuthLocalStorage,
} from '../../../utility/localStorage';
import { useDispatch } from '../../../utility/Context';

import { callPersonalData } from '../../../ducks/UserDucks/FetchPersonalData';

import Footer from '../../Footer';
import Profile from './Profile';
// import FavoriteClasses from './FavoriteClasses';
// import Measures from './Measures';
import Security from './Security';

import { Container, Wrapper } from './styles';

const AccountSettings = () => {
  const userId = getAuthLocalStorage()?.id;

  const dispatch = useDispatch();

  const personalData = useCallback(() => {
    dispatch(callPersonalData());
  }, [dispatch]);

  useEffect(() => {
    personalData();
  }, []);

  const items = [
    {
      key: '1',
      label: 'Perfil',
      children: (
        <Wrapper>
          <Profile userId={userId} />
        </Wrapper>
      ),
    },
    // {
    //   key: '2',
    //   label: 'Medidas',
    //   children: (
    //     <Wrapper>
    //       <Measures userId={userId} />
    //     </Wrapper>
    //   ),
    // },
    // {
    //   key: '3',
    //   label: 'Favoritos',
    //   children: <FavoriteClasses />,
    // },
    {
      key: '2',
      label: 'Senha',
      children: (
        <Wrapper>
          <Security userId={userId} />
        </Wrapper>
      ),
    },
  ];

  return (
    <Container>
      <div className="account-settings__container">
        <main>
          <Tabs defaultActiveKey="1" items={items} centered size="large" />
        </main>
      </div>
    </Container>
  );
};

export default AccountSettings;
