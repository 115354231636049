export const convertPersonalDataBackend = (data) => {
  if (!data) {
    return null;
  }

  return {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    registration_number: data.cpf,
    cref_number: data.cref,
    birth_date: data.birthDate,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
  };
};

export const convertPersonalData = (data) => {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    aboutClass: data.about_my_class,
    age: data.age,
    avatar: data.avatar,
    averageRate: data.average_rate,
    birthDate: data.birth_date,
    bookingAvailable: data.booking_available,
    classModality: data.class_modality,
    cref: data.cref_number,
    cpf: data.registration_number,
    firstName: data.first_name,
    instagram: data.instagram_user,
    isCrefValid: data.is_cref_valid,
    lastName: data.last_name,
    nickname: data.nickname,
    paymentMethods: data.payment_methods || [],
    classPersonPrice: data.price_in_person,
    classOnlinePrice: data.price_online,
    email: data.email,
    contactEmail: data.contact_email,
    phone: data.phone_number,
    posts: data.posts,
    skills: data.skills || [],
    targetMarket: data.target_market || [],
    workLocations: data.work_locations || [],
    workSchedules: data.work_schedules,
    zipCode: data.zip_code,
    rateCount: data.rate_count,
    rates: data.rates?.map((rate) => ({
      clientName: rate.client_name,
      description: rate.description,
      rate: rate.rate,
    })),
    educations: data.educations?.map(
      (education) =>
        ({
          institution: education.institution,
          fieldOfStudy: education.field_of_study,
          degree: education.degree,
          startDate: education.start_date,
          endDate: education.end_date,
        } || [])
    ),
    experiences: data.experiences?.map(
      (experience) =>
        ({
          company: experience.company,
          activities: experience.activities,
          position: experience.position,
          startDate: experience.start_date,
          endDate: experience.end_date,
        } || [])
    ),
  };
};

export const convertPersonalClientsData = (data) => {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    key: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    birthDate: data.birth_date,
    age: data.age,
    avatar: data.avatar,
    restriction: data.restriction,
    lastWeight: data.last_weight,
    lastBodyFat: data.last_body_fat,
    lastHeight: data.last_height,
    objectives: data.objectives,
    totalClasses: data.total_classes,
    nickname: data.nickname,
    phoneNumber: data.phone_number,
  };
};

export const convertPersonalDataBackendEditProfile = (data) => {
  if (!data) {
    return null;
  }

  return {
    first_name: data.firstName,
    last_name: data.lastName,
    nickname: data.nickname,
    phone_number: `${data.phone}`,
    registration_number: data.cpf,
    birth_date: data.birthDate,
    zip_code: data.zipCode,
    instagram_user: data.instagram,
    cref_number: data.cref,
    educations: data.educations?.map((education) => ({
      institution: education.institution,
      field_of_study: education.fieldOfStudy,
      degree: education.degree,
      start_date: education.startDate,
      end_date: education.endDate,
    })),
    experiences: data.experiences?.map(({ endDate, startDate, ...rest }) => ({
      start_date: startDate,
      end_date: endDate,
      ...rest,
    })),
    about_my_class: data.aboutClass,
    skills: data.skills,
    target_market: data.targetMarket,
    work_locations: data.workLocations,
    payment_methods: data.paymentMethods,
    price_in_person: data.classPersonPrice,
    price_online: data.classOnlinePrice,
    work_schedules: data.workSchedules,
    contact_email: data.contactEmail
  };
};

export const convertPersonalDataFilters = (data) => {
  if (!data) {
    return null;
  }

  return {
    class_modality: data.classModality,
    objective: data.skills,
    payment_methods: data.paymentMethods,
    target_market: data.targetMarket,
    weekday: data.weekday,
    max_price: data.maxPrice,
    work_location: data.workLocations,
    identifier: data.personalName,
    cref_valid: data.isCrefValid,
  };
};

export default {
  convertPersonalDataBackend,
  convertPersonalData,
  convertPersonalDataBackendEditProfile,
  convertPersonalDataFilters,
};
