import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../defaults/Colors';

export const BodyGlobalStyle = createGlobalStyle`
  body {
    background: ${BACKGROUND_BODY};
  }
`;

export const LabelDropdown = styled.header.attrs({
  className: 'label-dropdown',
})`
  &.label-dropdown {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${PRIMARY_COLOR};
    font-size: 16px;
    font-weight: bold;

    & svg {
      transition: transform 0.3s ease-in-out;

      :hover {
        transform: rotate(180deg);
      }
    }
  }
`;

export const MainStyled = styled.main.attrs({
  className: 'landing-page-client__main',
})`
  &.landing-page-client__main {
    margin: 0 40px;

    & .container-options {
      background: ${BACKGROUND_BODY};
      gap: 24px;
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      margin: 0 auto;
      max-width: 1300px;
    }

    & .container-area {
      padding: 24px 50px;
      justify-content: center;
      background: ${BACKGROUND_BODY};
      display: flex;
      flex-direction: row-reverse;
    }

    & .container-block {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 12px;
      background: #7a28c8;
      border-radius: 20px 0px 20px 0px;
      width: max-content;
      padding: 24px;
      color: ${WHITE_COLOR};

      & h2 {
        color: ${WHITE_COLOR};
      }
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'landing-page-client__title',
})`
  &.landing-page-client__title {
    color: ${PRIMARY_COLOR};
    font-size: 40px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const Text = styled.p.attrs({
  className: 'landing-page-client__text',
})`
  &.landing-page-client__text {
    text-wrap: wrap;
    text-align: start;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }
`;

export const ContainerSearch = styled.div.attrs({
  className: 'landing-page-client__container-search',
})`
  &.landing-page-client__container-search {
    display: flex;
    justify-content: space-around;
    background: ${WHITE_COLOR};
    align-items: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
      padding: 0 24px;
    }

    & img {
      width: 400px;
      height: 400px;

      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 300px;
        height: 300px;
      }
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
      gap: 24px;
    }
  }
`;

export const ContainerSearchPersonal = styled.div.attrs({
  className: 'landing-page-client__container-search-personal',
})`
  &.landing-page-client__container-search-personal {
    & .search-personal-form {
      display: flex;
      gap: 12px;
      margin-top: 24px;

      @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
      }
    }
  }
`;

export const ContainerSearchImg = styled.div.attrs({
  className: 'landing-page-client__container-search-img',
})`
  &.landing-page-client__container-search-img {
  }
`;

export const ContainerOption = styled.div.attrs({
  className: 'option-container',
})`
  &.option-container {
    align-items: flex-start;
    display: flex;
    padding: 24px;
    background: ${BACKGROUND_BODY};

    @media (max-width: ${MOBILE_WIDTH}px) {
      text-align: start;
    }

    ${({ direct }) =>
      direct &&
      `
        justify-content: ${direct};
    `}

    & div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
      margin: 24px;
      max-width: 250px;
      text-align: justify;
    }

    & img {
      width: 300px;
      height: 300px;

      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 150px;
        height: 150px;
      }
    }

    & p {
      color: #7a7a7a;
    }
  }
`;

export const Footer = styled.footer.attrs({
  className: 'landing-page-client__footer',
})`
  &.landing-page-client__footer {
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    padding: 50px 100px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media (max-width: ${MOBILE_WIDTH}px) {
      padding: 30px 40px;
    }

    & .footer__divider {
      background-color: ${WHITE_COLOR};
    }

    & .footer__bloc1 {
      text-align: start;

      & ul {
        list-style-type: none;
      }

      & li {
        margin-bottom: 12px;
      }

      & h4 {
        color: ${WHITE_COLOR};
        margin-bottom: 24px;
      }
    }
  }
`;

export default {
  BodyGlobalStyle,
  LabelDropdown,
  MainStyled,
  Title,
  Text,
  ContainerSearch,
  ContainerSearchPersonal,
  ContainerSearchImg,
  ContainerOption,
  Footer,
};
