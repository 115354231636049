import React from 'react';
import { Tag } from 'antd';

const Tags = ({ title, data, color }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <div>
      <h3>{title}</h3>
      <div className="tag-container__type">
        {data?.map((item, index) => (
          <Tag key={index} color={color}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default Tags;
