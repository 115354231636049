import React, { useCallback, useEffect } from 'react';
import { Avatar, Card, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RxOpenInNewWindow } from 'react-icons/rx';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from '../../../../utility/Context';
import Button from '../../../../elements/Button';
import Empty from '../../../Empty';

import { ContainerFavoriteClasses } from '../styles';
import { getAuthLocalStorage } from '../../../../utility/localStorage';
import { fetchClientsProfessionalsFavorites } from '../../../../ducks/ApplicationDucks/ClientsProfessionalsFavorites';

const { Meta } = Card;

const FavoriteClasses = () => {
  const navigate = useNavigate();
  const userId = getAuthLocalStorage()?.id;

  const redirect = (url) => {
    navigate(url);
  };

  const dispatch = useDispatch();

  const clientProfessional = useCallback(() => {
    dispatch(fetchClientsProfessionalsFavorites(userId));
  }, [dispatch]);

  useEffect(() => {
    clientProfessional();
  }, []);

  const [clientsProfessionalsFavorites, loading] = useSelector(
    ({ application }) => [
      application.clientsProfessionalsFavorites,
      application.loading.clientsProfessionalsFavorites,
    ]
  );

  const toggleFavorite = async (professionalId, clientId, isFavorite) => {
    const token = localStorage.getItem('token');
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${clientId}/professionals/favorites/${professionalId}`,
        {
          method: !isFavorite ? 'POST' : 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Language': 'pt-BR',
          },
        }
      );
      clientProfessional();
    } catch (error) {
      console.error('Erro na chamada POST:', error);
    }
  };

  if (!clientsProfessionalsFavorites?.length) {
    return (
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        <Empty text="Página há profissionais favoritados" />{' '}
      </Spin>
    );
  }

  return (
    <ContainerFavoriteClasses>
      {clientsProfessionalsFavorites?.map(
        (
          {
            firstName,
            lastName,
            aboutClass,
            avatar,
            isFavorite,
            professionalId,
            nickname,
          },
          index
        ) => (
          <Card
            hoverable
            className="classes_card"
            key={index}
            actions={[
              <Button
                className="btn-favorite-class"
                key="favorite"
                type="text"
                icon={isFavorite ? <AiFillHeart /> : <AiOutlineHeart />}
                onClick={() =>
                  toggleFavorite(professionalId, userId, isFavorite)
                }
              >
                {isFavorite ? 'Desfavoritar' : 'Favoritar'}
              </Button>,
              <a
                className="btn-expand-profile"
                key="setting"
                type="text"
                icon={<RxOpenInNewWindow />}
                href={`https://personally.fit/p/${nickname}`}
                target="_blank"
                rel="noreferrer"
              >
                Abrir perfil
              </a>,
            ]}
          >
            <Meta
              avatar={<Avatar src={avatar} />}
              title={`${firstName} ${lastName}`}
              description={aboutClass}
            />
          </Card>
        )
      )}
    </ContainerFavoriteClasses>
  );
};

export default FavoriteClasses;
