import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  &.container {
    margin: 0 auto;
    max-width: 600px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    gap: 12px;
  }
`;

export default { Container };
