export const CALL_EDIT_CLASS = '@application/CALL_EDIT_CLASS';
export const EDIT_CLASS_FAILED = '@application/EDIT_CLASS_FAILED';
export const EDIT_CLASS_SUCCESS = '@application/EDIT_CLASS_SUCCESS';

export const callEditClass = (appointmentId, values, userId) => ({
  type: CALL_EDIT_CLASS,
  appointmentId,
  values,
  userId,
});

export const editClassFailed = () => ({
  type: EDIT_CLASS_FAILED,
});

export const editClassSuccess = () => ({
  type: EDIT_CLASS_SUCCESS,
});

export const onCallEditClass = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    editClass: true,
  },
  errors: {
    ...state.errors,
    editClass: null,
  },
});

export const onEditClassFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    editClass: false,
  },
  errors: {
    ...state.errors,
    editClass: error,
  },
});

export const onEditClassSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    editClass: false,
  },
  errors: {
    ...state.errors,
    editClass: null,
  },
});
