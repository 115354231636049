import styled from 'styled-components';

import { MOBILE_WIDTH } from '../../../defaults/Breakpoints';
import { PRIMARY_COLOR } from '../../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'home-client__container',
})`
  &.home-client__container {
    & .container-table {
      padding: 0 40px;
    }

    & .container-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & .btn-send-email {
      display: flex;
      align-items: center;
    }

    & .ant-table-wrapper {
      margin-bottom: 24px;
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'sign-up__title',
})`
  &.sign-up__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const ContainerButtonFooter = styled.div.attrs({
  className: 'container-button-footer',
})`
  &.container-button-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export const ContainerFields = styled.div.attrs({
  className: 'container-fields',
})`
  &.container-fields {
    & .alert {
      margin: 24px 0;
    }
  }
`;

export const ContainerExpanded = styled.div.attrs({
  className: 'container-expanded',
})`
  &.container-expanded {
    display: flex;
    gap: 12px;
    flex-direction: column;

    & > p {
      font-weight: 700;
    }

    & .more-info-expanded {
      display: flex;
      gap: 75px;
    }

    & .expanded-title {
      font-weight: 600;
    }
  }
`;

export default {
  Container,
  Title,
  ContainerButtonFooter,
  ContainerFields,
  ContainerExpanded,
};
