export const CALL_NOTIFICATION = '@application/CALL_NOTIFICATION';
export const NOTIFICATION_FAILED = '@application/NOTIFICATION_FAILED';
export const NOTIFICATION_SUCCESS = '@application/NOTIFICATION_SUCCESS';

export const CALL_READ_NOTIFICATION = '@application/CALL_READ_NOTIFICATION';
export const READ_NOTIFICATION_FAILED = '@application/READ_NOTIFICATION_FAILED';
export const READ_NOTIFICATION_SUCCESS =
  '@application/READ_NOTIFICATION_SUCCESS';

export const callNotification = (userId) => ({
  type: CALL_NOTIFICATION,
  userId,
});

export const notificationFailed = (error) => ({
  type: NOTIFICATION_FAILED,
  error,
});

export const notificationSuccess = (data) => ({
  type: NOTIFICATION_SUCCESS,
  data,
});

export const callReadNotification = (userId) => ({
  type: CALL_READ_NOTIFICATION,
  userId,
});

export const readNotificationFailed = (error) => ({
  type: READ_NOTIFICATION_FAILED,
  error,
});

export const readNotificationSuccess = (data) => ({
  type: READ_NOTIFICATION_SUCCESS,
  data,
});

export const onCallNotification = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    notification: true,
  },
  errors: {
    ...state.errors,
    notification: null,
  },
  notification: [],
});

export const onNotificationFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    notification: false,
  },
  errors: {
    ...state.errors,
    notification: error,
  },
});

export const onNotificationSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    notification: false,
  },
  errors: {
    ...state.errors,
    notification: null,
  },
  notification: data,
});

export const onCallReadNotification = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    readNotification: true,
  },
  errors: {
    ...state.errors,
    readNotification: null,
  },
});

export const onReadNotificationFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    readNotification: false,
  },
  errors: {
    ...state.errors,
    readNotification: error,
  },
});

export const onReadNotificationSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    readNotification: false,
  },
  errors: {
    ...state.errors,
    readNotification: null,
  },
});
