export const CALL_PROFESSIONAL_COUNT = '@application/CALL_PROFESSIONAL_COUNT';
export const PROFESSIONAL_COUNT_FAILED = '@application/PROFESSIONAL_COUNT_FAILED';
export const PROFESSIONAL_COUNT_SUCCESS = '@application/PROFESSIONAL_COUNT_SUCCESS';

export const callProfessionalCount = () => ({
  type: CALL_PROFESSIONAL_COUNT,
});

export const professionalCountFailed = (error) => ({
  type: PROFESSIONAL_COUNT_FAILED,
  error,
});

export const professionalCountSuccess = (data) => ({
  type: PROFESSIONAL_COUNT_SUCCESS,
  data,
});

export const onCallProfessionalCount = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    professionalCount: true,
  },
  errors: {
    ...state.errors,
    professionalCount: null,
  },
  professionalCount: [],
});

export const onProfessionalCountFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    professionalCount: false,
  },
  errors: {
    ...state.errors,
    professionalCount: error,
  },
  professionalCount: [],
});

export const onProfessionalCountSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    professionalCount: false,
  },
  errors: {
    ...state.errors,
    professionalCount: null,
  },
  professionalCount: data,
});
