import styled, { createGlobalStyle } from 'styled-components';

export const ContainerButtonFooter = styled.div.attrs({
  className: 'container-button-footer',
})`
  &.container-button-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export const ContainerForm = styled.div.attrs({
  className: 'container-form',
})`
  &.container-form {
    margin: 24px 0 50px 0;
  }
`;

export default {
  ContainerButtonFooter,
  ContainerForm,
};
