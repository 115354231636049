import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
// import {
//   PRIMARY_COLOR,
//   WHITE_COLOR,
//   BACKGROUND_BODY,
// } from '../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'personal__container',
})`
  &.personal__container {
    padding: 0px 40px;
    display: flex;
    gap: 50px;
    margin-bottom: 50px;
  }

  & .container-main__personal {
    width: 100%;
  }

  & .container__report-profile {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: end;
  }
`;

export const ContainerMainMobile = styled.div.attrs({
  className: 'container-main__mobile',
})`
  &.container-main__mobile {
    padding: 0px 40px;
    margin-bottom: 50px;
  }
`;

export default { Container, ContainerMainMobile };
