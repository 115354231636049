const convertCategory = (values) => {
  if (!values) {
    return null;
  }

  return values.map((value) => ({
    id: value.id,
    value: value.name,
    label: value.name,
  }));
};

export default convertCategory;
