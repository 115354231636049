import React, { useCallback } from 'react';

import Button from '../../../../elements/Button';

import { useDispatch, useSelector } from '../../../../utility/Context';

import { callConfirmClass } from '../../../../ducks/ApplicationDucks/ConfirmClass';

const ConfirmClass = ({ id, configModal }) => {
  const dispatch = useDispatch();

  const [loadingConfirmClass] = useSelector(({ application }) => [
    application.loading.confirmClass,
  ]);

  const confirmClass = useCallback(() => {
    dispatch(callConfirmClass(id, configModal?.userId));
  }, [dispatch]);

  return (
    <Button type="link" onClick={confirmClass} loading={loadingConfirmClass}>
      Confirmar
    </Button>
  );
};

export default ConfirmClass;
