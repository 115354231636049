import React, { useState } from 'react';

import Button from '../../../elements/Button';

import Modal from './Modal';

const EditClass = ({ professional, appointmentId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="link" onClick={showModal}>
        Editar
      </Button>

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          handleCancel={handleCancel}
          professional={professional}
          appointmentId={appointmentId}
        />
      )}
    </>
  );
};

export default EditClass;
