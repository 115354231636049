import { getHeaders } from '../utility/getHeaders';

class ApplicationAPI {
  static personalTrainerRegistry = (data) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}api/v1/professionals/signup`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static category = () =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/categories`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static professionalCount = () =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/catalogs/count`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static fetchNotification = (userId) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/notifications`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static readNotification = (userId) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/notifications/ack`,
        {
          method: 'PATCH',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static sendEmail = ({ data, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/communications/email`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static createPost = ({ userId, body }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/posts`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static deletePost = ({ postId, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/posts/${postId}`,
        {
          method: 'DELETE',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static fetchCatalog = (filters) => {
    let currQuery = '';

    if (filters) {
      const filteredFilters =
        filters &&
        Object.fromEntries(
          Object.entries(filters).filter(
            ([key, value]) =>
              value !== undefined && value !== '' && value !== null
          )
        );

      const queryString = Object.entries(filteredFilters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      currQuery = `?${queryString}`;
    }

    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/catalogs${currQuery}`, {
        method: 'GET',
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static resetPasswordPersonal = ({ body, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/password`,
        {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static resetPasswordClient = ({ body, userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/password`,
        {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static fetchPersonalClients = ({ userId }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/clients`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static fetchClientsProfessional = ({ userId }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/professionals`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static fetchClientsProfessionalFavorites = ({ userId }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/clients/${userId}/professionals/favorites`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static fetchClientAppointment = ({ userId }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/clients/${userId}`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static fetchProfessionalsAppointment = ({ userId }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/professionals/${userId}`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static rate = ({ id, body }) => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/rate`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static cancelClass = ({ id, body }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/cancel`,
        {
          method: 'PATCH',
          body: JSON.stringify(body),
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
  };

  static fetchAvailability = ({ nickname }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/public/${nickname}/availability`,
        {
          method: 'GET',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then(resolve).catch(reject);
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static bookClass = ({ data }) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/appointments`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
      })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static enableProfile = ({ userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/booking/availability/public`,
        {
          method: 'PATCH',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ errors }) => reject(errors));
          }
        })
        .catch(reject);
    });

  static disabledProfile = ({ userId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/professionals/${userId}/booking/availability/private`,
        {
          method: 'PATCH',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(reject);
    });

  static confirmClass = ({ appointmentId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/${appointmentId}/confirm`,
        {
          method: 'PATCH',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static rejectClass = ({ id, body }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/reject`,
        {
          method: 'PATCH',
          headers: getHeaders(),
          body: JSON.stringify(body),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static report = ({ userId, body }) =>
    new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/reports/${userId}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static editClass = ({ appointmentId, body }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/${appointmentId}`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(body),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });

  static finishClass = ({ appointmentId }) =>
    new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/appointments/${appointmentId}/finish`,
        {
          method: 'PATCH',
          headers: getHeaders(),
        }
      )
        .then((res) => {
          if (res.ok) {
            resolve();
          } else {
            res.json().then(({ message }) => reject(message));
          }
        })
        .catch(reject);
    });
}

export default ApplicationAPI;
