import styled, { createGlobalStyle } from 'styled-components';
import { Form as FormAntd } from 'antd';

import { MOBILE_WIDTH } from '../../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'account-settings__container',
})`
  &.account-settings__container {
    & .menu__favorite-list {
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        color: ${WHITE_COLOR};
      }
    }

    & .menu__menu {
      color: ${PRIMARY_COLOR};
      cursor: pointer;
      font-size: 24px;
    }

    & .account-settings__container__info" {
      display: flex;
      justify-content: space-evenly;
    }
`;

export const Wrapper = styled.div.attrs({
  className: 'account-settings__wrapper',
})`
  &.account-settings__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 34px;
  }
`;

export const Form = styled(FormAntd).attrs({
  className: 'sign-up__form',
})`
  &.sign-up__form {
    width: 400px;
    padding: 20px;
    background: rgb(240 240 240);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;

    @media (max-width: ${MOBILE_WIDTH}px) {
      background: #f8f8f8;
      border-radius: 0px;
      padding: 0px 20px;
    }

    & .btn-save-data {
      width: 100%;
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'sign-up__title',
})`
  &.sign-up__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const ContainerClasses = styled.div.attrs({
  className: 'container-classes',
})`
  &.container-classes {
    margin: 40px;

    & .classes_card {
      width: 350px;
      margin: 24px 0px;

      & li {
        display: flex;
        justify-content: center;
      }

      & .btn-expand-profile {
        display: flex;
        color: ${PRIMARY_COLOR};
        align-items: center;

        & svg {
          color: ${PRIMARY_COLOR};
        }
      }
    }
  }
`;

export const ContainerFavoriteClasses = styled.div.attrs({
  className: 'container-favorite-classes',
})`
  &.container-favorite-classes {
    margin: 40px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;

    & .classes_card {
      width: 300px;
      margin: 24px 0px;

      & li {
        display: flex;
        justify-content: center;
      }

      & .btn-favorite-class {
        display: flex;
        color: ${PRIMARY_COLOR};
        align-items: center;

        & svg {
          color: ${PRIMARY_COLOR};
        }
      }
    }
  }
`;

export default {
  Container,
  Wrapper,
  Form,
  Title,
  ContainerClasses,
  ContainerFavoriteClasses,
};
