import React, { useCallback, useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { MdOutlineMailOutline, MdKeyboardArrowRight } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';

import { callPersonalClients } from '../../../ducks/ApplicationDucks/PersonalClients';

import { PRIMARY_COLOR } from '../../../defaults/Colors';

import { getAuthLocalStorage } from '../../../utility/localStorage';
import { useDispatch, useSelector } from '../../../utility/Context';

import Button from '../../../elements/Button';
import Empty from '../../Empty';

import ModalSendEmail from './ModalSendEmail';
import tableSettings from './tableSettings';

import { Container, Title, ContainerExpanded } from './styles';
import { parseGramsToKg } from '../../../utility/parseKgToGrams';

const Clients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const userId = getAuthLocalStorage()?.id;

  const [personalClients, loadingFetchPersonalData] = useSelector(
    ({ application }) => [
      application.personalClients,
      application.loading.personalClients,
    ]
  );

  const dispatch = useDispatch();

  const fetchPersonalClients = useCallback(() => {
    dispatch(callPersonalClients(userId));
  }, [dispatch]);

  useEffect(() => {
    fetchPersonalClients();
  }, []);

  const expandIcon = ({ expanded, onExpand, record }) => (
    <Button
      type="link"
      icon={
        <MdKeyboardArrowRight
          onClick={(e) => onExpand(record, e)}
          style={{
            transition: 'transform 0.3s ease',
            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
            color: PRIMARY_COLOR,
            fontSize: '1.5rem',
          }}
        />
      }
    />
  );

  const expandedRowRender = (client) => (
    <ContainerExpanded>
      <p>Nome</p>
      <div className="more-info-expanded">
        <div>
          <p className="expanded-description">
            {client.firstName} {client.lastName}
          </p>
        </div>
      </div>

      <p>Contato</p>
      <div className="more-info-expanded">
        <div>
          <p className="expanded-description">{client.phoneNumber}</p>
        </div>
      </div>

      <p>Idade</p>
      <div className="more-info-expanded">
        <div>
          <p className="expanded-description">{client.age} anos</p>
        </div>
      </div>

      <p>Aulas realizadas</p>
      <div className="more-info-expanded">
        <div>
          <p className="expanded-description">{client.totalClasses} aulas</p>
        </div>
      </div>

      <p>Últimas medidas</p>
      <div className="more-info-expanded">
        <div>
          <p className="expanded-description">
            Peso: {parseGramsToKg(client.lastWeight)} kgs
          </p>
          <p className="expanded-description">Altura: {client.lastHeight} cm</p>
          <p className="expanded-description">
            Taxa de gordura corporal: {client.lastBodyFat}%
          </p>
        </div>
      </div>

      <p>Objetivos</p>
      <div className="more-info-expanded">
        <div>
          <p className="expanded-description">
            {client?.objectives?.join(', ')}
          </p>
        </div>
      </div>
    </ContainerExpanded>
  );

  return (
    <Container>
      <Spin
        spinning={loadingFetchPersonalData}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        <div className="container-table">
          <div className="container-header">
            <Title>Meus clientes</Title>
            <Button
              type="default"
              icon={<MdOutlineMailOutline />}
              onClick={showModal}
              className="btn-send-email"
            >
              Enviar e-mail
            </Button>
          </div>

          <Table
            columns={tableSettings}
            dataSource={personalClients}
            expandable={{
              expandedRowRender: (record) => expandedRowRender(record),
              expandIcon,
            }}
            locale={{
              emptyText: <Empty text="Você ainda não tem nenhum cliente" />,
            }}
          />
        </div>

        <ModalSendEmail
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          userId={userId}
        />
      </Spin>
    </Container>
  );
};

export default Clients;
