import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'education__container',
})`
  &.education__container {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;

    & header {
      align-items: center;
      display: flex;
      padding: 5px 0 20px 0;
      justify-content: space-between;
    }
  }
`;

export const EducationExpand = styled.div.attrs({
  className: 'education__expand',
})`
  &.education__expand {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const EducationIconContainer = styled.div.attrs({
  className: 'education__icon-container',
})`
  &.education__icon-container {
    display: flex;
    gap: 12px;

    & button {
      font-size: 17px;
      height: 0px;
      padding: 0px;
    }
  }
`;

export const EducationTitle = styled.h4.attrs({
  className: 'education__title',
})`
  &.education__title {
  }
`;

export const EducationDescription = styled.p.attrs({
  className: 'education__description',
})`
  &.education__description {
  }
`;

export const ContainerButton = styled.div.attrs({
  className: 'container-btn',
})`
  &.container-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export default {
  Container,
  EducationExpand,
  EducationIconContainer,
  EducationTitle,
  EducationDescription,
  ContainerButton,
};
