import styled from 'styled-components';
import { Form as FormAntd } from 'antd';

import {
  BACKGROUND_BODY,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from '../../defaults/Colors';
import { MOBILE_WIDTH, TABLET_WIDTH } from '../../defaults/Breakpoints';

export const Main = styled.main.attrs({
  className: 'sign-up__main',
})`
  &.sign-up__main {
    padding: 0 40px;
    gap: 34px;
    display: flex;
    flex-direction: column;
}
  }
`;

export const Container = styled.div.attrs({
  className: 'sign-up__container',
})`
  &.sign-up__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 24px 40px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
    }

    & img {
      width: 500px;
      height: 500px;

      @media (max-width: ${TABLET_WIDTH}px) {
        width: 400px;
        height: 400px;
      }
    }
  }
`;

export const Form = styled(FormAntd).attrs({
  className: 'sign-up__form',
})`
  &.sign-up__form {
    width: 400px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.8rem;

    @media (max-width: ${MOBILE_WIDTH}px) {
      background: ${BACKGROUND_BODY};
      border-radius: 0px;
      padding: 0px 20px;
    }

    & .btn-sign-up {
      width: 100%;
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'sign-up__title',
})`
  &.sign-up__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const ContainerTerms = styled.div.attrs({
  className: 'sign-up__container-terms',
})`
  &.sign-up__container-terms {
    margin: 24px 0 0px;

    & .btn-terms {
      padding: 0px;
      font-size: 14px;
      height: 0px;
    }
  }
`;

export const ContainerSignIn = styled.div.attrs({
  className: 'sign-up__container-signin',
})`
  &.sign-up__container-signin {
    margin-bottom: 24px;
  }
`;

export const ContainerOption = styled.div.attrs({
  className: 'option-container',
})`
  &.option-container {
    align-items: flex-start;
    display: flex;
    padding: 24px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      text-align: start;
    }

    ${({ direct }) =>
      direct &&
      `
        justify-content: ${direct};
    `}

    & div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 12px;
      margin: 24px;
      max-width: 250px;
      text-align: justify;
    }

    & img {
      width: 300px;
      height: 300px;

      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 150px;
        height: 150px;
      }
    }

    & p {
      color: #7a7a7a;
    }
  }
`;

export const ContainerInfo = styled.div.attrs({
  className: 'container-info',
})`
  &.container-info {
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    background: ${BACKGROUND_BODY};
    margin: 24px 0;
    border-radius: 30px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      border-radius: 0px;
    }
  }
`;

export const ContainerMoreInfo = styled.div.attrs({
  className: 'container-more-info',
})`
  &.container-more-info {
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;

    & .container-info__more-info {
      display: flex;
      gap: 34px;
      align-items: center;

      @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
      }
    }

    & .container-info__infoA {
      flex: 1;
    }

    & .container-info__infoB {
      flex: 1;
      justify-content: flex-end;

      & ul {
        list-style-type: none;

        & li {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 12px;
          font-weight: 600;

          & svg {
            color: ${PRIMARY_COLOR};
            font-size: 20px;
          }
        }
      }
    }

    & img {
      width: 250px;
      height: 250px;
    }
  }
`;

export const TooltipContainerCellphone = styled.div.attrs({
  className: 'container-tooltip',
})`
  &.container-tooltip {
    display: flex;
    width: 100%;
  }
`;

export default {
  Container,
  Form,
  Title,
  ContainerTerms,
  ContainerSignIn,
  ContainerOption,
  ContainerInfo,
  ContainerMoreInfo,
  TooltipContainerCellphone,
};
