import React from 'react';
import dayjs from 'dayjs';
import { Timeline } from 'antd';

import Empty from '../../../Empty';

import { Container, ContainerTimeline, Title } from './styles';

const Qualifications = ({ userData }) => {
  const { educations, experiences } = userData;

  const timelineEducation = (items) =>
    items?.map((item) => ({
      children: (
        <ContainerTimeline>
          <p>
            <span>Instituto: </span>
            {item.institution}
          </p>
          <p>
            <span>Área de estudo: </span>
            {item.fieldOfStudy}
          </p>
          <p>
            <span>Grau: </span>
            {item.degree}
          </p>
          <p>
            <span>Data de início: </span>
            {item.startDate}
          </p>
          <p>
            <span>Data do término: </span>

            {item?.endDate ? item.endDate : 'Sem data prevista'}
          </p>
        </ContainerTimeline>
      ),
    }));

  const timelineExperiences = (items) =>
    items?.map((item) => ({
      children: (
        <ContainerTimeline>
          <p>
            <span>Empresa: </span>
            {item.company}
          </p>
          <p>
            <span>Atividades: </span>
            {item.activities}
          </p>
          <p>
            <span>Posição: </span>
            {item.position}
          </p>
          <p>
            <span>Data de início: </span>
            {item.startDate}
          </p>
          <p>
            <span>Data do término: </span>
            {item?.endDate ? item.endDate : 'Sem data prevista'}
          </p>
        </ContainerTimeline>
      ),
    }));

  const hasExperiences = experiences?.length;
  const hasEducations = educations?.length;

  if (!hasExperiences && !hasEducations) {
    return <Empty text="Não há qualificações no momento..." />;
  }

  return (
    <Container>
      {hasExperiences ? (
        <>
          <Title>Experiência</Title>
          <Timeline items={timelineExperiences(experiences)} />
        </>
      ) : null}

      {hasEducations ? (
        <>
          <Title>Formação acadêmica</Title>
          <Timeline items={timelineEducation(educations)} />
        </>
      ) : null}
    </Container>
  );
};

export default Qualifications;
