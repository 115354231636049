export const FETCH_CLIENT_APPOINTMENT = '@application/FETCH_CLIENT_APPOINTMENT';
export const CLIENT_APPOINTMENT_FAILED =
  '@application/CLIENT_APPOINTMENT_FAILED';
export const CLIENT_APPOINTMENT_SUCCESS =
  '@application/CLIENT_APPOINTMENT_SUCCESS';

export const fetchClientAppointment = (userId) => ({
  type: FETCH_CLIENT_APPOINTMENT,
  userId,
});

export const clientAppointmentFailed = (error) => ({
  type: CLIENT_APPOINTMENT_FAILED,
  error,
});

export const clientAppointmentSuccess = (data) => ({
  type: CLIENT_APPOINTMENT_SUCCESS,
  data,
});

export const onFetchClientAppointment = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    clientAppointment: true,
  },
  errors: {
    ...state.errors,
    clientAppointment: null,
  },
  appointments: {
    ...state.appointments,
    all: [],
  },
});

export const onClientAppointmentFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    clientAppointment: false,
  },
  errors: {
    ...state.errors,
    clientAppointment: error,
  },
});

export const onClientAppointmentSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    clientAppointment: false,
  },
  errors: {
    ...state.errors,
    clientAppointment: null,
  },
  appointments: {
    ...state.appointments,
    all: data,
  },
});
