export const CALL_DELETE_POST = '@application/CALL_DELETE_POST';
export const DELETE_POST_FAILED = '@application/DELETE_POST_FAILED';
export const DELETE_POST_SUCCESS = '@application/DELETE_POST_SUCCESS';

export const callDeletePost = ({ postId, userId }) => ({
  type: CALL_DELETE_POST,
  postId,
  userId,
});

export const deletePostFailed = (error) => ({
  type: DELETE_POST_FAILED,
  error,
});

export const deletePostSuccess = (data) => ({
  type: DELETE_POST_SUCCESS,
  data,
});

export const onCallDeletePost = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    deletePost: true,
  },
  errors: {
    ...state.errors,
    deletePost: null,
  },
});

export const onDeletePostFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    deletePost: false,
  },
  errors: {
    ...state.errors,
    deletePost: error,
  },
});

export const onDeletePostSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    deletePost: false,
  },
  errors: {
    ...state.errors,
    deletePost: null,
  },
  data: {
    ...state.data,
    personal: data,
  },
});
