import React from 'react';
import { all, fork, takeEvery, call, put } from 'redux-saga/effects';
import { message } from 'antd';

import { callPersonalData } from '../ducks/UserDucks/FetchPersonalData';
import {
  CALL_PERSONAL_TRAINER_REGISTRY,
  personalTrainerRegistrySuccess,
  personalTrainerRegistryFailed,
} from '../ducks/ApplicationDucks/PersonalTrainerRegistry';
import {
  CALL_CATEGORY,
  categoryFailed,
  categorySuccess,
} from '../ducks/ApplicationDucks/Category';
import {
  CALL_PROFESSIONAL_COUNT,
  professionalCountFailed,
  professionalCountSuccess,
} from '../ducks/ApplicationDucks/ProfessionalCount';
import {
  CALL_NOTIFICATION,
  CALL_READ_NOTIFICATION,
  callNotification,
  notificationFailed,
  notificationSuccess,
  readNotificationSuccess,
  readNotificationFailed,
} from '../ducks/ApplicationDucks/Notification';
import {
  CALL_SEND_EMAIL,
  sendEmailFailed,
  sendEmailSuccess,
} from '../ducks/ApplicationDucks/SendEmail';
import {
  CALL_CREATE_POST,
  createPostFailed,
  createPostSuccess,
} from '../ducks/ApplicationDucks/CreatePost';
import {
  CALL_DELETE_POST,
  deletePostFailed,
  deletePostSuccess,
} from '../ducks/ApplicationDucks/DeletePost';
import {
  FETCH_CATALOG,
  catalogFailed,
  catalogSuccess,
} from '../ducks/ApplicationDucks/FetchCatalog';
import {
  CALL_RESET_PASSWORD,
  resetPasswordFailed,
  resetPasswordSuccess,
} from '../ducks/ApplicationDucks/ResetPassword';
import {
  CALL_PERSONAL_CLIENTS,
  personalClientsFailed,
  personalClientsSuccess,
} from '../ducks/ApplicationDucks/PersonalClients';
import {
  FETCH_CLIENT_APPOINTMENT,
  fetchClientAppointment,
  clientAppointmentFailed,
  clientAppointmentSuccess,
} from '../ducks/ApplicationDucks/FetchClientAppointments';
import {
  CALL_RATE,
  rateFailed,
  rateSuccess,
} from '../ducks/ApplicationDucks/Rate';
import {
  CALL_CANCEL_CLASS,
  cancelClassFailed,
  cancelClassSuccess,
} from '../ducks/ApplicationDucks/CancelClass';
import {
  CALL_AVAILABILITY,
  callAvailability,
  availabilitySuccess,
  availabilityFailed,
} from '../ducks/ApplicationDucks/Availability';
import {
  CALL_BOOK_CLASS,
  bookClassFailed,
  bookClassSuccess,
} from '../ducks/ApplicationDucks/BookClass';
import {
  CALL_ENABLE_PROFILE,
  enableProfileFailed,
  enableProfileSuccess,
} from '../ducks/ApplicationDucks/EnableProfile';
import {
  CALL_CONFIRM_CLASS,
  confirmClassFailed,
  confirmClassSuccess,
} from '../ducks/ApplicationDucks/ConfirmClass';
import {
  CALL_REJECT_CLASS,
  rejectClassFailed,
  rejectClassSuccess,
} from '../ducks/ApplicationDucks/Reject';
import {
  CALL_REPORT,
  reportFailed,
  reportSuccess,
} from '../ducks/ApplicationDucks/Report';

import ApplicationAPI from '../integrations/ApplicationAPI';
import personalTrainerRegistryTreated from '../utility/personalTrainerRegistryTreated';
import {
  convertPersonalClientsData,
  convertPersonalData,
  convertPersonalDataFilters,
} from '../utility/convertPersonalData';
import convertCategory from '../utility/convertCategory';
import convertNotification from '../utility/convertNotification';
import convertCustomerData from '../utility/convertCustomerData';
import convertClientAppointment from '../utility/convertClientAppointment';

import { USER_TYPE_PROFESSIONAL } from '../defaults/userType';
import {
  CALL_CLIENTS_PROFESSIONALS,
  clientsProfessionalsFailed,
  clientsProfessionalsSuccess,
} from '../ducks/ApplicationDucks/ClientsProfessionals';
import { convertClientProfessionalData } from '../utility/convertClientProfessional';
import {
  CALL_CLIENTS_PROFESSIONALS_FAVORITES,
  clientsProfessionalsFavoritesFailed,
  clientsProfessionalsFavoritesSuccess,
} from '../ducks/ApplicationDucks/ClientsProfessionalsFavorites';
import {
  FETCH_PROFESSIONALS_APPOINTMENT,
  fetchProfessionalsAppointment,
  professionalsAppointmentFailed,
  professionalsAppointmentSuccess,
} from '../ducks/ApplicationDucks/FetchProfessionalsAppointments';
import {
  CALL_FINISH_CLASS,
  finishClassFailed,
  finishClassSuccess,
} from '../ducks/ApplicationDucks/FinishClass';
import {
  CALL_EDIT_CLASS,
  editClassFailed,
  editClassSuccess,
} from '../ducks/ApplicationDucks/EditClass';

function* registerUserSaga({ data }) {
  try {
    const body = personalTrainerRegistryTreated(data);

    yield call(ApplicationAPI.personalTrainerRegistry, body);

    personalTrainerRegistrySuccess();
  } catch (error) {
    personalTrainerRegistryFailed(error);
    message.error('Não foi possivel cadastrar usuário.');
  }
}

function* registerUserSagas() {
  yield takeEvery(CALL_PERSONAL_TRAINER_REGISTRY, registerUserSaga);
}

function* category() {
  try {
    const response = yield call(ApplicationAPI.category);

    const categories = convertCategory(response);

    yield put(categorySuccess(categories));
  } catch (error) {
    yield put(categoryFailed(error));
    message.error('Não foi possivel cadastrar usuário.');
  }
}

function* categorySagas() {
  yield takeEvery(CALL_CATEGORY, category);
}

function* professionalCount() {
  try {
    const response = yield call(ApplicationAPI.professionalCount);

    yield put(professionalCountSuccess(response.total));
  } catch (error) {
    yield put(professionalCountFailed(error));
    message.error('Não foi possivel listar profissionais.');
  }
}

function* professionalCountSagas() {
  yield takeEvery(CALL_PROFESSIONAL_COUNT, professionalCount);
}

function* notification({ userId }) {
  try {
    const data = yield call(ApplicationAPI.fetchNotification, userId);

    const currData = convertNotification(data);

    yield put(notificationSuccess(currData));
  } catch (error) {
    yield put(notificationFailed(error));
    message.error('Não foi possivel obter as notificações.');
  }
}

function* notificationSagas() {
  yield takeEvery(CALL_NOTIFICATION, notification);
}

function* readNotification({ userId }) {
  try {
    yield call(ApplicationAPI.readNotification, userId);

    yield put(readNotificationSuccess());
    yield put(callNotification(userId));
  } catch (error) {
    yield put(readNotificationFailed(error));
    message.error('Não foi possivel ler as notificações.');
  }
}

function* readNotificationSagas() {
  yield takeEvery(CALL_READ_NOTIFICATION, readNotification);
}

function* sendEmail({ content, userId }) {
  try {
    const data = {
      content,
    };

    yield call(ApplicationAPI.sendEmail, { data, userId });

    yield put(sendEmailSuccess());
    message.success('E-mail enviado com sucesso');
  } catch (error) {
    yield put(sendEmailFailed(error));
    message.error('Não foi possivel enviar o e-mail');
  }
}

function* sendEmailSagas() {
  yield takeEvery(CALL_SEND_EMAIL, sendEmail);
}

function* createPost({ userId, title, content }) {
  try {
    const body = {
      title,
      content,
    };

    const data = yield call(ApplicationAPI.createPost, { userId, body });

    yield put(createPostSuccess());
    yield put(callPersonalData());
    message.success('Publicação criada com sucesso');
  } catch (error) {
    yield put(createPostFailed(error));
    message.error('Não foi possivel criar publicação');
  }
}

function* createPostSagas() {
  yield takeEvery(CALL_CREATE_POST, createPost);
}

function* deletePost({ postId, userId }) {
  try {
    yield call(ApplicationAPI.deletePost, { postId, userId });

    yield put(deletePostSuccess());
    yield put(callPersonalData());
    message.success('Publicação deletada com sucesso');
  } catch (error) {
    yield put(deletePostFailed(error));
    message.error('Não foi possivel deletar publicação');
  }
}

function* deletePostSagas() {
  yield takeEvery(CALL_DELETE_POST, deletePost);
}

function* fetchCatalog({ filters }) {
  try {
    const currFilters = convertPersonalDataFilters(filters);
    const data = yield call(ApplicationAPI.fetchCatalog, currFilters);

    const convertData = data?.map(convertPersonalData);
    yield put(catalogSuccess(convertData));
  } catch (error) {
    yield put(catalogFailed(error));
    message.error('Não foi possivel buscar profissionais');
  }
}

function* fetchCatalogSagas() {
  yield takeEvery(FETCH_CATALOG, fetchCatalog);
}

function* resetPassword({
  oldPassword,
  newPassword,
  confirmNewPassword,
  userType,
  userId,
}) {
  try {
    const body = {
      old_password: oldPassword,
      new_password: newPassword,
      password_confirmation: confirmNewPassword,
    };

    if (userType === USER_TYPE_PROFESSIONAL) {
      yield call(ApplicationAPI.resetPasswordPersonal, {
        body,
        userId,
      });
    } else {
      yield call(ApplicationAPI.resetPasswordClient, {
        body,
        userId,
      });
    }

    message.success('Senha alterada com sucesso');
    yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordFailed(error));
    message.error(error || 'Não foi possível alterar senhas');
  }
}

function* resetPasswordSagas() {
  yield takeEvery(CALL_RESET_PASSWORD, resetPassword);
}

function* fetchPersonalClients({ userId }) {
  try {
    const data = yield call(ApplicationAPI.fetchPersonalClients, { userId });

    const convert = data?.map(convertPersonalClientsData);
    yield put(personalClientsSuccess(convert));
  } catch (error) {
    yield put(personalClientsFailed(error));
    message.error(error || 'Não foi possivel buscar os clientes');
  }
}

function* fetchPersonalClientsSagas() {
  yield takeEvery(CALL_PERSONAL_CLIENTS, fetchPersonalClients);
}

function* fetchClientsProfessionals({ userId }) {
  try {
    const data = yield call(ApplicationAPI.fetchClientsProfessional, {
      userId,
    });

    const convert = data?.map(convertClientProfessionalData);
    yield put(clientsProfessionalsSuccess(convert));
  } catch (error) {
    yield put(clientsProfessionalsFailed(error));
    message.error(error || 'Não foi possivel buscar os profissionais');
  }
}

function* fetchClientsProfessionalsSagas() {
  yield takeEvery(CALL_CLIENTS_PROFESSIONALS, fetchClientsProfessionals);
}

function* fetchClientsProfessionalsFavorites({ userId }) {
  try {
    const data = yield call(ApplicationAPI.fetchClientsProfessionalFavorites, {
      userId,
    });

    const convert = data?.map(convertClientProfessionalData);
    yield put(clientsProfessionalsFavoritesSuccess(convert));
  } catch (error) {
    yield put(clientsProfessionalsFavoritesFailed(error));
    message.error(error || 'Não foi possivel buscar os profissionais');
  }
}

function* fetchClientsProfessionalsFavoritesSagas() {
  yield takeEvery(
    CALL_CLIENTS_PROFESSIONALS_FAVORITES,
    fetchClientsProfessionalsFavorites
  );
}

function* callClientAppointment({ userId }) {
  try {
    const data = yield call(ApplicationAPI.fetchClientAppointment, { userId });

    const convert = data?.map(convertClientAppointment);

    yield put(clientAppointmentSuccess(convert));
  } catch (error) {
    yield put(clientAppointmentFailed(error));
    message.error(error || 'Não foi possivel buscar os agendamentos');
  }
}

function* fetchClientAppointmentSagas() {
  yield takeEvery(FETCH_CLIENT_APPOINTMENT, callClientAppointment);
}

function* callProfessionalsAppointment({ userId }) {
  try {
    const data = yield call(ApplicationAPI.fetchProfessionalsAppointment, {
      userId,
    });

    const convert = data?.map(convertClientAppointment);

    yield put(professionalsAppointmentSuccess(convert));
  } catch (error) {
    yield put(professionalsAppointmentFailed(error));
    message.error(error || 'Não foi possivel buscar os agendamentos');
  }
}

function* fetchProfessionalAppointmentSagas() {
  yield takeEvery(
    FETCH_PROFESSIONALS_APPOINTMENT,
    callProfessionalsAppointment
  );
}

function* rate({ id, body, userId }) {
  try {
    yield call(ApplicationAPI.rate, { id, body });
    yield put(rateSuccess());
    message.success('Avaliação feita com sucesso');
    yield put(fetchClientAppointment(userId));
  } catch (error) {
    yield put(rateFailed(error));
    message.error(error || 'Não foi possivel avaliar a aula');
  }
}

function* rateSagas() {
  yield takeEvery(CALL_RATE, rate);
}

function* cancelClass({ id, reason, userId, userType }) {
  try {
    const body = {
      reason,
    };

    yield call(ApplicationAPI.cancelClass, { id, body });
    yield put(cancelClassSuccess());
    message.success('Aula cancelada com sucesso');

    if (userType === USER_TYPE_PROFESSIONAL) {
      yield put(fetchProfessionalsAppointment(userId));
      return;
    }
    yield put(fetchClientAppointment(userId));
  } catch (error) {
    yield put(cancelClassFailed(error));
    message.error(error || 'Não foi possivel cancelar a aula');
  }
}

function* cancelClassSagas() {
  yield takeEvery(CALL_CANCEL_CLASS, cancelClass);
}

function* availability({ nickname }) {
  try {
    const response = yield call(ApplicationAPI.fetchAvailability, { nickname });
    yield put(availabilitySuccess(response));
  } catch (error) {
    yield put(availabilityFailed(error));
    message.error(error || 'Não foi possível obter a agenda');
  }
}

function* availabilitySagas() {
  yield takeEvery(CALL_AVAILABILITY, availability);
}

function* bookClass({
  clientId,
  professionalId,
  classModality,
  startDate,
  typeClass,
}) {
  try {
    const data = {
      client_id: clientId,
      professional_id: professionalId,
      class_modality: classModality,
      start_date: startDate,
      type: typeClass,
    };

    yield call(ApplicationAPI.bookClass, { data });
    yield put(bookClassSuccess());
    message.success(
      'Solicitação de agendamento enviada. Pendente de confirmação do profissional.'
    );

    yield put(fetchClientAppointment(clientId));
  } catch (error) {
    yield put(bookClassFailed(error));
    message.error(error || 'Não foi possível marcar aula.');
  }
}

function* bookClassSagas() {
  yield takeEvery(CALL_BOOK_CLASS, bookClass);
}

function* enableProfile({ checked, userId, nickname }) {
  try {
    if (checked) {
      yield call(ApplicationAPI.enableProfile, { userId });
      message.success('Uhuuu! Agora é possível receber novos agendamentos.');
    } else {
      yield call(ApplicationAPI.disabledProfile, { userId });
      message.success(
        'Agenda fechada com sucesso. Você não receberá mais nenhum agendamento.'
      );
    }

    yield put(callPersonalData());
    yield put(enableProfileSuccess());
    yield put(callAvailability(nickname));
  } catch (errors) {
    yield put(enableProfileFailed(errors));
    message.error(
      errors?.length
        ? errors.map((error, index) => (
            <>
              <span key={index}>{error}</span>
              <br />
            </>
          ))
        : 'Não foi possível abrir a agenda'
    );
  }
}

function* enableProfileSagas() {
  yield takeEvery(CALL_ENABLE_PROFILE, enableProfile);
}

function* confirmClass({ appointmentId, userId }) {
  try {
    yield call(ApplicationAPI.confirmClass, { appointmentId });
    message.success('Aula confirmada com sucesso');

    yield put(fetchProfessionalsAppointment(userId));
    yield put(confirmClassSuccess());
  } catch (error) {
    yield put(confirmClassFailed(error));
    message.error(error || 'Não foi possivel confirmar aula');
  }
}

function* confirmClassSagas() {
  yield takeEvery(CALL_CONFIRM_CLASS, confirmClass);
}

function* rejectClass({ id, reason, userId }) {
  try {
    const body = {
      reason,
    };

    yield call(ApplicationAPI.rejectClass, { id, body });
    message.success('Aula rejeitada com sucesso');

    yield put(fetchProfessionalsAppointment(userId));
    yield put(rejectClassSuccess());
  } catch (error) {
    yield put(rejectClassFailed(error));
    message.error(error || 'Não foi possivel rejeitar a aula');
  }
}

function* rejectClassSagas() {
  yield takeEvery(CALL_REJECT_CLASS, rejectClass);
}

function* reportProfessional({ userId, reason }) {
  try {
    const body = {
      cause: reason,
    };

    yield call(ApplicationAPI.report, { userId, body });
    message.success('Denúncia registrada com sucesso.');

    yield put(reportSuccess());
  } catch (error) {
    yield put(reportFailed(error));
    message.error(
      error ||
        'Não foi possivel reportar o profissonal. Por favor, tente novamente mais tarde.'
    );
  }
}

function* reportProfessionalSagas() {
  yield takeEvery(CALL_REPORT, reportProfessional);
}

function* finishClass({ appointmentId }) {
  try {
    yield call(ApplicationAPI.finishClass, { appointmentId });
    message.success('Aula finalizada com sucesso');

    yield put(finishClassSuccess());
  } catch (error) {
    yield put(finishClassFailed(error));
    message.error(
      error ||
        'Não foi possivel finalizar a aula. Por favor, tente novamente mais tarde.'
    );
  }
}

function* finishClassSagas() {
  yield takeEvery(CALL_FINISH_CLASS, finishClass);
}

function* editClass({ appointmentId, values, userId }) {
  try {
    const body = {
      type: values?.type,
      class_modality: values?.classModality,
      start_date: values?.startDate,
    };

    yield call(ApplicationAPI.editClass, { appointmentId, body });
    message.success('Aula editada com sucesso');

    yield put(editClassSuccess());
    yield put(fetchClientAppointment(userId));
  } catch (error) {
    yield put(editClassFailed(error));
    message.error(
      error ||
        'Não foi possivel editar a aula. Por favor, tente novamente mais tarde.'
    );
  }
}

function* editClassSagas() {
  yield takeEvery(CALL_EDIT_CLASS, editClass);
}

export default function* () {
  yield all([
    fork(registerUserSagas),
    fork(categorySagas),
    fork(professionalCountSagas),
    fork(notificationSagas),
    fork(readNotificationSagas),
    fork(sendEmailSagas),
    fork(createPostSagas),
    fork(deletePostSagas),
    fork(fetchCatalogSagas),
    fork(resetPasswordSagas),
    fork(fetchPersonalClientsSagas),
    fork(fetchProfessionalAppointmentSagas),
    fork(fetchClientsProfessionalsSagas),
    fork(fetchClientsProfessionalsFavoritesSagas),
    fork(fetchClientAppointmentSagas),
    fork(rateSagas),
    fork(cancelClassSagas),
    fork(availabilitySagas),
    fork(bookClassSagas),
    fork(enableProfileSagas),
    fork(confirmClassSagas),
    fork(rejectClassSagas),
    fork(reportProfessionalSagas),
    fork(finishClassSagas),
    fork(editClassSagas),
  ]);
}
