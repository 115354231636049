import React, { useCallback } from 'react';
import { Modal, Form, notification, Alert } from 'antd';

import Textarea from '../../../../elements/Textarea';
import Button from '../../../../elements/Button';

import { callSendEmail } from '../../../../ducks/ApplicationDucks/SendEmail';

import { getRuleField } from '../../../../utility/RuleField';
import { useDispatch, useSelector } from '../../../../utility/Context';

import { ContainerFields, ContainerButtonFooter } from '../styles';

const TEXTAREA_SEND_EMAIL = {
  name: 'content',
  label: 'Conteúdo do e-mail',
  placeholder: 'Escreva aqui ...',
  isRequired: true,
  showCount: true,
  maxLength: 5000,
};

const ModalSendEmail = ({ isModalOpen, handleCancel, userId }) => {
  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel enviar e-mail');
  };

  const dispatch = useDispatch();

  const sendEmail = useCallback(
    (content) => {
      dispatch(callSendEmail({ content, userId }));
    },
    [dispatch]
  );

  const onFinish = ({ content }) => {
    const currContent = content.trim();

    if (currContent.length < 10) {
      message(
        'warning',
        'Atenção!',
        'O conteúdo do e-mail deve ter mais de 10 caracteres.'
      );
      return;
    }

    sendEmail(currContent);
    handleCancel();
  };

  const [loading] = useSelector(({ application }) => [
    application.loading.sendEmail,
  ]);

  return (
    <>
      {contextHolder}
      <Modal
        title="Enviar e-mail"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ContainerFields>
            <Alert
              message="A comunicação será enviada para todos os seus clientes."
              type="warning"
              showIcon
              className="alert"
            />

            <Textarea
              textareaConfig={TEXTAREA_SEND_EMAIL}
              rules={[getRuleField(TEXTAREA_SEND_EMAIL)]}
            />

            <ContainerButtonFooter>
              <Button key="back" disabled={loading} onClick={handleCancel}>
                Voltar
              </Button>
              <Button
                key="submit"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Enviar
              </Button>
            </ContainerButtonFooter>
          </ContainerFields>
        </Form>
      </Modal>
    </>
  );
};

export default ModalSendEmail;
