import styled from 'styled-components';

import { PRIMARY_COLOR, WHITE_COLOR } from '../../defaults/Colors';
import { MOBILE_WIDTH } from '../../defaults/Breakpoints';

export const Container = styled.div.attrs({
  className: 'reset-password__container',
})`
  &.reset-password__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
      gap: 24px;
    }

    & img {
      width: 400px;
      height: 400px;
    }

    & .reset-password__form {
      min-width: 300px;
      max-width: 400px;

      & button {
        width: 100%;
      }
    }
  }
`;

export const Header = styled.header.attrs({
  className: 'signin__header',
})`
  &.signin__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    background: ${WHITE_COLOR};
    flex-wrap: wrap;
    margin-bottom: 40px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      padding: 0px 20px;
      margin-bottom: 20px;
    }
  }
`;

export const ContainerButton = styled.div.attrs({
  className: 'container-button',
})`
  &.container-button {
    font-size: 14px;
  }
`;

export default { Container, Header, ContainerButton };
