import React, { useCallback } from 'react';
import { Divider, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BsQuestionCircle } from 'react-icons/bs';

import Input from '../../../elements/Input';
import DatePicker from '../../../elements/DatePicker';
import InputMasked from '../../../elements/InputMasked';
import Textarea from '../../../elements/Textarea';
import Button from '../../../elements/Button';

import {
  INPUT_FIRST_NAME,
  INPUT_LAST_NAME,
  INPUT_EMAIL,
  INPUT_CPF,
  DATE_PICKER_BIRTH_DATE,
  INPUT_PASSWORD,
  INPUT_CONFIRM_PASSWORD,
  INPUT_WEIGHT,
  INPUT_HEIGHT,
  TEXTAREA_RESTRICTION,
  INPUT_NUMBER_PHONE,
} from '../../../defaults/RegisterUserFields';
import passwordRegex from '../../../defaults/passwordRegex';

import { callSignUpClient } from '../../../ducks/UserDucks/SignUpClient';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useNotification from '../../../hooks/useNotification';

import { useDispatch, useSelector } from '../../../utility/Context';
import { getRuleField } from '../../../utility/RuleField';
import { parseKgToGrams } from '../../../utility/parseKgToGrams';
import removeCommasAndDots from '../../../utility/removeCommasAndDots';
import removeCPFCharacter from '../../../utility/removeCPFCharacter';
import { convertCustomerDataBackend } from '../../../utility/convertCustomerDataBackend';
import validateCPF from '../../../utility/validateCPF';
import validateOnlyNumbers from '../../../utility/validateOnlyNumbers';

import Terms from '../../../components/Terms';

import {
  Container,
  Form,
  Title,
  ContainerSignIn,
  TooltipContainerCellphone,
} from '../styles';

const SignUpClient = () => {
  const { isMobile } = useWindowDimensions();

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const { showNotification, contextHolder } = useNotification();

  const dispatch = useDispatch();

  const singUpClient = useCallback(
    (values) => dispatch(callSignUpClient(values)),
    [dispatch]
  );

  const onFinish = (values) => {
    const { cpf, weight, height, birthDate, confirmTerms, ...rest } = values;

    if (!confirmTerms) {
      showNotification(
        'warning',
        'Aceite os Termos de Uso e Políticas de Privacidade',
        'Antes de prosseguir, recomendamos que revise atentamente nossos Termos de Uso e Políticas de Privacidade. Ao aceitá-los, você confirma sua compreensão e concordância com as diretrizes que regem o uso dos nossos serviços.',
        10.0
      );

      return;
    }

    const removeCommasAndDotsCPF = removeCPFCharacter(cpf);
    const isValidCPF = validateCPF(removeCommasAndDotsCPF);

    if (!isValidCPF) {
      showNotification(
        'warning',
        'CPF inválido',
        'Ops! Parece que o CPF inserido não está correto. Por favor, confira e insira um CPF válido.'
      );

      return;
    }

    const currWeight = removeCommasAndDots(parseKgToGrams(weight));
    const convertIntWeight = parseInt(currWeight, 10);

    if (convertIntWeight < 30000) {
      showNotification(
        'warning',
        'Peso Mínimo Requerido',
        'Certifique-se de inserir um valor igual ou superior a 30 kgs para atender aos requisitos mínimos. Isso garante a precisão das informações.'
      );

      return;
    }

    const convertIntHeight = parseInt(height, 10);

    if (convertIntHeight < 100) {
      showNotification(
        'warning',
        'Altura Mínima Requerida',
        'Certifique-se de inserir um valor igual ou superior a 100 centímetros para atender aos requisitos mínimos.'
      );
      return;
    }

    const inputDate = new Date(birthDate);

    const rfc3339FormattedDate = moment(inputDate).format(
      'YYYY-MM-DDTHH:mm:ss[Z]'
    );

    const body = convertCustomerDataBackend({
      ...rest,
      cpf,
      weight: convertIntWeight,
      height: convertIntHeight,
      birthDate: rfc3339FormattedDate,
    });

    singUpClient(body);
  };

  const onFinishFailed = () => {
    showNotification(
      'warning',
      'Preencha os dados corretamente',
      'Por favor, preencha o formulário corretamente para continuar.'
    );
  };

  const loading = useSelector(({ user }) => user.loading.signUp.client);

  const BtnHeader = (
    <>
      <span>Já tem uma conta?</span>
      <Button type="link" onClick={() => redirect('/login')}>
        Faça login
      </Button>
    </>
  );

  const today = new Date();
  const date = new Date();
  const minimumAge = date.setFullYear(today.getFullYear() - 18);

  const maximumAge = date.setFullYear(today.getFullYear() - 120);

  const disabledDate = (current) =>
    (current && current > minimumAge) || current < maximumAge;

  return (
    <>
      {contextHolder}
      <Container>
        {!isMobile ? (
          <img src="/image/signup.svg" alt="Personal trainer" />
        ) : null}

        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Title>Crie sua conta gratuita</Title>

          <Input
            inputConfig={INPUT_FIRST_NAME}
            rules={[getRuleField(INPUT_FIRST_NAME)]}
          />

          <Input
            inputConfig={INPUT_LAST_NAME}
            rules={[getRuleField(INPUT_LAST_NAME)]}
          />

          <DatePicker
            datePickerConfig={DATE_PICKER_BIRTH_DATE}
            rules={[getRuleField(DATE_PICKER_BIRTH_DATE)]}
            disabledDate={disabledDate}
          />

          <InputMasked
            inputMaskedConfig={INPUT_CPF}
            rules={[getRuleField(INPUT_CPF)]}
          />

          <Input
            inputConfig={{
              ...INPUT_EMAIL,
              tooltip: {
                title:
                  'Insira seu endereço de e-mail abaixo para garantir acesso total à sua conta e receber atualizações importantes. E lembre-se este campo não pode ser modificado posteriormente.',
              },
            }}
            rules={[
              getRuleField(INPUT_EMAIL),
              {
                type: 'email',
                message: 'O e-mail não é um e-mail válido',
              },
            ]}
          />

          <TooltipContainerCellphone>
            <InputMasked
              inputMaskedConfig={INPUT_NUMBER_PHONE}
              rules={[getRuleField(INPUT_NUMBER_PHONE)]}
            />

            <Tooltip title="Este número será disponibilizado como meio de contato para o profissional.">
              <BsQuestionCircle />
            </Tooltip>
          </TooltipContainerCellphone>

          <Input
            inputConfig={INPUT_WEIGHT}
            rules={[
              getRuleField(INPUT_WEIGHT),
              { validator: validateOnlyNumbers },
            ]}
          />

          <Input
            inputConfig={INPUT_HEIGHT}
            rules={[
              getRuleField(INPUT_HEIGHT),
              { validator: validateOnlyNumbers },
            ]}
          />

          <Textarea
            textareaConfig={TEXTAREA_RESTRICTION}
            rules={[getRuleField(TEXTAREA_RESTRICTION)]}
          />

          <Input
            inputConfig={INPUT_PASSWORD}
            hasFeedback
            rules={[
              getRuleField(INPUT_PASSWORD),
              {
                validator(_, value) {
                  if (passwordRegex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'A senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número, 1 caractere especial e ter no mínimo 6 caracteres.'
                    )
                  );
                },
              },
            ]}
          />

          <Input
            inputConfig={INPUT_CONFIRM_PASSWORD}
            hasFeedback
            rules={[
              getRuleField(INPUT_CONFIRM_PASSWORD),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('A confirmação de senha não confere.')
                  );
                },
              }),
            ]}
          />

          <Button
            type="primary"
            className="btn-sign-up"
            htmlType="submit"
            loading={loading}
          >
            Criar conta
          </Button>

          <Terms userType="cliente" />
        </Form>

        {isMobile ? (
          <>
            <Divider />
            <ContainerSignIn>
              <span>Já tem uma conta?</span>
              <Button
                type="link"
                className="btn-terms"
                onClick={() => redirect('/login')}
              >
                Faça login
              </Button>
            </ContainerSignIn>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default SignUpClient;
