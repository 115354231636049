import { notification } from 'antd';

const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const showNotification = (type, title, description, time) => {
    return api[type]({
      message: title,
      description,
      duration: time || 5,
    });
  };

  return {
    showNotification,
    contextHolder,
  };
};

export default useNotification;
