import removeCPFCharacter from './removeCPFCharacter';
import removePhoneCharacter from './removePhoneCharacter';
import removeZipCodeCharacter from './removeZipCodeCharacter';

const personalTrainerRegistryTreated = (data) => {
  const dataTreated = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data?.email,
    registration_number: removeCPFCharacter(data?.registrationNumber),
    phone: removePhoneCharacter(data?.phone),
    birth_date: data?.birthDate,
    nickname: data?.nickname,
    password: data?.password,
    zip_code: removeZipCodeCharacter(data?.zipCode),
    about_class: data?.aboutClass,
    skills: data?.skills,
    phone_number: removePhoneCharacter(data?.phone),
    target_market: data?.targetMarket,
    work_locations: data?.workLocations,
    // work_schedules: data?.workSchedules,
    payment_methods: data?.paymentMethods,
    // class_in_person_price_in_cents: data?.,
    // class_online_price_in_cents: data?.,
    class_modality: [
      data?.classPerson ? 'in_person' : null,
      data?.classOnline ? 'online' : null,
    ],
    // class_modality: data?.paymentMethods,
    // educations: data?.paymentMethods,
    // experiences: data?.paymentMethods,
  };

  return dataTreated;
};

export default personalTrainerRegistryTreated;
