import React from 'react';

import Checkbox from '../../elements/Checkbox';
import Button from '../../elements/Button';

import { Container } from './styles';

const Terms = ({ userType }) => {
  const handleOpenNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container>
      <Checkbox
        checkboxConfig={{
          children: (
            <span>
              Ao criar uma conta como {userType}, você aceita os{' '}
              <Button
                type="link"
                className="btn-terms"
                onClick={() => handleOpenNewTab('/terms-of-use')}
              >
                termos e condições
              </Button>{' '}
              de uso da Personally e reconhece que leu e entendeu nossa{' '}
              <Button
                type="link"
                className="btn-terms"
                onClick={() => handleOpenNewTab('/privacy-policy')}
              >
                política de privacidade
              </Button>
              .
            </span>
          ),
          name: 'confirmTerms',
          valuePropName: 'checked',
        }}
      />
    </Container>
  );
};

export default Terms;
