import React, { useCallback, useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import { useDispatch, useSelector } from '../../../utility/Context';
import { getAuthLocalStorage } from '../../../utility/localStorage';

import ModalReport from '../../ModalReport';
import Empty from '../../Empty';

import tableSettings from './tableSettings';

import { Container, Title } from './styles';
import { fetchClientsProfessionals } from '../../../ducks/ApplicationDucks/ClientsProfessionals';

const MyProfessionals = () => {
  const { isMobile } = useWindowDimensions();
  const userId = getAuthLocalStorage()?.id;

  const dispatch = useDispatch();

  const clientProfessional = useCallback(() => {
    dispatch(fetchClientsProfessionals(userId));
  }, [dispatch]);

  useEffect(() => {
    clientProfessional();
  }, []);

  const MODALITY = {
    online: 'Online',
    in_person: 'Presencial',
  };

  const TARGET_MARKET = {
    'Não definido': 'Não definido',
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [professionalId, setProfessionalId] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const configModal = {
    open: isModalOpen,
    showModal,
    handleCancel,
    professionalId,
    setProfessionalId,
  };

  const [clientProfessionals, loading] = useSelector(({ application }) => [
    application.clientsProfessionals,
    application.loading.clientProfessionals,
  ]);

  if (!clientProfessionals) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <h3>Carregando...</h3>
      </Spin>
    );
  }

  return (
    <Container>
      <div className="container-table">
        <Title>Meus profissionais</Title>

        <Table
          columns={tableSettings(
            isMobile,
            userId,
            clientProfessional,
            configModal
          )}
          dataSource={clientProfessionals}
          locale={{
            emptyText: <Empty text="Não há profissionais" />,
          }}
        />
      </div>

      <ModalReport configModal={configModal} />
    </Container>
  );
};

export default MyProfessionals;
