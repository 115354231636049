import { parseGramsToKg } from './parseKgToGrams';

const convertCustomerData = (data) => {
  if (!data) {
    return null;
  }

  return {
    firstName: data.first_name,
    lastName: data.last_name,
    cpf: data.registration_number,
    birthDate: data.birth_date,
    email: data.email,
    avatar: data.avatar,
    restriction: data.restriction,
    phone: data.phone_number,
    zipCode: data.zip_code,
    weight: parseGramsToKg(data.last_weight),
    bodyFat: data.last_body_fat,
    height: data.last_height,
    customerObjectives: data.objectives,
  };
};

export default convertCustomerData;
