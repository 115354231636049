import styled, { createGlobalStyle } from 'styled-components';

// import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
  MEDIUM_RED_COLOR,
  SHADOW_COLOR,
} from '../../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'container-mobile',
})`
  &.container-mobile {
    padding: 0px 40px;
    margin-bottom: 50px;

    & .user-cards-mobile {
      display: flex;
      gap: 24px;
    }

    & .fullname__mobile {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-bottom: 8px;
    }

    & .nickname__mobile {
      color: #767676;
    }

    & .enable-profile__mobile {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    & .info-user__mobile {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      flex-direction: column;

      & .more-info {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }

    & .about-class__mobile {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    & .tag__container__mobile {
      display: flex;
      flex-direction: column;
      gap: 12px;

      > div {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
  }
`;

export default { Container };
