export const CALL_BODY_FAT = '@user/CALL_BODY_FAT';
export const BODY_FAT_FAILED = '@user/BODY_FAT_FAILED;';
export const BODY_FAT_SUCCESS = '@user/BODY_FAT_SUCCESS';

export const callBodyFat = (userId) => ({
  type: CALL_BODY_FAT,
  userId,
});

export const bodyFatFailed = (error) => ({
  type: BODY_FAT_FAILED,
  error,
});

export const bodyFatSuccess = (data) => ({
  type: BODY_FAT_SUCCESS,
  data,
});

export const onCallBodyFat = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    bodyFat: true,
  },
  errors: {
    ...state.errors,
    bodyFat: null,
  },
});

export const onBodyFatFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    bodyFat: false,
  },
  errors: {
    ...state.errors,
    bodyFat: error,
  },
});

export const onBodyFatSuccess = (state, { data }) => ({
  ...state,
  loading: {
    ...state.loading,
    bodyFat: false,
  },
  errors: {
    ...state.errors,
    bodyFat: null,
  },
  data: {
    ...state.data,
    bodyFat: data,
  },
});
