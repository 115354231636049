import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import moment from 'moment';

import Button from '../../../../elements/Button';

import Empty from '../../../Empty';

import { useDispatch, useSelector } from '../../../../utility/Context';
import { getAuthLocalStorage } from '../../../../utility/localStorage';

import { callAvailability } from '../../../../ducks/ApplicationDucks/Availability';

import { Container } from './styles';
import Modal from './Modal';

const Availability = ({ userData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState(false);

  const userType = getAuthLocalStorage()?.userType;

  const { nickname } = userData || {};

  const dispatch = useDispatch();

  const fetchAvailability = useCallback(() => {
    dispatch(callAvailability(nickname));
  }, [dispatch]);

  useEffect(() => {
    fetchAvailability();
  }, []);

  useEffect(() => {
    dayjs.locale('pt-br');
  }, []);

  const showModal = (data) => {
    setDataModal(data);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [availability, loading] = useSelector(({ user, application }) => [
    application.availability,
    application.loading.availability,
  ]);

  if (!Object.keys(availability).length) {
    return <Empty text="A agenda não está aberta no momento..." />;
  }

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
    >
      <Container>
        <div className="custom-calendar">
          {Object.keys(availability).map((date, index) => (
            <div key={index}>
              <div className="custom-header-calendar">
                <p>{dayjs(date, 'YYYY/MM/DD').format('dddd')}</p>
                <p>{dayjs(date, 'YYYY/MM/DD').format('DD/MM/YYYY')}</p>
              </div>

              {availability[date].map((slot) => (
                <div key={slot.time} className="custom-main-calendar">
                  <Button
                    onClick={() => showModal(slot)}
                    disabled={!slot.available}
                  >
                    {moment(slot.time).format('HH:mm')}
                  </Button>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Container>

      <Modal
        open={isModalOpen}
        handleCancel={handleCancel}
        data={dataModal}
        userData={userData}
        userType={userType}
      />
    </Spin>
  );
};

export default Availability;
