import React, { useCallback } from 'react';
import { Divider, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import Input from '../../elements/Input';
import Button from '../../elements/Button';

import { INPUT_EMAIL, INPUT_PASSWORD } from '../../defaults/RegisterUserFields';

import { useDispatch, useSelector } from '../../utility/Context';

import { callLogin } from '../../ducks/UserDucks/Login';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import {
  Container,
  Form,
  Title,
  ContainerSignIn,
  ContainerForgotPassword,
  ContainerNewAccount,
} from './styles';

const SignInClient = () => {
  const { isMobile } = useWindowDimensions();

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const loading = useSelector(({ user }) => user.loading.login);

  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();

  const login = useCallback(
    (values) => dispatch(callLogin(values)),
    [dispatch]
  );

  const onFinish = (values) => {
    const { email, password } = values;
    if (!email || !password) {
      api.warning(
        {
          message: 'Atenção',
          description: 'Credenciais inválidas',
        },
        1000
      );

      return;
    }

    login(values);
  };

  const onFinishFailed = (errors) => {
    api.warning(
      {
        message: 'Atenção',
        description: 'Não foi possivel realizar o login',
      },
      1000
    );
  };

  return (
    <>
      {contextHolder}
      <Container>
        {!isMobile ? (
          <img src="/image/Sign-in-amico.svg" alt="Personal trainer" />
        ) : null}

        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Title>Entrar</Title>

          <Input inputConfig={INPUT_EMAIL} />

          <Input inputConfig={INPUT_PASSWORD} />

          <Button
            type="primary"
            className="btn-sign-up"
            htmlType="submit"
            loading={loading}
          >
            Entrar
          </Button>

          <ContainerForgotPassword>
            <Button type="link" onClick={() => redirect('/reset-password')}>
              Esqueceu sua senha?
            </Button>
          </ContainerForgotPassword>

          <Divider />

          <ContainerNewAccount>
            <span>Ainda não tem uma conta? </span>
            <Button type="link" onClick={() => redirect('/sign-up-client')}>
              Inscreva-se
            </Button>
          </ContainerNewAccount>
        </Form>
      </Container>
    </>
  );
};

export default SignInClient;
