export const CALL_SEND_EMAIL = '@application/CALL_SEND_EMAIL';
export const SEND_EMAIL_FAILED = '@application/SEND_EMAIL_FAILED';
export const SEND_EMAIL_SUCCESS = '@application/SEND_EMAIL_SUCCESS';

export const callSendEmail = ({ content, userId }) => ({
  type: CALL_SEND_EMAIL,
  content,
  userId,
});

export const sendEmailFailed = (error) => ({
  type: SEND_EMAIL_FAILED,
  error,
});

export const sendEmailSuccess = () => ({
  type: SEND_EMAIL_SUCCESS,
});

export const onCallSendEmail = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    sendEmail: true,
  },
  errors: {
    ...state.errors,
    sendEmail: null,
  },
});

export const onSendEmailFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    sendEmail: false,
  },
  errors: {
    ...state.errors,
    sendEmail: error,
  },
});

export const onSendEmailSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    sendEmail: false,
  },
  errors: {
    ...state.errors,
    sendEmail: null,
  },
});
