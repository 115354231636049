import styled from 'styled-components';

import { MOBILE_WIDTH, TABLET_WIDTH } from '../../defaults/Breakpoints';

export const Container = styled.div.attrs({
  className: 'container-empty',
})`
  &.container-empty {
    margin: 24px 0;

    & .ant-empty-image {
      height: 350px;

      @media (max-width: ${TABLET_WIDTH}px) {
        height: 250px;
      }
    }

    & .ant-empty-description span {
      color: #6e6e6e;
      font-weight: 700;
      font-size: 1rem;
    }
  }
`;

export default {
  Container,
};
