const brazilianNeighborhoods = [
  { value: 'Lapa', label: 'Lapa' },
  { value: 'Ipiranga', label: 'Ipiranga' },
  { value: 'Liberdade', label: 'Liberdade' },
  { value: 'Mooca', label: 'Mooca' },
  { value: 'Pinheiros', label: 'Pinheiros' },
  { value: 'Sé', label: 'Sé' },
  { value: 'Barra Funda', label: 'Barra Funda' },
  { value: 'Bom Retiro', label: 'Bom Retiro' },
  { value: 'Vila Mariana', label: 'Vila Mariana' },
  { value: 'Luz', label: 'Luz' },
  { value: 'Avenida Paulista', label: 'Avenida Paulista' },
  { value: 'Pacaembu', label: 'Pacaembu' },
  { value: 'Santana', label: 'Santana' },
  { value: 'Jardins', label: 'Jardins' },
  { value: 'Vila Madalena', label: 'Vila Madalena' },
  { value: 'Liberdade', label: 'Liberdade' },
  { value: 'Itaim Bibi', label: 'Itaim Bibi' },
  { value: 'Bela Vista', label: 'Bela Vista' },
  { value: 'Santo Amaro', label: 'Santo Amaro' },
  { value: 'Perdizes', label: 'Perdizes' },
  { value: 'Campo Belo', label: 'Campo Belo' },
];

export default brazilianNeighborhoods;
