import React from 'react';
import PropTypes from 'prop-types';
import { Input as InputAntd } from 'antd';

import { FormItem } from './styles';

const { TextArea: TextAreaAntd } = InputAntd;

const Textarea = ({ textareaConfig, hasFeedback, rules }) => {
  const {
    placeholder,
    maxLength,
    minLength,
    showCount,
    autoSize,
    className,
    labelcol,
    wrapperCol,
    ...rest
  } = textareaConfig;

  return (
    <FormItem {...rest} hasFeedback={hasFeedback} rules={rules}>
      <TextAreaAntd
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        showCount={showCount}
        autoSize={autoSize}
        className={className}
        labelcol={labelcol}
        wrapperCol={wrapperCol}
      />
    </FormItem>
  );
};

Textarea.defaultProps = {
  hasFeedback: false,
};

Textarea.propTypes = {
  textareaConfig: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    showCount: PropTypes.bool,
    style: PropTypes.shape({
      marginBottom: PropTypes.string,
    }),
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default Textarea;
