const formatCurrency = (value) => {
  if (!value) {
    return null;
  }

  const parseIntCurrency = parseInt(value, 10);

  const format = parseIntCurrency?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return format;
};

export default formatCurrency;
