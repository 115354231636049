import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxAntd } from 'antd';

import { FormItem } from './styles';

const Checkbox = ({ checkboxConfig, hasFeedback, rules, onChange }) => {
  const { children, ...rest } = checkboxConfig;

  return (
    <FormItem {...rest} hasFeedback={hasFeedback} rules={rules}>
      <CheckboxAntd onChange={onChange}>{children}</CheckboxAntd>
    </FormItem>
  );
};

Checkbox.defaultProps = {
  hasFeedback: false,
};

Checkbox.propTypes = {
  checkboxConfig: PropTypes.shape({
    name: PropTypes.string,
    valuePropName: PropTypes.string,
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default Checkbox;
