const validateOnlyNumbers = (_, value) => {
  return new Promise((resolve, reject) => {
    if (value && !/^\d+$/.test(value)) {
      reject(new Error('Por favor, insira apenas números.'));
    } else {
      resolve();
    }
  });
};

export default validateOnlyNumbers;
