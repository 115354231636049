import styled from 'styled-components';

export const ContainerSwitch = styled.div.attrs({
  className: 'switch__container',
})`
  &.switch__container {
    display: flex;
    align-items: center;

    & .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start;
    }
  }
`;

export const ContainerTitleWorkShedules = styled.div.attrs({
  className: 'container-title-work-shedules',
})`
  &.container-title-work-shedules {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;
  }
`;

export const TooltipContainerCellphone = styled.div.attrs({
  className: 'container-tooltip',
})`
  &.container-tooltip {
    display: flex;
    width: 100%;
  }
`;

export default {
  ContainerSwitch,
  TooltipContainerCellphone,
};
