export const INPUT_CURRENT_PASSWORD = {
  label: 'Senha atual',
  placeholder: 'Digite sua senha atual',
  name: 'oldPassword',
  type: 'password',
  isRequired: true,
};

export const INPUT_NEW_PASSWORD = {
  label: 'Nova senha',
  placeholder: 'Digite a nova senha',
  name: 'newPassword',
  type: 'password',
  isRequired: true,
};

export const INPUT_CONFIRM_NEW_PASSWORD = {
  label: 'Confirme a nova senha',
  placeholder: 'Digite a confirmação de senha',
  name: 'confirmNewPassword',
  type: 'password',
  isRequired: true,
};

export default {
  INPUT_CURRENT_PASSWORD,
  INPUT_NEW_PASSWORD,
  INPUT_CONFIRM_NEW_PASSWORD,
};
