const targetMarket = [
  {value: 'Adolescentes', label: 'Adolescentes'},
  {value: 'Adultos', label: 'Adultos'},
  {value: 'Gestantes', label: 'Gestantes'},
  {value: 'Idosos', label: 'Idosos'},
  {value: 'PCD', label: 'PCD'},
  {value: 'Atletas', label: 'Atletas'},
  {value: 'Reabilitação', label: 'Reabilitação'},
  {value: 'Aulas em Grupo', label: 'Aulas em grupo'},
  {value: 'Aulas individuais', label: 'Aulas individuais'}
];

export default targetMarket;
