import styled from 'styled-components';

import { PRIMARY_COLOR, WHITE_COLOR } from '../../../defaults/Colors';

export const ContainerDesktop = styled.header.attrs({
  className: 'header-desktop-container',
})`
  &.header-desktop-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    background: ${WHITE_COLOR};
    flex-wrap: wrap;
    margin-bottom: 40px;

    & img {
      height: 80px;
      width: 80px;
    }
  }
`;

export const ContainerMobile = styled.header.attrs({
  className: 'header-mobile-container',
})`
  &.header-mobile-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    align-items: center;
    background: ${WHITE_COLOR};

    & svg {
      font-size: 2.5rem;
      color: ${PRIMARY_COLOR};
    }
  }
`;

export const NavMenuContainer = styled.div.attrs({
  className: 'header__nav-menu-container',
})`
  &.header__nav-menu-container {
    align-items: baseline;
    display: flex;
    gap: 32px;

    & button {
      color: ${PRIMARY_COLOR};
      font-size: 16px;
      font-weight: bold;

      & :hover {
        color: #8f58c4;
      }

      &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active,
      &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
        color: #8f58c4;
      }
    }
  }
`;

export const SessionContainer = styled.div.attrs({
  className: 'header__session-container',
})`
  &.header__session-container {
    display: flex;
    gap: 12px;
    align-items: center;

    & .session-container__btn-login {
      border-color: ${PRIMARY_COLOR};
      color: ${PRIMARY_COLOR};
    }
  }
`;

export default {
  ContainerDesktop,
  ContainerMobile,
  NavMenuContainer,
  SessionContainer,
};
