export const CALL_USER_INFO = '@user/CALL_USER_INFO';
export const USER_INFO_FAILED = '@user/USER_INFO_FAILED';
export const USER_INFO_SUCCESS = '@user/USER_INFO_SUCCESS';

export const callUserInfo = (token) => ({
  type: CALL_USER_INFO,
  token,
});

export const userInfoFailed = (error) => ({
  type: USER_INFO_FAILED,
  error,
});

export const userInfoSuccess = ({ token }) => ({
  type: USER_INFO_SUCCESS,
  token,
});

export const onCallUserInfo = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    userInfo: true,
  },
  errors: {
    ...state.errors,
    userInfo: null,
  },
});

export const onlUserInfoFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    userInfo: false,
  },
  errors: {
    ...state.errors,
    userInfo: error,
  },
});

export const onUserInfoSuccess = (state, { token }) => ({
  ...state,
  loading: {
    ...state.loading,
    userInfo: false,
  },
  errors: {
    ...state.errors,
    userInfo: null,
  },
  auth: {
    ...state.auth,
    ...token,
  },
});
