import React from 'react';

import {Divider} from "antd";
import SearchPersonal from '../../../components/SearchPersonal';

import {
  MainStyled,
  ContainerOption,
} from '../styles';
import Button from "../../../elements/Button";

const Main = () => {
  return (
    <MainStyled>
      <SearchPersonal/>
      <Divider />
      <div className="container-options">
        <ContainerOption direct="flex-start">
          <img
            src="/image/Filter-amico.svg"
            alt="Personagem realizando busca no celular"
          />
          <div>
            <h2>Explore.</h2>
            <p>Acesse o nosso catálogo e consulte livremente os perfis de profissionais de acordo com os seus critérios.</p>
          </div>
        </ContainerOption>

        <ContainerOption direct="flex-end">
          <div>
            <h2>Agende.</h2>
            <p>Encontrou seu profissional ideal? Consulte a disponibilidade em seu perfil digital e agende de maneira 100% digital. Sem burocracias.</p>
          </div>
          <img
            src="/image/Online%20calendar-amico.svg"
            alt="Olhando a agenda"
          />
        </ContainerOption>

        <ContainerOption direct="flex-start">
          <img src="/image/Innovation-pana.svg" alt="Personagem controlando pelo computador"/>
          <div>
            <h2>Tenha o controle na palma da mão.</h2>
            <p>Acompanhe sua evolução, gerencie suas aulas, explore e agende com facilidade. Tudo na palma da sua mão.</p>
          </div>
        </ContainerOption>
      </div>
    </MainStyled>
  );
};

export default Main;
