import React, { useState } from 'react';
import { Popover, Tag } from 'antd';

import { STATUS_CLASS } from '../../defaults/StatusClass';

const Status = ({ status }) => {
  const { label, color, popover } = STATUS_CLASS[status];

  return (
    <Popover content={popover}>
      <Tag color={color} key={status}>
        {label.toUpperCase()}
      </Tag>
    </Popover>
  );
};

export default Status;
