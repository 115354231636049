const skillsPersonal = [
  { value: 'Yoga', label: 'Yoga' },
  { value: 'Alongamento', label: 'Alongamento' },
  { value: 'Musculação', label: 'Musculação' },
  { value: 'Treinamento de resistência', label: 'Treinamento de resistência' },
  { value: 'Treinamento funcional', label: 'Treinamento funcional' },
  {
    value: 'Treinamento de alta intensidade (HIIT)',
    label: 'Treinamento de alta intensidade (HIIT)',
  },
  {
    value: 'Treinamento de peso corporal',
    label: 'Treinamento de peso corporal',
  },
  { value: 'Treinamento de força', label: 'Treinamento de força' },
  { value: 'Treinamento cardiovascular', label: 'Treinamento cardiovascular' },
  {
    value: 'Treinamento de flexibilidade',
    label: 'Treinamento de flexibilidade',
  },
  { value: 'Pilates', label: 'Pilates' },
  {
    value: 'Treinamento de habilidades esportivas específicas',
    label: 'Treinamento de habilidades esportivas específicas',
  },
  {
    value: 'Treinamento para perda de peso',
    label: 'Treinamento para perda de peso',
  },
  {
    value: 'Treinamento para ganho de massa muscular',
    label: 'Treinamento para ganho de massa muscular',
  },
  { value: 'Treinamento para idosos', label: 'Treinamento para idosos' },
  {
    value: 'Treinamento para crianças e adolescentes',
    label: 'Treinamento para crianças e adolescentes',
  },
  { value: 'Treinamento para gestantes', label: 'Treinamento para gestantes' },
  {
    value: 'Treinamento pós-reabilitação',
    label: 'Treinamento pós-reabilitação',
  },
  {
    value: 'Treinamento de condicionamento atlético',
    label: 'Treinamento de condicionamento atlético',
  },
  {
    value: 'Treinamento de condicionamento para artistas performáticos',
    label: 'Treinamento de condicionamento para artistas performáticos',
  },
];

export default skillsPersonal;
