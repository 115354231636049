export const CALL_LOGIN = '@user/CALL_LOGIN';
export const LOGIN_FAILED = '@user/LOGIN_FAILED';
export const LOGIN_SUCCESS = '@user/LOGIN_SUCCESS';

export const callLogin = (data) => ({
  type: CALL_LOGIN,
  data,
});

export const loginFailed = (error) => ({
  type: LOGIN_FAILED,
  error,
});

export const loginSuccess = (token) => ({
  type: LOGIN_SUCCESS,
  token,
});

export const onCallLogin = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    login: true,
  },
  errors: {
    ...state.errors,
    login: null,
  },
});

export const onLoginFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    login: false,
  },
  errors: {
    ...state.errors,
    login: error,
  },
});

export const onLoginSuccess = (state, { token }) => ({
  ...state,
  loading: {
    ...state.loading,
    login: false,
  },
  errors: {
    ...state.errors,
    login: null,
  },
  auth: {
    ...state.auth,
    token,
  },
});
