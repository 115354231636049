export const CALL_BOOK_CLASS = '@application/CALL_BOOK_CLASS';
export const BOOK_CLASS_FAILED = '@application/BOOK_CLASS_FAILED';
export const BOOK_CLASS_SUCCESS = '@application/BOOK_CLASS_SUCCESS';

export const callBookClass = ({
  clientId,
  professionalId,
  classModality,
  startDate,
  typeClass,
}) => ({
  type: CALL_BOOK_CLASS,
  clientId,
  professionalId,
  classModality,
  startDate,
  typeClass,
});

export const bookClassFailed = (error) => ({
  type: BOOK_CLASS_FAILED,
  error,
});

export const bookClassSuccess = (data) => ({
  type: BOOK_CLASS_SUCCESS,
  data,
});

export const onCallBookClass = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    bookCalss: true,
  },
  errors: {
    ...state.errors,
    bookCalss: null,
  },
});

export const onBookClassFailed = (state, { error }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    bookCalss: false,
  },
  errors: {
    ...state.errors,
    bookCalss: error,
  },
});

export const onBookClassSuccess = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    bookCalss: false,
  },
  errors: {
    ...state.errors,
    bookCalss: null,
  },
});
