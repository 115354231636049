import styled from 'styled-components';
import { Form } from 'antd';

import { PRIMARY_COLOR } from '../../defaults/Colors';

export const FormItem = styled(Form.Item).attrs({
  className: 'form__item',
})`
  &.form__item {
    width: 100%;

    .ant-picker {
      width: 100%;
    }
  }
`;

export default { FormItem };
