import React, { useState, useCallback, useEffect } from 'react';
import { Collapse } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';

import { useDispatch, useSelector } from '../../../../../utility/Context';

import {
  addPersonalEducation,
  removePersonalEducation,
} from '../../../../../ducks/UserDucks/PersonalEducation';

import Button from '../../../../../elements/Button';

import Modal from './Modal';

import {
  EducationContainer,
  EducationExpand,
  EducationIconContainer,
  EducationTitle,
  EducationDescription,
} from './styles';

const Education = ({ userData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [personalEducation] = useSelector(({ user }) => [
    user.infoPersonal.education,
  ]);

  const dispatch = useDispatch();

  const addEducation = useCallback(
    (body) => {
      dispatch(addPersonalEducation(body));
    },
    [dispatch]
  );

  useEffect(() => {
    const userDataEducations = userData?.educations;

    if (userDataEducations?.length && !personalEducation.length) {
      addEducation(userDataEducations);
    }
  }, []);

  const removeEducation = useCallback(
    (index) => {
      dispatch(removePersonalEducation(index));
    },
    [dispatch]
  );

  const genExtra = (index) => (
    <EducationIconContainer>
      <Button
        type="link"
        icon={<AiOutlineClose />}
        onClick={(event) => {
          event.stopPropagation();
          removeEducation(index);
        }}
      />
    </EducationIconContainer>
  );

  const items = personalEducation?.map((education, index) => ({
    key: index,
    label: education?.institution,
    children: (
      <EducationExpand>
        <div>
          <EducationTitle>Instituição de ensino:</EducationTitle>
          <EducationDescription>{education?.institution}</EducationDescription>
        </div>
        <div>
          <EducationTitle>Grau:</EducationTitle>
          <EducationDescription>{education?.degree}</EducationDescription>
        </div>
        <div>
          <EducationTitle>Data de início:</EducationTitle>
          <EducationDescription>{education?.startDate}</EducationDescription>
        </div>
        <div>
          <EducationTitle>Data de término:</EducationTitle>
          <EducationDescription>
            {education?.endDate || 'Até o momento'}
          </EducationDescription>
        </div>
      </EducationExpand>
    ),
    extra: genExtra(index),
  }));

  return (
    <EducationContainer>
      <header>
        <h3>Formação acadêmica</h3>
        <Button
          type="default"
          className="btn-add-education"
          loading={false}
          onClick={showModal}
        >
          Adicionar
        </Button>
      </header>
      <Collapse defaultActiveKey={['0']} items={items} />
      <Modal open={isModalOpen} handleCancel={handleCancel} />
    </EducationContainer>
  );
};

export default Education;
