const convertNotification = (data) => {
  if (!data?.length) {
    return null;
  }

  return data.map((item) => ({
    title: item.title,
    description: item.content,
    seen: item.seen,
  }));
};

export default convertNotification;

// [
//   {
//       "from_id": "",
//       "from_name": "",
//       "from_email": "",
//       "from_user_type": "",
//       "to_id": "9a690b95-f685-46ea-bac0-1d85b4f07fb3",
//       "to_name": "Gabryelle ",
//       "to_email": "gabryelle-gaby@hotmail.com",
//       "to_user_type": "client",
//       "type": "new_account",
//       "created_at": "2023-10-30T13:56:24.892079Z"
//   }
// ]
