export const CALL_SIGN_UP_PERSONAL = '@user/CALL_SIGN_UP_PERSONAL';
export const SIGN_UP_PERSONAL_FAILED = '@user/SIGN_UP_PERSONAL_FAILED';
export const SIGN_UP_PERSONAL_SUCCESS = '@user/IGN_UP_CLIENT_SUCCESS';

export const callSignUpPersonal = (data) => ({
  type: CALL_SIGN_UP_PERSONAL,
  data,
});

export const signUpPersonalFailed = (error) => ({
  type: SIGN_UP_PERSONAL_FAILED,
  error,
});

export const signUpPersonalSuccess = () => ({
  type: SIGN_UP_PERSONAL_SUCCESS,
});

export const onCallSignUpPersonal = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    signUp: { ...state.loading.signUp, personal: true },
  },
  errors: {
    ...state.errors,
    signUp: { ...state.errors.signUp, personal: null },
  },
});

export const onSignUpPersonalFailed = (state, { error }) => ({
  ...state,
  loading: {
    ...state.loading,
    signUp: { ...state.loading.signUp, personal: false },
  },
  errors: {
    ...state.errors,
    signUp: { ...state.errors.signUp, personal: error },
  },
});

export const onSignUpPersonalSuccess = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    signUp: { ...state.loading.signUp, personal: false },
  },
  errors: {
    ...state.errors,
    signUp: { ...state.errors.signUp, personal: null },
  },
});
