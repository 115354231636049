export const convertCustomerDataBackend = (data) => {
  if (!data) {
    return null;
  }

  return {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    phone_number: data.phone,
    registration_number: data.cpf,
    birth_date: data.birthDate,
    weight_in_grams: data.weight,
    height_in_centimeters: data.height,
    restriction: data.restriction,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
  };
};

export const convertCustomerUpdateBackend = (data) => {
  if (!data) {
    return null;
  }

  return {
    first_name: data.firstName,
    last_name: data.lastName,
    birth_date: data.birthDate,
    phone_number: data.phone,
    zip_code: data.zipCode,
    restriction: data.restriction,
    objectives: data.customerObjectives,
  };
};

export default { convertCustomerDataBackend, convertCustomerUpdateBackend };
