import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Form, Modal as ModalAntd, notification } from 'antd';
import moment from 'moment';

// import Textarea from '../../../elements/Textarea';
import Button from '../../../../elements/Button';
import Input from '../../../../elements/Input';
import Select from '../../../../elements/Select';

import { useDispatch, useSelector } from '../../../../utility/Context';
import { getAuthLocalStorage } from '../../../../utility/localStorage';
import { getRuleField } from '../../../../utility/RuleField';

import { callBookClass } from '../../../../ducks/ApplicationDucks/BookClass';

import { USER_TYPE_PROFESSIONAL } from '../../../../defaults/userType';

import { ContainerButton, ContainerModal, ContentForm } from './styles';

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

const Modal = ({ open, handleCancel, data, userData, userType }) => {
  const userId = getAuthLocalStorage()?.id;

  const { classModality, skills, id } = userData;

  const [form] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const message = (type, title, description, time) => {
    return api[type](
      {
        message: title,
        description,
      },
      time || 1000
    );
  };

  const dispatch = useDispatch();

  const bookCalss = useCallback(
    (modality, type) => {
      dispatch(
        callBookClass({
          clientId: userId,
          professionalId: id,
          classModality: modality,
          startDate: data.time,
          typeClass: type,
        })
      );
    },
    [dispatch, data]
  );

  const handleReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    const { classModality: modality, type } = values;

    if (!modality || !type) {
      message(
        'warning',
        'Atenção!',
        'É necessário preencher os campos obrigatórios'
      );
      return;
    }

    bookCalss(modality, type);
    handleReset();
    handleCancel();
  };

  const onFinishFailed = () => {
    message('warning', 'Atenção!', 'Não foi possivel marcar a aula');
  };

  const [loading] = useSelector(({ application }) => [
    application.loading.bookCalss,
  ]);

  const [currClassModality, setCurrClassModality] = useState([]);
  const [currSkill, setCurrSkill] = useState([]);

  useEffect(() => {
    const option = classModality?.map((modality) => ({
      value: modality,
      label: MODALITY[modality],
    }));

    setCurrClassModality(option);
  }, []);

  useEffect(() => {
    const option = skills?.map((skill) => ({
      value: skill,
      label: skill,
    }));

    setCurrSkill(option);
  }, []);

  return (
    <ContainerModal>
      {contextHolder}
      <ModalAntd
        title="Preferências da sua aula?"
        footer={null}
        open={open}
        onCancel={() => {
          handleCancel();
          handleReset();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            startDate: moment(data.time).format('HH:mm'),
          }}
        >
          <ContentForm>
            {userType === USER_TYPE_PROFESSIONAL ? (
              <Alert
                message="Não é possível agendar sendo um profissional."
                type="info"
                showIcon
                className="alert"
              />
            ) : null}

            {!userId ? (
              <Alert
                message="Faça login para agendar uma aula com este profissional."
                type="info"
                showIcon
                className="alert"
              />
            ) : null}

            <Input
              inputConfig={{
                label: 'Horário da aula',
                name: 'startDate',
                required: true,
                disabled: true,
              }}
            />

            <Select
              selectConfig={{
                options: currSkill,
                label: 'Categoria da aula',
                placeholder: 'Selecione a categoria',
                required: true,
                name: 'type',
              }}
              rules={[
                getRuleField({
                  options: currSkill,
                  label: 'Categoria da aula',
                  placeholder: 'Selecione a categoria',
                  required: true,
                  name: 'type',
                }),
              ]}
            />

            <Select
              selectConfig={{
                options: currClassModality,
                label: 'Modalidade',
                placeholder: 'Selecione a modalidade',
                required: true,
                name: 'classModality',
              }}
              rules={[
                getRuleField({
                  options: currClassModality,
                  label: 'Modalidade',
                  placeholder: 'Selecione a modalidade',
                  required: true,
                  name: 'classModality',
                }),
              ]}
            />
          </ContentForm>

          <ContainerButton>
            <Button
              key="back"
              onClick={() => {
                handleCancel();
                handleReset();
              }}
              disabled={loading}
            >
              Voltar
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={userType === USER_TYPE_PROFESSIONAL || !userId}
            >
              Marcar
            </Button>
          </ContainerButton>
        </Form>
      </ModalAntd>
    </ContainerModal>
  );
};

export default Modal;
