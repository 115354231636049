import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'home-client__container',
})`
  &.home-client__container {
    & .container-table {
      padding: 0 40px;
    }

    & .menu-table__mobile {
      color: ${PRIMARY_COLOR};
      font-size: 1.5rem;
    }
  }
`;

export const BodyGlobalStyle = createGlobalStyle`
  & .ant-popover-inner {
    max-width: 300px;
  }
`;

export const Title = styled.h3.attrs({
  className: 'sign-up__title',
})`
  &.sign-up__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0px 24px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 24px 0;
    }
  }
`;

export const ExpandedRowTitle = styled.p.attrs({
  className: 'client__expanded-row-title',
})`
  &.client__expanded-row-title {
    color: ${PRIMARY_COLOR};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export const ExpandedRowInfo = styled.p.attrs({
  className: 'client__expanded-row-info',
})`
  &.client__expanded-row-info {
    margin: 0;
  }
`;

export const RateTitle = styled.h3.attrs({
  className: 'client__rate-title',
})`
  &.client__rate-title {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${PRIMARY_COLOR};
  }
`;

export const RateContent = styled.div.attrs({
  className: 'client__rate-class',
})`
  &.client__rate-class {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const ContainerBtnMobile = styled.div.attrs({
  className: 'container-menu__btn-mobile',
})`
  &.container-menu__btn-mobile {
    & button {
      align-items: center;
      display: flex;
    }
  }
`;

export const ExpandedRowMobile = styled.div.attrs({
  className: 'expanded-row-mobile',
})`
  &.expanded-row-mobile {
    display: flex;
    flex-direction: column;

    & div {
      margin-bottom: 12px;

      & .title {
        font-weight: 700;
      }
    }
  }
`;

export const AvatarContainer = styled.div.attrs({
  className: 'avatar-container',
})`
  &.avatar-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const ContainerButtonFooter = styled.div.attrs({
  className: 'container-button-footer',
})`
  &.container-button-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export const ContainerForm = styled.div.attrs({
  className: 'container-form',
})`
  &.container-form {
    margin: 24px 0 50px 0;
  }
`;

export default {
  BodyGlobalStyle,
  Container,
  Title,
  ExpandedRowTitle,
  ExpandedRowInfo,
  RateTitle,
  RateContent,
  ContainerBtnMobile,
  ExpandedRowMobile,
  AvatarContainer,
  ContainerButtonFooter,
  ContainerForm,
};
