import skillsPersonal from './SkillsPersonal';
import brazilianNeighborhoods from './BrazilianNeighborhoods';

export const SELECT_SKILLS = {
  name: 'skills',
  mode: 'multiple',
  options: skillsPersonal,
  placeholder: 'Tipos de aula',
  allowClear: true,
};

export const SELECT_ADDRESS_STATE = {
  placeholder: 'Localidade',
  name: 'workLocations',
  options: brazilianNeighborhoods,
  allowClear: true,
  showSearch: true,
};

export default { SELECT_SKILLS, SELECT_ADDRESS_STATE };
