const paymentMethod = [
  { value: 'Dinheiro', label: 'Dinheiro' },
  { value: 'Cartão de Crédito', label: 'Cartão de Crédito' },
  { value: 'Cartão de Débito', label: 'Cartão de Débito' },
  { value: 'Cheque', label: 'Cheque' },
  { value: 'Boleto Bancário', label: 'Boleto Bancário' },
  { value: 'Pix', label: 'Pix' },
];

export default paymentMethod;
