export const ADD_PERSONAL_EXPERIENCE = '@user/ADD_PERSONAL_EXPERIENCE';
export const REMOVE_PERSONAL_EXPERIENCE = '@user/REMOVE_PERSONAL_EXPERIENCE';

export const addPersonalExperience = (data) => ({
  type: ADD_PERSONAL_EXPERIENCE,
  data,
});

export const removePersonalExperience = (index) => ({
  type: REMOVE_PERSONAL_EXPERIENCE,
  index,
});

export const onAddPersonalExperience = (state, { data }) => ({
  ...state,
  infoPersonal: {
    ...state.infoPersonal,
    experience: [...state.infoPersonal.experience, ...data],
  },
});

export const onRemovePersonalExperience = (state, { index }) => ({
  ...state,
  infoPersonal: {
    ...state.infoPersonal,
    experience: state.infoPersonal.experience.filter((item, i) => i !== index),
  },
});
