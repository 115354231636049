import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'home__container',
})`
  &.home__container {
    & .home__favorite-list {
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        color: ${WHITE_COLOR};
      }
    }

    & .home__menu {
      color: ${PRIMARY_COLOR};
      cursor: pointer;
      font-size: 24px;
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'sign-up__title',
})`
  &.sign-up__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;
export const ContainerSpin = styled.div.attrs({
  className: 'container-sping',
})`
  &.container-sping {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;

export default { Container, Title, ContainerSpin };
