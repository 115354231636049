import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container-work-schedules',
})`
  &.container-work-schedules {
    margin-bottom: 24px;
    width: 100%;

    h3 {
      margin-bottom: 12px;
    }
  }
`;

export const ContainerSwitch = styled.div.attrs({
  className: 'switch__container',
})`
  &.switch__container {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;

    & .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start;
    }

    & .form__item {
      margin: 0;
    }

    & .time-picker-week {
      display: flex;
      gap: 12px;
    }

    & .ant-form-item {
      margin-bottom: 0px;
    }
  }
`;

export default {
  Container,
  ContainerSwitch,
};
