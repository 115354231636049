import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH, TABLET_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../defaults/Colors';

export const Title = styled.h3.attrs({
  className: 'landing-page-client__title',
})`
  &.landing-page-client__title {
    color: ${PRIMARY_COLOR};
    font-size: 40px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const Text = styled.p.attrs({
  className: 'landing-page-client__text',
})`
  &.landing-page-client__text {
    text-wrap: wrap;
    text-align: start;

    display: flex;
    align-items: center;

    & .ant-statistic-content {
      margin: 0px 3px;
      font-size: 15px;
      font-family: 'Poppins';
      font-weight: 600;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }
`;

export const TextSpan = styled.span.attrs({
  className: 'landing-page-client__text',
})`
  &.landing-page-client__text {
    text-wrap: wrap;
    text-align: start;

    display: flex;
    align-items: center;

    & .ant-statistic-content {
      margin: 0px 3px;
      font-size: 15px;
      font-family: 'Poppins';
      font-weight: 600;
    }

    & .text-dinamic-count {
      display: flex;
    }

    @media (max-width: ${TABLET_WIDTH}px) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: flex-start;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  }
`;

export const ContainerSearch = styled.div.attrs({
  className: 'landing-page-client__container-search',
})`
  &.landing-page-client__container-search {
    display: flex;
    justify-content: space-between;
    background: ${BACKGROUND_BODY};
    align-items: center;

    @media (max-width: ${MOBILE_WIDTH}px) {
      padding: 0 24px;
    }

    & img {
      width: 400px;
      height: 400px;

      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 300px;
        height: 300px;
      }
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
      gap: 24px;
    }
  }
`;

export const ContainerSearchPersonal = styled.div.attrs({
  className: 'landing-page-client__container-search-personal',
})`
  &.landing-page-client__container-search-personal {
    & .search-personal-form {
      display: flex;
      gap: 12px;
      margin-top: 24px;

      & .search-container {
        gap: 12px;
        display: flex;
        align-items: flex-start;
        flex: 1;

        & svg {
          font-size: 16px;
        }
      }

      @media (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
      }
    }

    & .text-dinamic {
      display: flex;
      align-items: baseline;
      flex-direction: column;

      & .ant-statistic {
        font-weight: 500;
      }

      & .last-description {
        margin-right: 5px;
      }
    }
  }
`;

export const ContainerSearchImg = styled.div.attrs({
  className: 'landing-page-client__container-search-img',
})`
  &.landing-page-client__container-search-img {
    img {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 780px) {
      display: none;
    }
  }
`;

export default {
  Title,
  Text,
  ContainerSearch,
  ContainerSearchPersonal,
  ContainerSearchImg,
};
