import React, { useCallback, useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment/moment';

import { useDispatch, useSelector } from '../../../utility/Context';
import { getAuthLocalStorage } from '../../../utility/localStorage';

import Button from '../../../elements/Button';

import useWindowDimensions from '../../../hooks/useWindowDimensions';

import { fetchClientAppointment } from '../../../ducks/ApplicationDucks/FetchClientAppointments';

import { PRIMARY_COLOR } from '../../../defaults/Colors';

import Empty from '../../Empty';

import Modal from './Modal';
import tableSettings from './tableSettings';
import 'dayjs/locale/pt-br';

import { Container, Title, ExpandedRowTitle } from './styles';

const Appointments = () => {
  const { isMobile } = useWindowDimensions();
  const userId = getAuthLocalStorage()?.id;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();

  const clientAppointment = useCallback(() => {
    dispatch(fetchClientAppointment(userId));
  }, [dispatch]);

  useEffect(() => {
    clientAppointment();
  }, []);

  const [appointments, loading] = useSelector(({ application }) => [
    application.appointments.all,
    application.loading.clientAppointment,
  ]);

  const expandedRowRender = (_appointments) => (
    <div>
      <ExpandedRowTitle>
        Profissional:{' '}
        <span style={{ color: 'black' }}>
          {_appointments.professional.firstName}{' '}
          {_appointments.professional.lastName}
        </span>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Instagram:{' '}
        <a
          href={`https://instagram.com/${_appointments.professional.instagram}`}
          target="_blank"
          rel="noreferrer"
        >
          {_appointments.professional.instagram}
        </a>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Contato:{' '}
        <span style={{ color: 'black' }}>
          {_appointments.professional.phoneNumber}
        </span>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Categoria da aula:{' '}
        <span style={{ color: 'black' }}>{_appointments.type}</span>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Preço: <span style={{ color: 'black' }}>R$ {_appointments.price}</span>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Data e horário:{' '}
        <span style={{ color: 'black' }}>
          {dayjs(_appointments.startDate, 'YYYY/MM/DD').format('DD/MM/YYYY')} -{' '}
          {moment(_appointments.startDate).format('HH:mm')} às{' '}
          {moment(_appointments.endDate).format('HH:mm')}
        </span>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Nota:{' '}
        <span style={{ color: 'black' }}>
          {_appointments.rate === 0 ? 'Sem nota.' : _appointments.rate}
        </span>
      </ExpandedRowTitle>
      <ExpandedRowTitle>
        Avaliação:{' '}
        <span style={{ color: 'black' }}>
          {_appointments.rateDescription === ''
            ? 'Sem avaliação.'
            : _appointments.rateDescription}
        </span>
      </ExpandedRowTitle>
    </div>
  );

  const expandIcon = ({ expanded, onExpand, record }) => (
    <Button
      type="link"
      icon={
        <MdKeyboardArrowRight
          onClick={(e) => onExpand(record, e)}
          style={{
            transition: 'transform 0.3s ease',
            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
            color: PRIMARY_COLOR,
            fontSize: '1.5rem',
          }}
        />
      }
    />
  );

  if (!appointments) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <h3>Carregando...</h3>
      </Spin>
    );
  }

  const configModal = {
    isModalOpen,
    showModal,
    handleCancel,
    userId,
    modalData,
    setModalData: (data) => setModalData(data),
  };

  return (
    <Container>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      >
        <div className="container-table">
          <Title>Meus agendamentos</Title>

          <Table
            columns={tableSettings(isMobile, configModal)}
            dataSource={appointments}
            expandable={{
              expandedRowRender,
              expandIcon,
            }}
            locale={{
              emptyText: <Empty text="Não há agendamentos" />,
            }}
          />
        </div>
      </Spin>
      <Modal configModal={configModal} />
    </Container>
  );
};

export default Appointments;
