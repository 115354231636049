import React, { useCallback } from 'react';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Input from '../../../elements/Input';
import InputMasked from '../../../elements/InputMasked';
import DatePicker from '../../../elements/DatePicker';
import Button from '../../../elements/Button';

import {
  INPUT_FIRST_NAME,
  INPUT_LAST_NAME,
  INPUT_EMAIL,
  INPUT_CPF,
  DATE_PICKER_BIRTH_DATE,
  INPUT_CREF,
  INPUT_PASSWORD,
  INPUT_CONFIRM_PASSWORD,
} from '../../../defaults/RegisterUserFields';
import passwordRegex from '../../../defaults/passwordRegex';

import { callSignUpPersonal } from '../../../ducks/UserDucks/SignUpPersonal';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useNotification from '../../../hooks/useNotification';

import { useDispatch, useSelector } from '../../../utility/Context';
import { getRuleField } from '../../../utility/RuleField';
import { convertPersonalDataBackend } from '../../../utility/convertPersonalData';
import validateCPF from '../../../utility/validateCPF';
import removeCPFCharacter from '../../../utility/removeCPFCharacter';

import Terms from '../../../components/Terms';

import {
  Container,
  Form,
  Title,
  ContainerSignIn,
  ContainerOption,
  ContainerInfo,
} from '../styles';
import { MainStyled } from '../../LandingPageClient/styles';

const SignUpProfessional = () => {
  const { isMobile } = useWindowDimensions();

  const navigate = useNavigate();

  const redirect = (url) => {
    navigate(url);
  };

  const dispatch = useDispatch();

  const singUpPersonal = useCallback(
    (values) => dispatch(callSignUpPersonal(values)),
    [dispatch]
  );

  const { showNotification, contextHolder } = useNotification();

  const onFinish = (values) => {
    const { confirmTerms, cref, cpf, birthDate, ...rest } = values;

    if (!confirmTerms) {
      showNotification(
        'warning',
        'Aceite os Termos de Uso e Políticas de Privacidade',
        'Antes de prosseguir, recomendamos que revise atentamente nossos Termos de Uso e Políticas de Privacidade. Ao aceitá-los, você confirma sua compreensão e concordância com as diretrizes que regem o uso dos nossos serviços.',
        10.0
      );

      return;
    }

    const removeCommasAndDotsCPF = removeCPFCharacter(cpf);
    const isValidCPF = validateCPF(removeCommasAndDotsCPF);

    if (!isValidCPF) {
      showNotification(
        'warning',
        'CPF inválido',
        'Ops! Parece que o CPF inserido não está correto. Por favor, confira e insira um CPF válido.'
      );

      return;
    }

    const inputDate = new Date(birthDate);

    const rfc3339FormattedDate = moment(inputDate).format(
      'YYYY-MM-DDTHH:mm:ss[Z]'
    );

    const body = convertPersonalDataBackend({
      ...rest,
      cref,
      cpf,
      birthDate: rfc3339FormattedDate,
    });

    singUpPersonal(body);
  };

  const onFinishFailed = () => {
    showNotification(
      'warning',
      'Preencha os dados corretamente',
      'Por favor, preencha o formulário corretamente para continuar.'
    );
  };

  const loading = useSelector(({ user }) => user.loading.signUp.personal);

  const today = new Date();
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

  const disabledDate = (current) => current && current > eighteenYearsAgo;

  return (
    <>
      {contextHolder}
      <main>
        <Container>
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Title>Crie uma conta gratuita</Title>

            <Input
              inputConfig={INPUT_FIRST_NAME}
              rules={[getRuleField(INPUT_FIRST_NAME)]}
            />

            <Input
              inputConfig={INPUT_LAST_NAME}
              rules={[getRuleField(INPUT_LAST_NAME)]}
            />

            <Input
              inputConfig={{
                ...INPUT_EMAIL,
                tooltip: {
                  title:
                    'Insira seu endereço de e-mail abaixo para garantir acesso total à sua conta e receber atualizações importantes. E lembre-se este campo não pode ser modificado posteriormente.',
                },
              }}
              rules={[
                getRuleField(INPUT_EMAIL),
                {
                  type: 'email',
                  message: 'O e-mail não é um e-mail válido',
                },
              ]}
            />

            <InputMasked
              inputMaskedConfig={INPUT_CPF}
              rules={[getRuleField(INPUT_CPF)]}
            />

            <DatePicker
              datePickerConfig={DATE_PICKER_BIRTH_DATE}
              rules={[getRuleField(DATE_PICKER_BIRTH_DATE)]}
              disabledDate={disabledDate}
            />

            <InputMasked
              inputMaskedConfig={INPUT_CREF}
              rules={[getRuleField(INPUT_CREF)]}
            />

            <Input
              inputConfig={INPUT_PASSWORD}
              hasFeedback
              rules={[
                getRuleField(INPUT_PASSWORD),
                {
                  validator(_, value) {
                    if (passwordRegex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'A senha deve conter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número, 1 caractere especial e ter no mínimo 6 caracteres.'
                      )
                    );
                  },
                },
              ]}
            />

            <Input
              inputConfig={INPUT_CONFIRM_PASSWORD}
              hasFeedback
              rules={[
                getRuleField(INPUT_CONFIRM_PASSWORD),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('A confirmação de senha não confere.')
                    );
                  },
                }),
              ]}
            />

            <Button
              type="primary"
              className="btn-sign-up"
              htmlType="submit"
              loading={loading}
            >
              Criar conta
            </Button>

            <Terms userType="profissional" />
          </Form>

          {isMobile ? (
            <>
              <Divider />
              <ContainerSignIn>
                <span>Já tem uma conta?</span>
                <Button
                  type="link"
                  className="btn-terms"
                  onClick={() => redirect('/login')}
                >
                  Faça login
                </Button>
              </ContainerSignIn>
            </>
          ) : null}

          {!isMobile ? (
            <img
              src="/image/Online%20Personal%20Trainer-rafiki.svg"
              alt="Personal trainer"
            />
          ) : null}
        </Container>

        <Divider />

        <MainStyled>
          <ContainerInfo>
            <div className="container-options">
              <ContainerOption direct="flex-start">
                <img src="/image/Resume-bro.svg" alt="Currículo" />
                <div>
                  <h2>Sua conta. Do seu jeito.</h2>
                  <p>
                    Configure seu perfil de forma única. Adicione seu horário de
                    atendimento, modalidade de aulas, preços e muito mais.
                    Demonstre seu verdadeiro conhecimento.
                  </p>
                </div>
              </ContainerOption>

              <ContainerOption direct="flex-end">
                <div>
                  <h2>
                    Cuide do que realmente importa. A burocracia nós resolvemos.
                  </h2>
                  <p>
                    Com uma agenda em tempo real, é possível receber
                    agendamentos 24 horas por dia. 7 dias por semana.
                  </p>
                </div>
                <img
                  src="/image/Live%20collaboration-pana.svg"
                  alt="Personagem realizando busca no celular"
                />
              </ContainerOption>

              <ContainerOption direct="flex-start">
                <img
                  src="/image/Feed-amico.svg"
                  alt="Personagem realizando busca no celular"
                />
                <div>
                  <h2>Explore suas ideias.</h2>
                  <p>
                    Faça postagens em seu perfil e comunique sua base de
                    clientes.
                  </p>
                </div>
              </ContainerOption>
            </div>
          </ContainerInfo>
        </MainStyled>
      </main>
    </>
  );
};

export default SignUpProfessional;
