export const CALL_AVAILABILITY = '@application/CALL_AVAILABILITY';
export const AVAILABILITY_FAILED = '@application/AVAILABILITY_FAILED';
export const AVAILABILITY_SUCCESS = '@application/AVAILABILITY_SUCCESS';

export const callAvailability = (nickname) => ({
  type: CALL_AVAILABILITY,
  nickname,
});

export const availabilityFailed = (error) => ({
  type: AVAILABILITY_FAILED,
  error,
});

export const availabilitySuccess = (data) => ({
  type: AVAILABILITY_SUCCESS,
  data,
});

export const onCallAvailability = (state) => ({
  ...state,
  laoding: {
    ...state.laoding,
    availability: true,
  },
  errors: {
    ...state.errors,
    availability: null,
  },
  availability: {},
});

export const onAvailabilityFailed = (state, { error }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    availability: false,
  },
  errors: {
    ...state.errors,
    availability: error,
  },
});

export const onAvailabilitySuccess = (state, { data }) => ({
  ...state,
  laoding: {
    ...state.laoding,
    availability: false,
  },
  errors: {
    ...state.errors,
    availability: null,
  },
  availability: data,
});
