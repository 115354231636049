import React, { useCallback } from 'react';
import { Badge, Avatar, Popover, List, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IoIosNotificationsOutline } from 'react-icons/io';

import { useDispatch, useSelector } from '../../../utility/Context';

import { callReadNotification } from '../../../ducks/ApplicationDucks/Notification';

import Button from '../../../elements/Button';

import { BodyGlobalStyle, ContainerTitleNotification } from '../styles';

const STATUS = {
  true: 'default',
  false: 'processing',
};

const Notification = ({ notifications, userId }) => {
  const dispatch = useDispatch();

  const readNotification = useCallback(() => {
    dispatch(callReadNotification(userId));
  }, [dispatch]);

  const [loadingReadNotification] = useSelector(({ application }) => [
    application.loading.readNotification,
  ]);

  const countNotifications = notifications?.filter(
    (notification) => !notification.seen
  );

  const text = (
    <ContainerTitleNotification>
      <span>Notificações</span>
      <Button
        type="link"
        onClick={() => readNotification(userId)}
        loading={loadingReadNotification}
        disabled={!countNotifications?.length}
      >
        Marcar todas como lidas
      </Button>
    </ContainerTitleNotification>
  );

  const content = (
    <Spin
      spinning={loadingReadNotification}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
    >
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        locale={{
          emptyText: 'Não há notificações',
        }}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              avatar={<Badge status={STATUS[item.seen]} />}
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    </Spin>
  );

  return (
    <>
      <BodyGlobalStyle />
      <Popover
        placement="bottomLeft"
        title={text}
        content={content}
        trigger="click"
        className="notification-popover"
      >
        <Badge
          count={countNotifications?.length}
          className="container-notification"
        >
          <Avatar
            shape="square"
            className="notification"
            icon={<IoIosNotificationsOutline />}
          />
        </Badge>
      </Popover>
    </>
  );
};

export default Notification;
