import styled from 'styled-components';

export const ContainerButton = styled.div.attrs({
  className: 'container-btn',
})`
  &.container-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
`;

export const ContainerModal = styled.div.attrs({
  className: 'container-modal',
})`
  &.container-modal {
  }
`;

export const ContentForm = styled.div.attrs({
  className: 'content-form',
})`
  &.content-form {
    margin-top: 24px;
  }
`;

export default {
  ContainerButton,
  ContainerModal,
  ContentForm,
};
