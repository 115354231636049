import React from 'react';
import PropTypes from 'prop-types';
import { Input as InputAntd } from 'antd';
import { BsQuestionCircle } from 'react-icons/bs';

import { FormItem } from './styles';

const INPUT_TYPE_PASSWORD = 'password';

const Input = ({ inputConfig, hasFeedback, rules }) => {
  const {
    placeholder,
    type,
    tooltip,
    disabled,
    showCount,
    maxLength,
    prefix,
    ...rest
  } = inputConfig;

  if (type === INPUT_TYPE_PASSWORD) {
    return (
      <FormItem {...rest} hasFeedback={hasFeedback} rules={rules}>
        <InputAntd.Password placeholder={placeholder} disabled={disabled} />
      </FormItem>
    );
  }

  if (tooltip) {
    return (
      <FormItem
        {...rest}
        hasFeedback={hasFeedback}
        rules={rules}
        tooltip={{
          title: tooltip.title,
          icon: <BsQuestionCircle />,
        }}
      >
        <InputAntd placeholder={placeholder} disabled={disabled} />
      </FormItem>
    );
  }

  return (
    <FormItem {...rest} hasFeedback={hasFeedback} rules={rules}>
      <InputAntd
        placeholder={placeholder}
        disabled={disabled}
        showCount={showCount}
        maxLength={maxLength}
        prefix={prefix}
      />
    </FormItem>
  );
};

Input.defaultProps = {
  hasFeedback: false,
};

Input.propTypes = {
  inputConfig: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.shape({
      marginBottom: PropTypes.string,
    }),
  }).isRequired,
  hasFeedback: PropTypes.bool,
};

export default Input;
