import React from 'react';

import { Container } from './styles';

const PrivacyPolicy = () => {
  return (
    <Container>
      <h4> Política de privacidade</h4>
      <p>
        Na presente política de privacidade explicaremos a nossos usuários,
        colaboradores e inscritos como tratamos os dados coletados em nossa
        plataforma e, por conseguinte, a forma que os protegem. Contém,
        portanto, informações sobre uso, armazenamento, tratamento e proteção
        dos dados pessoais de usuários/ visitantes do aplicativo.
      </p>
      <p>
        É de extrema seriedade a proteção de dados pessoais e interpessoais
        coletados. Dado termo irá explicar detalhadamente a motivação da
        referida coleta e a maneira em que nossos usuários poderão alterar,
        inserir ou excluir os dados de nossos servidores, dentro da letra da
        lei.
      </p>
      <p>Dúvidas devem ser retiradas com nosso suporte.</p>

      <h4>Dos dados coletados</h4>
      <p>
        Nesta plataforma haverá coleta de dados pessoais mediante
        cadastro/acesso, para atingirem-se diferentes objetivos.
      </p>
      <p>
        Para coletarmos referidos dados utilizamos a plataforma e tecnologias
        conseguintes, mediante, inserto a esta, o preenchimento de formulários,
        cadastro para acesso pessoal, envio de comentários, e afins, ciente de
        que seus dados serão salvos para ulterior e eventual atendimento.
      </p>
      <p>
        Dados para fins de criação de conta/perfil de acesso pessoal,
        qualificação, dados para otimização de navegação, concretização de
        transações eventuais na plataforma, newsletter, e demais.
      </p>
      <p>
        Ainda, as informações sobre interação e acesso são coletadas pela
        plataforma visando garantir a melhor experiência ao usuário, compondo-se
        estas por palavras-chave, compartilhamento eventual de documentos,
        visualizações da página, perfil do usuário, URL, navegadores que
        utilizam IPs para acesso, entre outros.
      </p>
      <p>
        A Política de Privacidade aplica-se a todos os usuários e visitantes da
        plataforma, integrando de forma veemente os Termos de Uso e Condições
        Gerais do aplicativo.
      </p>
      <p>
        O termo foi elaborado em conformidade à L13709/2018, L12965/2014 e
        Regulamento EU n. 2016/6790. Este documento poderá ser atualizado para
        conformar-se com eventual atualização normativa, podendo ser consultado
        a qualquer tempo.
      </p>

      <h4>Da finalidade</h4>
      <p>
        Os dados coletados, de caráter pessoal do usuário e demais visitantes,
        serão armazenados na plataforma, em conformidade legal, tendo por
        finalidade o aprimoramento dos produtos e serviços ofertados, melhora de
        experiência, fornecimento de funcionalidades específicas, melhoria da
        plataforma propriamente dita, compreensão de utilização de produtos e
        serviços do aplicativo, apresentação eventual de anúncios
        personalizados, comerciais que gerem subsídios à plataforma, tratamento
        automatizado de dados pessoais, e afins.
      </p>
      <p>
        O tratamento de quaisquer dados pessoais para finalidades diversas das
        acima explanadas ocorrerão mediante comunicação prévia ao usuário/
        visitante/ profissional habilitado.
      </p>

      <h4>Do período de armazenamento</h4>
      <p>
        Os dados pessoais do usuário serão armazenados durante o período
        congruente à prestação de serviços, cumprindo-se as finalidades
        legalmente estabelecidas veja L13709/2018, podendo ser removidos a
        pedido do usuário, a qualquer tempo.
      </p>

      <h4>Da segurança dos dados armazenados</h4>
      <p>
        A plataforma compromete-se a aplicar todas e quaisquer medidas técnicas
        hábeis à proteção de dados pessoais, impedindo e protegendo-se estes de
        acessos não autorizados, perda, alteração, comunicação ou difusão. Em
        tempo, os dados que concernem a cartões de crédito serão criptografados
        a partir da tecnologia mediante a empresa contratada pelo grupo de
        Personally assim processamento de dados, garantindo sua transmissão de
        maneira confidencial e segura, não sendo a plataforma responsável pela
        ação exclusiva de terceiros ou por culpa exclusiva do usuário.
      </p>
      <p>
        A plataforma se compromete a alertar os usuários em caso de violação de
        segurança de dados.
      </p>
      <p>
        Os dados armazenados serão tratados com base na legislação vigente
        acerca da proteção e confidencialidade consequencial, dentro dos limites
        legalmente estabelecidos.
      </p>

      <h4>Do compartilhamento</h4>
      <p>
        Pessoas que utilizam a plataforma, sejam visitantes ou profissionais
        experientes, precisam saber que a plataforma pode coletar informações de
        navegação usando cookies ou tecnologias semelhantes.
      </p>

      <h4>Dos cookies e dos dados de navegação</h4>
      <p>
        Ao usar os serviços e produtos oferecidos pela plataforma Personally, os
        usuários concordam com a atual Política de Privacidade, bem como com os
        Termos de Uso e as Condições Gerais associadas a ela. Eles também devem
        saber, com o direito de cancelar sua conta a qualquer momento, desde que
        forneçam informações verdadeiras.
      </p>

      <h4>Do consentimento</h4>
      <p>
        Utilizando-se os serviços e produtos ofertados pela plataforma, o
        usuário CONSENTE com a presente Política de Privacidade e com os Termos
        de Uso e Condições Gerais a estão atrelados, ciente igualmente que
        poderá exercer o direito de cancelamento de seu cadastro a qualquer
        tempo, concomitante a garantia de veracidade sobre informações por este
        prestadas
      </p>

      <h4>Da alteração/novação da presente Política de Privacidade</h4>
      <p>
        A Política de Privacidade poderá ser alterada pela plataforma, a
        qualquer tempo, bem como igualmente revisada/vista pelo usuário.
      </p>
      <p>
        Alterações surtem efeito tão logo publicadas, sendo os usuários
        alertados de referidas modificações.
      </p>

      <h4>Do foro</h4>
      <p>
        Para a resolução de problemáticas instauradas ou eventuais controvérsias
        decorrentes deste termo serão aplicadas as leis vigentes do Direito
        Brasileiro, compondo-se ao foro de eleição da comarca de São Paulo
        (1700).
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
