import React, { useCallback } from 'react';
import { Popconfirm } from 'antd';

import Button from '../../../../elements/Button';

import { callFinishClass } from '../../../../ducks/ApplicationDucks/FinishClass';

import { useDispatch } from '../../../../utility/Context';

const FinishClass = ({ id }) => {
  const dispatch = useDispatch();

  const finishClass = useCallback(() => {
    dispatch(callFinishClass(id));
  }, [dispatch]);

  const confirm = (e) => {
    finishClass();
  };

  return (
    <>
      <Popconfirm
        title="Finalizar aula"
        description="Tem certeza que finalizar esta aula?"
        onConfirm={confirm}
        okText="Sim"
        cancelText="Não"
      >
        <Button type="link">Finalizar aula</Button>
      </Popconfirm>
    </>
  );
};

export default FinishClass;
