/* eslint-disable camelcase */
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Space, Tag, Dropdown } from 'antd';
import { FiMoreVertical } from 'react-icons/fi';
import moment from 'moment';

import Button from '../../../elements/Button';
import ImagePreview from '../../ImagePreview';
import Avatar from '../../Avatar';
import Status from '../../Status';

import CancelClass from '../CancelClass';
import RateClass from '../RateClass';
import EditClass from '../EditClass';
import FinishClass from '../FinishClass';

import { ContainerBtnMobile, AvatarContainer } from '../styles';

dayjs.extend(utc);
dayjs.extend(timezone);

const MODALITY = {
  online: 'Online',
  in_person: 'Presencial',
};

const within24Hours = (startDate) => {
  const currentTime = moment(new Date());
  const duration = currentTime.diff(startDate, 'hours');
  return duration >= 0 && duration <= 24;
};

const canEnableAction = (currStatus, statusType) => {
  if (currStatus === statusType) {
    return true;
  }

  return false;
};

export default (isMobile, configModal) => [
  {
    title: 'Profissional',
    dataIndex: 'avatar',
    key: 'avatar',
    render: (_, { professional }) => (
      <a
        href={`https://personally.fit/p/${professional.nickname}`}
        target="_blank"
        rel="noreferrer"
      >
        <AvatarContainer>
          <Avatar
            avatar={professional?.avatar}
            modalTitle="Avatar do profissional"
          />
          <p>{professional?.firstName}</p>
        </AvatarContainer>
      </a>
    ),
    responsive: ['lg'],
    sorter: (a, b) => {
      if (a.professional.firstName < b.professional.firstName) {
        return -1;
      }
      if (a.professional.firstName > b.professional.firstName) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Modalidade',
    key: 'skill',
    dataIndex: 'skill',
    render: (_, { classModality }) => (
      <Tag color="geekblue">{MODALITY[classModality].toUpperCase()}</Tag>
    ),
    responsive: ['lg'],
    sorter: (a, b) => {
      if (a.classModality < b.classModality) {
        return -1;
      }
      if (a.classModality > b.classModality) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Valor',
    dataIndex: 'price',
    key: 'price',
    render: (_, { price }) => (
      <p>{`${price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`}</p>
    ),
    responsive: ['lg'],
    sorter: (a, b) => {
      return a.price - b.price;
    },
  },
  {
    title: 'Data da aula',
    dataIndex: 'date',
    key: 'date',
    render: (_, { startDate }) => (
      <p> {moment(startDate).format('DD/MM/YYYY')}</p>
    ),
    responsive: ['xs', 'sm'],
    sorter: (a, b) => {
      if (a.startDate < b.startDate) {
        return -1;
      }
      if (a.startDate > b.startDate) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Horário',
    dataIndex: 'date',
    key: 'date',
    render: (_, { startDate, endDate }) => (
      <p>
        {moment(startDate).format('HH:mm')} - {moment(endDate).format('HH:mm')}
      </p>
    ),
    responsive: ['xs', 'sm'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, { status }) => <Status status={status} />,
    responsive: ['md'],
    sorter: (a, b) => {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: 'Nota',
    dataIndex: 'rateNumber',
    key: 'rateNumber',
    render: (_, { rate }) => <p>{rate !== 0 ? rate : '-'}</p>,
    responsive: ['lg'],
    sorter: (a, b) => {
      return a.rate - b.rate;
    },
  },
  {
    title: 'Ações',
    key: 'action',
    responsive: ['xs', 'sm'],
    render: (_, { key, professional, status, startDate, rate }) =>
      isMobile ? (
        <Dropdown
          menu={{
            items: [
              canEnableAction(status, 'pending_confirmation') &&
                within24Hours(startDate) && {
                  key: '0',
                  label: (
                    <ContainerBtnMobile>
                      <CancelClass id={key} configModal={configModal} />
                    </ContainerBtnMobile>
                  ),
                },
              canEnableAction(status, 'finished') &&
                rate === 0 && {
                  label: (
                    <ContainerBtnMobile>
                      <RateClass professional={professional} id={key} />
                    </ContainerBtnMobile>
                  ),
                  key: '1',
                },
              status !== 'finished' &&
                status !== 'canceled' && {
                  label: (
                    <ContainerBtnMobile>
                      <EditClass
                        appointmentId={key}
                        professional={professional}
                        status={status}
                      />
                    </ContainerBtnMobile>
                  ),
                  key: '2',
                },
              canEnableAction(status, 'confirmed') && {
                label: (
                  <ContainerBtnMobile>
                    <FinishClass id={key} />
                  </ContainerBtnMobile>
                ),
                key: '3',
              },
            ],
          }}
          trigger={['click']}
        >
          <Space>
            <FiMoreVertical className="menu-table__mobile" />
          </Space>
        </Dropdown>
      ) : (
        <>
          {canEnableAction(status, 'finished') && rate === 0 && (
            <RateClass professional={professional} id={key} />
          )}

          {canEnableAction(status, 'pending_confirmation') &&
            within24Hours(startDate) && (
              <CancelClass id={key} configModal={configModal} />
            )}

          {status !== 'finished' && status !== 'canceled' && (
            <EditClass professional={professional} appointmentId={key} />
          )}

          {canEnableAction(status, 'confirmed') && <FinishClass id={key} />}
        </>
      ),
  },
];
