import React, { useCallback } from 'react';
import { Form, Modal as ModalAntd } from 'antd';

import Textarea from '../../elements/Textarea';
import Button from '../../elements/Button';

import { getRuleField } from '../../utility/RuleField';
import { useDispatch } from '../../utility/Context';

import useNotification from '../../hooks/useNotification';

import { callReport } from '../../ducks/ApplicationDucks/Report';

import { ContainerButton } from './styles';

const ModalReport = ({ configModal }) => {
  const [form] = Form.useForm();

  const { open, handleCancel, professionalId } = configModal;

  const { showNotification, contextHolder } = useNotification();

  const dispatch = useDispatch();

  const report = useCallback(
    (reason) => {
      dispatch(callReport(professionalId, reason));
    },
    [dispatch, professionalId]
  );

  const handleReset = () => {
    form.resetFields();
  };

  const onFinish = (values) => {
    report(values.reason);
    handleReset();
    handleCancel();
  };

  const onFinishFailed = () => {
    showNotification(
      'warning',
      'Preencha os dados corretamente',
      'Por favor, preencha o formulário corretamente para continuar.'
    );
  };

  const validateMinLength = (_, value) => {
    if (value?.length < 10) {
      return Promise.reject(
        new Error('O texto deve ter no mínimo 10 caracteres.')
      );
    }
    return Promise.resolve();
  };

  const TEXTAREA_REPORT = {
    name: 'reason',
    placeholder: 'Descreva com o máximo de detalhes que puder',
    autoSize: { minRows: 2, maxRows: 4 },
    className: 'report',
    maxLength: 5000,
    minLength: 10,
    showCount: true,
    label: 'Nos conte o motivo da sua denúncia.',
    isRequired: true,
  };

  return (
    <>
      {contextHolder}
      <ModalAntd
        title="Denunciar profissional"
        footer={null}
        open={open}
        onCancel={() => {
          handleCancel();
          handleReset();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Textarea
            textareaConfig={TEXTAREA_REPORT}
            rules={[
              getRuleField(TEXTAREA_REPORT),
              { validator: validateMinLength },
            ]}
          />

          <ContainerButton>
            <Button
              key="back"
              onClick={() => {
                handleCancel();
                handleReset();
              }}
            >
              Voltar
            </Button>
            <Button key="submit" type="primary" htmlType="submit">
              Denunciar
            </Button>
          </ContainerButton>
        </Form>
      </ModalAntd>
    </>
  );
};

export default ModalReport;
