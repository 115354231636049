import styled, { createGlobalStyle } from 'styled-components';

import { MOBILE_WIDTH } from '../../defaults/Breakpoints';
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
  BACKGROUND_BODY,
} from '../../defaults/Colors';

export const Container = styled.div.attrs({
  className: 'avatar-container',
})`
  &.avatar-container {
    cursor: pointer;
  }
`;

export default { Container };
