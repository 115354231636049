import styled from 'styled-components';
import { Form as FormAntd } from 'antd';

import { PRIMARY_COLOR } from '../../defaults/Colors';
import { MOBILE_WIDTH, TABLET_WIDTH } from '../../defaults/Breakpoints';

export const Container = styled.div.attrs({
  className: 'sign-in__container',
})`
  &.sign-in__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 40px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      flex-direction: column;
    }

    & img {
      width: 500px;
      height: 500px;

      @media (max-width: ${TABLET_WIDTH}px) {
        width: 350px;
        height: 350px;
      }
    }
  }
`;

export const Form = styled(FormAntd).attrs({
  className: 'sign-in__form',
})`
  &.sign-in__form {
    min-width: 400px;
    max-width: 500px;
    padding: 20px;
    background: rgb(240 240 240);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.8rem;

    @media (max-width: ${MOBILE_WIDTH}px) {
      background: #f8f8f8;
      border-radius: 0px;
      padding: 0px 20px;
    }

    & .btn-sign-up {
      width: 100%;
    }
  }
`;

export const Title = styled.h3.attrs({
  className: 'sign-in__title',
})`
  &.sign-in__title {
    color: ${PRIMARY_COLOR};
    font-size: 30px;
    margin: 10px 0;

    @media (max-width: ${MOBILE_WIDTH}px) {
      font-size: 2rem;
      margin: 20px 0;
    }
  }
`;

export const ContainerSignIn = styled.div.attrs({
  className: 'sign-in__container-sign-in',
})`
  &.sign-in__container-sign-in {
    margin-bottom: 24px;
  }
`;

export const ContainerForgotPassword = styled.div.attrs({
  className: 'sign-in__container-forgot-password',
})`
  &.sign-in__container-forgot-password {
    display: flex;
    width: 100%;
    justify-content: end;

    & button {
      padding-right: 0px;
    }
  }
`;

export const ContainerNewAccount = styled.div.attrs({
  className: 'sign-in__container-new-account',
})`
  &.sign-in__container-new-account {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 14px;
  }
`;

export default {
  Container,
  Form,
  Title,
  ContainerSignIn,
  ContainerForgotPassword,
  ContainerNewAccount,
};
