import styled from 'styled-components';
import { Form } from 'antd';

import { PRIMARY_COLOR } from '../../defaults/Colors';

export const FormItem = styled(Form.Item).attrs({
  className: 'form__item',
})`
  &.form__item {
    text-align: initial;
    width: 100%;
  }
`;

export const TooltipContainer = styled.div.attrs({
  className: 'tooltip__container',
})`
  &.tooltip__container {
    align-items: center;
    display: flex;
    gap: 5px;
  }
`;

export const NotFound = styled.div.attrs({
  className: 'container__not-found',
})`
  &.container__not-found {
    align-items: center;
    display: flex;
    gap: 5px;
    color: #bababa;
  }
`;

export default { FormItem, TooltipContainer, NotFound };
