import React, { useCallback, useEffect } from 'react';
import { Divider, notification, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { BsQuestionCircle } from 'react-icons/bs';
import moment from 'moment';

import UploadImage from '../../../UploadImage';

import Input from '../../../../elements/Input';
import InputNumber from '../../../../elements/InputNumber';
import InputMasked from '../../../../elements/InputMasked';
import DatePicker from '../../../../elements/DatePicker';
import Textarea from '../../../../elements/Textarea';
import Select from '../../../../elements/Select';
import Button from '../../../../elements/Button';

import {
  INPUT_FIRST_NAME,
  INPUT_LAST_NAME,
  INPUT_CPF,
  DATE_PICKER_BIRTH_DATE,
  INPUT_NUMBER_PHONE,
  INPUT_EMAIL,
  INPUT_BUSINESS_EMAIL,
  INPUT_CREF,
  INPUT_ZIP_CODE,
  TEXTAREA_ABOUT_CLASS,
  INPUT_NICKNAME,
  INPUT_INSTAGRAM,
  SELECT_SKILLS,
  INPUT_CLASS_PERSON_PRICE,
  INPUT_CLASS_ONLINE_PRICE,
  SELECT_TARGET_MARKET,
  SELECT_PAYMENT_METHOD,
  SELECT_WORK_LOCATIONS,
} from '../../../../defaults/RegisterUserFields';

import { getRuleField } from '../../../../utility/RuleField';
import { useDispatch, useSelector } from '../../../../utility/Context';
import removeCPFCharacter from '../../../../utility/removeCPFCharacter';
import { convertPersonalDataBackendEditProfile } from '../../../../utility/convertPersonalData';
import validateCPF from '../../../../utility/validateCPF';
import { getAuthLocalStorage } from '../../../../utility/localStorage';

import { callUpdatePersonalData } from '../../../../ducks/UserDucks/UpdatePersonalData';
import { callCategory } from '../../../../ducks/ApplicationDucks/Category';

import useNotification from '../../../../hooks/useNotification';

import Education from './Education';
import Experience from './Experience';
import WorkSchedules from './WorkSchedules';

import { ContainerTitleWorkShedules } from './styles';
import { Form, Title } from '../styles';

const format = 'HH';

const Profile = ({ userId }) => {
  const { showNotification, contextHolder } = useNotification();

  const { userType } = getAuthLocalStorage();

  const [
    userData,
    userDataLoading,
    categories,
    categoriesLoading,
    personalExperience,
    personalEducation,
    loading,
  ] = useSelector(({ user, application }) => [
    user.data.personal,
    user.loading.personalData,
    application.category,
    application.loading.category,
    user.infoPersonal.experience,
    user.infoPersonal.education,
    user.loading.updatePersonalData,
  ]);

  const dispatch = useDispatch();

  const category = useCallback(() => {
    dispatch(callCategory());
  }, [dispatch]);

  useEffect(() => {
    category();
  }, []);

  const updatePersonalData = useCallback(
    (data) => {
      dispatch(
        callUpdatePersonalData({
          data,
          userId,
        })
      );
    },
    [dispatch]
  );

  const generateDate = (list) =>
    list
      .map((item) => {
        if (!item.startDate || !item.endDate) {
          return null;
        }

        const startDate = item?.startDate?.format('HH:mm');
        const endDate = item?.endDate ? item?.endDate.format('HH:mm') : null;

        return {
          day_of_week: item.weekType,
          start_hour: startDate,
          end_hour: endDate,
        };
      })
      .filter((_) => _);

  const formatDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');

  const onFinish = (values) => {
    const {
      cpf,
      birthDate,
      skills,
      classOnlinePrice,
      classPersonPrice,
      contactEmail,
      ...rest
    } = values;

    const removeCommasAndDotsCPF = removeCPFCharacter(cpf);
    const isValidCPF = validateCPF(removeCommasAndDotsCPF);

    if (!isValidCPF) {
      showNotification(
        'warning',
        'CPF inválido',
        'Ops! Parece que o CPF inserido não está correto. Por favor, confira e insira um CPF válido.'
      );

      return;
    }

    if (skills?.length > 2) {
      showNotification(
        'warning',
        'Limite de "Tipos de aula" foi atingido',
        'Ops! Parece que ultrapassamos nosso limite de "Tipos de aula". Você pode selecionar até dois.'
      );
      return;
    }

    const inputDate = new Date(birthDate);

    const rfc3339FormattedDate = formatDate(inputDate);

    const weekList = [
      {
        weekType: 'sunday',
        startDate: values.sundayStartDate,
        endDate: values.sundayEndDate,
      },
      {
        weekType: 'monday',
        startDate: values.mondayStartDate,
        endDate: values.mondayEndDate,
      },
      {
        weekType: 'tuesday',
        startDate: values.tuesdayStartDate,
        endDate: values.tuesdayEndDate,
      },
      {
        weekType: 'wednesday',
        startDate: values.wednesdayStartDate,
        endDate: values.wednesdayEndDate,
      },
      {
        weekType: 'thursday',
        startDate: values.thursdayStartDate,
        endDate: values.thursdayEndDate,
      },
      {
        weekType: 'friday',
        startDate: values.fridayStartDate,
        endDate: values.fridayEndDate,
      },
      {
        weekType: 'saturday',
        startDate: values.saturdayStartDate,
        endDate: values.saturdayEndDate,
      },
    ];

    const weeks = generateDate(weekList);

    const body = convertPersonalDataBackendEditProfile({
      cpf,
      birthDate: rfc3339FormattedDate,
      experiences: personalExperience,
      educations: personalEducation,
      workSchedules: weeks,
      skills,
      classPersonPrice,
      classOnlinePrice,
      contactEmail,
      ...rest,
    });

    updatePersonalData(body);
  };

  const onFinishFailed = () => {
    showNotification(
      'warning',
      'Preencha os dados corretamente',
      'Por favor, Preencha o formulário corretamente para continuar.'
    );
  };

  const today = new Date();
  const date = new Date();
  const minimumAge = date.setFullYear(today.getFullYear() - 18);
  const maximumAge = date.setFullYear(today.getFullYear() - 120);

  const disabledDate = (current) =>
    (current && current > minimumAge) || current < maximumAge;

  if (!userData) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={userDataLoading}
      >
        <h3>Carregando...</h3>
      </Spin>
    );
  }

  const initialValues = {};

  userData?.workSchedules.forEach((item) => {
    initialValues[`${item.day_of_week}StartDate`] = dayjs(
      item.start_hour,
      format
    );
    initialValues[`${item.day_of_week}EndDate`] = dayjs(item.end_hour, format);
  });

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          firstName: userData.firstName,
          lastName: userData.lastName,
          nickname: userData.nickname,
          email: userData.email,
          contactEmail: userData.contactEmail,
          cpf: userData.cpf,
          cref: userData.cref,
          birthDate: dayjs(userData.birthDate.split('T')[0], 'YYYY/MM/DD'),
          restriction: userData.restriction,
          customerObjectives: userData.customerObjectives,
          instagram: userData.instagram,
          phone: userData.phone,
          zipCode: userData.zipCode,
          aboutClass: userData.aboutClass,
          skills: userData.skills,
          workLocations: userData.workLocations,
          targetMarket: userData.targetMarket,
          paymentMethods: userData.paymentMethods,
          classOnlinePrice: userData.classOnlinePrice,
          classPersonPrice: userData.classPersonPrice,
          ...initialValues,
        }}
      >
        <Title>Dados pessoais</Title>

        <Divider />

        <div>
          <UploadImage
            userId={userId}
            defaultAvatar={userData.avatar}
            userType={userType}
          />
        </div>

        <Input
          inputConfig={INPUT_FIRST_NAME}
          rules={[getRuleField(INPUT_FIRST_NAME)]}
        />

        <Input
          inputConfig={INPUT_LAST_NAME}
          rules={[getRuleField(INPUT_LAST_NAME)]}
        />

        <Input
          inputConfig={INPUT_NICKNAME}
          rules={[getRuleField(INPUT_NICKNAME)]}
        />

        <Input
          inputConfig={{
            ...INPUT_EMAIL,
            tooltip: {
              title:
                'Para alterar este dado, entre em contato com o suporte no e-mail suporte@personally.fit.',
            },
            disabled: true,
          }}
          rules={[getRuleField(INPUT_EMAIL)]}
        />

        <Input
          inputConfig={{
            ...INPUT_BUSINESS_EMAIL,
            tooltip: {
              title:
                'Este e-mail será disponibilizado como meio de contato para seus clientes.',
            },
          }}
          rules={[
            {
              type: 'email',
              message: 'O e-mail não é um e-mail válido',
            },
          ]}
        />

        <InputMasked
          inputMaskedConfig={{
            ...INPUT_NUMBER_PHONE,
            tooltip: {
              title:
                'Este celular será disponibilizado como meio de contato para seus clientes.',
            },
          }}
        />

        <InputMasked
          inputMaskedConfig={{
            ...INPUT_CPF,
            disabled: true,
            tooltip: {
              title:
                'Para alterar este dado, entre em contato com o suporte no e-mail suporte@personally.fit.',
            },
          }}
          rules={[getRuleField(INPUT_CPF)]}
        />

        <DatePicker
          datePickerConfig={DATE_PICKER_BIRTH_DATE}
          rules={[getRuleField(DATE_PICKER_BIRTH_DATE)]}
          disabledDate={disabledDate}
        />

        <InputMasked inputMaskedConfig={INPUT_ZIP_CODE} />

        <Input
          inputConfig={INPUT_INSTAGRAM}
          rules={[getRuleField(INPUT_INSTAGRAM)]}
        />

        <Title>Dados profissionais</Title>

        <Divider />

        <InputMasked
          inputMaskedConfig={{
            ...INPUT_CREF,
            disabled: true,
            tooltip: {
              title:
                'Para alterar este dado, entre em contato com o suporte no e-mail suporte@personally.fit.',
            },
          }}
          rules={[getRuleField(INPUT_CREF)]}
        />

        <Education userData={userData} />

        <Experience userData={userData} />

        <Title>Informações das aulas</Title>

        <Divider />

        <Textarea
          textareaConfig={TEXTAREA_ABOUT_CLASS}
          rules={[getRuleField(TEXTAREA_ABOUT_CLASS)]}
        />

        <Select
          selectConfig={{
            ...SELECT_SKILLS,
            options: categories,
            label: 'Tipos de aula (no máximo duas)',
            disabled: categoriesLoading,
          }}
        />

        <Select
          selectConfig={SELECT_TARGET_MARKET}
          rules={[getRuleField(SELECT_TARGET_MARKET)]}
        />

        <Select
          selectConfig={SELECT_WORK_LOCATIONS}
          rules={[getRuleField(SELECT_WORK_LOCATIONS)]}
        />

        <ContainerTitleWorkShedules>
          <h3>Dias e horários de trabalho:</h3>

          <Tooltip title="Os campos desabilitados não serão salvos">
            <BsQuestionCircle />
          </Tooltip>
        </ContainerTitleWorkShedules>

        <WorkSchedules workSchedules={userData.workSchedules} />

        <InputNumber
          inputNumberConfig={INPUT_CLASS_ONLINE_PRICE}
          rules={[getRuleField(INPUT_CLASS_ONLINE_PRICE)]}
        />

        <InputNumber
          inputNumberConfig={INPUT_CLASS_PERSON_PRICE}
          rules={[getRuleField(INPUT_CLASS_PERSON_PRICE)]}
        />

        <Select
          selectConfig={SELECT_PAYMENT_METHOD}
          rules={[getRuleField(SELECT_PAYMENT_METHOD)]}
        />

        <Button
          type="primary"
          className="btn-save-data"
          htmlType="submit"
          loading={loading}
        >
          Salvar dados
        </Button>
      </Form>
    </>
  );
};

export default Profile;
