import { connectRouter } from 'connected-react-router';

import { combineReducers } from 'redux';
import application from './ducks/ApplicationDucks';
import user from './ducks/UserDucks';

const createReducer =
  ([initialState, actionHandlers]) =>
  (state = initialState, action) =>
    (actionHandlers[action.type] &&
      actionHandlers[action.type](state, action)) ||
    state;

const createRootReducer = (history) =>
  combineReducers({
    application: createReducer(application),
    user: createReducer(user),
    router: connectRouter(history),
  });

export default createRootReducer;
